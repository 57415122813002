import mixpanel from "mxpl";

import { getConfig } from "utils/environment";

import { TrackingEvent } from "./TrackingEvent";

const instances = {
  staging: {
    token: "7ff481a8ffe07f83895add8ec69dc39d",
    host: "https://api.staging.nabla.com/mxpl",
  },
  preprod: {
    token: "f049fbdb5a3488037423927cb268f2ce",
    host: "https://api.preprod.nabla.com/mxpl",
  },
  prod: {
    token: "4603b73014e8ba5079a9d062c572fd73",
    host: "https://api.nabla.com/mxpl",
  },
};

const instance = getConfig({
  dev: instances.staging,
  pr: null,
  staging: instances.staging,
  preprod: instances.preprod,
  prod: instances.prod,
});

if (instance) {
  mixpanel.init(instance.token, { api_host: instance.host });
  mixpanel.opt_in_tracking();
}

export const trackEvent = (payload: TrackingEvent) => {
  if (!instance) return;
  // This check can be replaced by satisfies operator on the definition in TS 4.9
  const subTyped: {
    name: string;
    properties?: Record<string, string | number>;
  } = payload;
  mixpanel.track(subTyped.name, subTyped.properties);
};
