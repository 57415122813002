import {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { To } from "history";
import { useNavigate } from "react-router-dom";

import { now } from "utils/date";

import { useOn } from "./useOn";
import { useRerender } from "./useRerender";
import { useSyncRef } from "./useSyncRef";

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useOnUnmount = (callback: () => void) => {
  const callbackRef = useSyncRef(callback);
  useEffect(() => () => callbackRef.current(), [callbackRef]);
};

export const useWindowHasFocus = () => {
  const rerender = useRerender();
  useOn("focus", rerender);
  useOn("blur", rerender);
  return document.hasFocus();
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useOn("resize", () =>
    setWindowSize({ width: window.innerWidth, height: window.innerHeight }),
  );

  return windowSize;
};

export const useFocusAfterAnimation = <
  T extends HTMLElement = HTMLInputElement,
>() => {
  const inputRef = useRef<T>(null);

  useEffect(() => {
    // Avoid keyboard issue on mobile
    setTimeout(
      () => {
        inputRef.current?.focus();
      },
      window.E2E ? 0 : 300,
    );
  }, []);

  return inputRef;
};

/**
 * Currently a Popover closes when it detects a click on the window outside itself.
 * This issue is that any click with stopPropagation won't reach the window
 * This is used a lot via useTargetState and would happen anyway
 * because of menu inside clickable rows of tables.
 * So the current hack that doesn't require to rethink all the popover management
 * is to trigger custom events so that the popover can close on them.
 * This event is dispatched before the new one is mounted,
 * which is cool (and I hope stable in the future of React).
 * It may be improved later if multiple popover at the same time is something
 * wanted in some cases.
 * The function below is not exported for now but could be reused
 * in some other places where stopPropagation is used.
 */
const CUSTOM_CLICK_OUTSIDE_EVENT = "click-outside";
const triggerCloseOutside = () => {
  window.dispatchEvent(new CustomEvent(CUSTOM_CLICK_OUTSIDE_EVENT));
};
export const useTargetState = <Target extends Element = Element>(): [
  Target | undefined,
  (e: BaseSyntheticEvent<any, Target> | undefined) => void,
] => {
  const [target, setTarget] = useState<Target>();
  const targetRef = useSyncRef(target);

  return [
    target,
    useCallback(
      (e) => {
        if (!e) {
          setTarget(undefined);
          return;
        }
        const newTarget = e.currentTarget;
        e.stopPropagation();
        if (targetRef.current === newTarget) {
          setTarget(undefined);
        } else {
          triggerCloseOutside();
          setTarget(newTarget);
        }
      },
      [targetRef],
    ),
  ];
};

export const useClickOutside = <Element extends HTMLElement = HTMLDivElement>(
  onClickOutside: () => void,
) => {
  const ref = useRef<Element>(null);
  useOn("click", (event) => {
    const element = ref.current;
    if (!element) return;
    if (
      event
        .composedPath()
        .some((target) => target instanceof Element && element.contains(target))
    ) {
      return;
    }
    onClickOutside();
  });
  useOn(CUSTOM_CLICK_OUTSIDE_EVENT as keyof WindowEventMap, () => {
    onClickOutside();
  });
  return ref;
};

export const useInitialValue = <T>(value: T) => useState(value)[0];
export const useMountDate = () => useInitialValue(now());

// See https://github.com/ReactTraining/history/issues/791#issuecomment-643818253.
const getHistoryIndex = (): number => window.history.state.idx;

/**
 * Navigates back to either:
 * - A fallback if this is the first visited page on the console.
 * - Or, if `toPageBeforeMount`, the console page right before the one which
 *   mounted the component calling this hook; e.g. the console page right
 *   before the one displaying the `<BackMobile />` button.
 * - Or the previous console page.
 */
export const useGoBack = (toPageBeforeMount = true) => {
  const navigate = useNavigate();
  const indexOnMount = useState(getHistoryIndex)[0];

  return useCallback(
    (fallback: To) => {
      const currentIndex = getHistoryIndex();
      // Going back would leave the website?
      if (currentIndex === 0) {
        navigate(fallback, { replace: true });
        return;
      }

      navigate(toPageBeforeMount ? indexOnMount - currentIndex - 1 : -1);
    },
    [indexOnMount, navigate, toPageBeforeMount],
  );
};

// Fires the callback at most every temporizationMilliSeconds ms
export const useTemporization = (
  callback: () => any,
  temporizationMilliSeconds: number,
) => {
  const lastCallDate = useRef<Date>(new Date(0));
  return useCallback(() => {
    const date = new Date();
    if (
      date.getTime() - lastCallDate.current.getTime() >
      temporizationMilliSeconds
    ) {
      lastCallDate.current = date;
      callback();
    }
  }, [callback, temporizationMilliSeconds]);
};
