import { useMemo } from "react";
import { FormikProps, useFormikContext } from "formik";

export type FormState<
  FormValues extends Record<string, unknown> = { genericRequired: true },
  SubmitContext extends Record<string, unknown> = { genericRequired: true },
> = Omit<FormikProps<FormValues>, "setFieldValue" | "submitForm"> & {
  /** Set value of form field directly */
  setFieldValue: <K extends keyof FormValues>(
    field: K,
    value: FormValues[K],
  ) => void;

  /** Submit the form imperatively */
  submitForm(context?: SubmitContext): Promise<void>;
};

export const useFormState = <
  FormValues extends Record<string, unknown> = { genericRequired: true },
  SubmitContext extends Record<string, unknown> = { genericRequired: true },
>() => {
  const formikContext = useFormikContext<FormValues>();
  return useMemo(
    () =>
      ({
        ...formikContext,
        submitForm: (context) => {
          formikContext.setStatus({
            ...formikContext.status,
            submitContext: context,
          });
          return formikContext.submitForm();
        },
      } as FormState<FormValues, SubmitContext>),
    [formikContext],
  );
};
