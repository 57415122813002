import { useState } from "react";
import { Link } from "react-router-dom";

import { useAuth, VerifyMfaByTotpOrPhonePayload } from "auth/AuthContext";
import { Button } from "components/Button/Button";
import { Input } from "components/Form/Input/Input";
import { PasswordInput } from "components/Form/Input/PasswordInput";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { makeSearch } from "utils/url";

import { routes } from "../../routes";
import { VerifyMfaForm } from "./VerifyMfaForm";

export type AccountPasswordLoginFormState =
  | {
      step: "CREDENTIALS_FORM";
      email?: string;
      password?: string;
    }
  | {
      step: "MFA_FORM";
      email: string;
      password: string;
      payload: VerifyMfaByTotpOrPhonePayload;
    };

export const AccountPasswordLoginForm = ({
  state,
  onStateChange,
  fixedEmail,
  onSuccess,
}: {
  // State is controlled externally to allow the parent component to show
  // a different surrounding layout depending on the current step.
  state: AccountPasswordLoginFormState;
  onStateChange: (state: AccountPasswordLoginFormState) => void;

  fixedEmail?: string;
  onSuccess?: () => void;
}) => {
  const t = useTranslation();
  const isDesktop = useIsDesktop();
  const { accountLoginWithPassword } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const email = fixedEmail ?? state.email ?? "";
  const password = state.password ?? "";

  const accountLoginWithCurrentCredentials = async (mfaCode?: string) => {
    const response = await accountLoginWithPassword({
      email,
      password,
      mfaCode,
    });

    if (response.kind === "CREDENTIALS_FAILURE") {
      onStateChange({ step: "CREDENTIALS_FORM", email, password });
    } else if (response.kind === "MFA_REQUIRED") {
      onStateChange({ step: "MFA_FORM", payload: response, email, password });
    } else if (response.kind === "SUCCESS") {
      onSuccess?.();
    }

    return response.kind;
  };

  return state.step === "MFA_FORM" ? (
    <VerifyMfaForm
      {...state.payload}
      onGoBack={() => {
        onStateChange({ step: "CREDENTIALS_FORM", email, password });
      }}
      onSubmit={async (mfaCode) => {
        const kind = await accountLoginWithCurrentCredentials(mfaCode);
        return kind === "MFA_FAILURE" ? "ERROR" : "SUCCESS";
      }}
    />
  ) : (
    <form
      className="flex-col"
      onSubmit={async (e) => {
        e.preventDefault();

        setIsSubmitting(true);
        await accountLoginWithCurrentCredentials();
        setIsSubmitting(false);
      }}
    >
      <Input
        name="email"
        type="email"
        autoComplete="email"
        htmlRequired
        disabled={!!fixedEmail}
        value={email}
        onChange={(e) => onStateChange({ ...state, email: e.target.value })}
        label={t("login.email_address")}
        placeholder={t("login.enter_your_email_address")}
        wrapperClassName="mb-24"
      />
      <PasswordInput
        name="password"
        autoComplete="current-password"
        htmlRequired
        autoFocus={isDesktop}
        value={password}
        onChange={(e) => onStateChange({ ...state, password: e.target.value })}
        label={t("login.password")}
        placeholder={t("login.enter_your_password")}
        wrapperClassName="mb-4"
      />
      <Link
        className="inline-block text-14 hover:opacity-70"
        to={{
          pathname: routes.FORGOT_PASSWORD,
          search: makeSearch({ email }),
        }}
      >
        {t("login.forgot_your_password")}
      </Link>
      <Button
        large
        className="mt-24"
        loading={isSubmitting}
        label={t("login.login_label")}
        type="submit"
      />
    </form>
  );
};
