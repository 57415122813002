import {
  CSSProperties,
  HTMLAttributes,
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import classNames from "classnames";

import { Popover } from "components/Popover/Popover";
import { useSyncRef } from "hooks/useSyncRef";
import { useTranslation } from "i18n";

const LIST_PADDING = 12;
const CREATE_IDENTIFIER = "c601223b-78ee-4874-a90f-9409f92145bf";

export type CustomOptionProps<Option = unknown> = {
  // Not used in default component but usefully for other implementation
  // eslint-disable-next-line react/no-unused-prop-types
  value: Option;
  active: boolean;
  selected: boolean;
  label: string;
};

export const OptionsPopover = <
  Option extends unknown,
  IsMulti extends boolean = false,
>({
  options,
  value,
  onSelect,
  getOptionId,
  getOptionLabel,
  CustomOption,
  loading,
  creatable,
  style,
  targetRef,
  onSearchChanged,
  children,
  position = ["top", "bottom"],
}: {
  options: readonly Option[];
  getOptionId: (o: Option) => string;
  getOptionLabel: (o: Option) => string;
  CustomOption?: (props: CustomOptionProps<Option>) => JSX.Element;
  loading?: boolean;
  creatable?: Option extends string ? boolean : never;
  style?: CSSProperties; // For playground & time picker
  targetRef?: RefObject<HTMLElement>;
  position?: ("top" | "bottom")[];
  onSearchChanged?: (value: string | undefined) => void;
  children: (
    props: {
      inputRef: RefObject<HTMLInputElement>;
      search: string | undefined;
      showOptions: boolean;
      openOptions: () => void;
      set: (
        o: IsMulti extends true ? Option | Option[] : undefined | Option,
      ) => void;
    } & Required<
      Pick<
        HTMLAttributes<HTMLInputElement>,
        "onChange" | "onClick" | "onKeyDown" | "onBlur"
      >
    >,
  ) => ReactNode;
} & (IsMulti extends true
  ? { value: Option[]; onSelect: (o: Option[]) => void }
  : {
      value: Option | undefined;
      onSelect: (o: undefined | Option) => void;
    })) => {
  const t = useTranslation();
  const getIndex = (option: Option | undefined) => {
    if (!option) return undefined;
    return (options as Option[]).findIndexOrUndefined(
      (o) => getOptionId(o) === getOptionId(option),
    );
  };
  const inputRef = useRef<HTMLInputElement>(null);
  const [{ useKeyboard, showOptions, search, index }, setState] = useState(
    () => ({
      useKeyboard: false,
      showOptions: false,
      index: Array.isArray(value) ? undefined : getIndex(value),
      search: undefined as string | undefined,
    }),
  );

  // getOptionLabel is most of the time an inline callback
  // it's not expected to really change, and using ref is better than disabling the linter
  const callbackRef = useSyncRef({ getOptionId, getOptionLabel });
  const filteredOptions = useMemo(
    () =>
      options
        .filter((option) =>
          Array.isArray(value)
            ? !value.some(
                (o) =>
                  callbackRef.current.getOptionId(o) ===
                  callbackRef.current.getOptionId(option),
              )
            : true,
        )
        .filter((o) =>
          callbackRef.current.getOptionLabel(o).fuzzyMatch(search ?? ""),
        )
        .concatIf(
          creatable &&
            search?.isNotBlank() &&
            !(options as unknown as string[]).includes(search),
          CREATE_IDENTIFIER as unknown as Option,
        ),
    [callbackRef, creatable, options, search, value],
  );

  const setSearch = (newSearch: string | undefined, newValue?: Option) => {
    setState({
      useKeyboard,
      showOptions: newSearch !== undefined,
      index:
        newSearch === undefined
          ? Array.isArray(value)
            ? undefined
            : getIndex(newValue ?? value)
          : index,
      search: newSearch,
    });
    onSearchChanged?.(newSearch);
  };

  const setIndex = (newIndex: number, fromKeyboard: boolean) => {
    setState({
      useKeyboard: fromKeyboard,
      showOptions: true,
      search,
      index: newIndex,
    });
  };

  const setShow = (show: boolean) => {
    setState((current) => ({ ...current, showOptions: show }));
  };

  const set = (newValue: undefined | Option | Option[]) => {
    if (Array.isArray(value)) {
      setSearch("", undefined);
      const intermediateValueForTS: Option[] = Array.isArray(newValue)
        ? newValue
        : value.concat(
            newValue === CREATE_IDENTIFIER
              ? (search as Option)
              : // Probably due do bad handling of generics:
                // https://github.com/typescript-eslint/typescript-eslint/issues/3310#issuecomment-826008027
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                newValue!,
          );
      onSelect(intermediateValueForTS as any);
    } else {
      const typedValue = newValue as Option | undefined;
      setSearch(undefined, typedValue);
      const intermediateValueForTS: Option | undefined =
        newValue === CREATE_IDENTIFIER ? (search as Option) : typedValue;
      onSelect(intermediateValueForTS as any);
    }
  };

  const rectifiedIndex =
    index !== undefined && index < filteredOptions.length
      ? index
      : filteredOptions.length && showOptions
      ? 0
      : undefined;

  const target = (targetRef ?? inputRef).current!;

  return (
    <>
      {children({
        inputRef,
        set,
        search,
        showOptions,
        openOptions: () => setShow(true),
        onClick: () => setShow(!showOptions),
        onChange: (e) => setSearch(e.currentTarget.value),
        onKeyDown: (e) => {
          if (e.key === "ArrowDown" && filteredOptions.length) {
            setIndex(
              rectifiedIndex === undefined
                ? 0
                : (rectifiedIndex + 1) % filteredOptions.length,
              true,
            );
            e.preventDefault();
          }
          if (e.key === "ArrowUp" && filteredOptions.length) {
            setIndex(
              rectifiedIndex ? rectifiedIndex - 1 : filteredOptions.length - 1,
              true,
            );
            e.preventDefault();
          }
          if (e.key === "Enter") {
            if (rectifiedIndex === undefined) {
              setSearch(undefined);
            } else {
              set(filteredOptions[rectifiedIndex]);
              e.preventDefault();
            }
          }
          if (e.key === "Escape" && showOptions) {
            setShow(false);
            e.stopPropagation();
          }
        },
        onBlur: () => setSearch(undefined),
      })}
      {showOptions && (
        <Popover
          target={target}
          onClose={() => undefined}
          style={{
            width: target.offsetWidth,
            maxHeight: 35 * 9 + 2, // 9 default option + borders
            paddingTop: LIST_PADDING,
            paddingBottom: LIST_PADDING,
            ...style,
          }}
          className="flex-col overflow-auto my-10 px-10"
          noArrow
          position={position}
        >
          {filteredOptions.isEmpty() ? (
            <div className="p-10 text-center">
              {loading
                ? t("form.select.options_popover.loading")
                : t("form.select.options_popover.no_options")}
            </div>
          ) : (
            filteredOptions.map((option, i) => (
              <OptionWrapper
                key={getOptionId(option)}
                Content={CustomOption ?? DefaultCustomOption}
                value={option}
                getOptionLabel={
                  creatable
                    ? (o) =>
                        (o as unknown as string) === CREATE_IDENTIFIER
                          ? `${t(
                              "form.select.options_popover.create",
                            )} "${search!}"`
                          : (o as unknown as string)
                    : getOptionLabel
                }
                onClick={() => set(option)}
                onHovered={() => setIndex(i, false)}
                useKeyboard={useKeyboard}
                selected={
                  // For multi select, selected options are filtered out
                  value && !Array.isArray(value)
                    ? getOptionId(option) === getOptionId(value)
                    : false
                }
                active={i === rectifiedIndex}
              />
            ))
          )}
        </Popover>
      )}
    </>
  );
};

const OptionWrapper = <Option extends unknown>({
  Content,
  value,
  getOptionLabel,
  onClick,
  onHovered,
  useKeyboard,
  active,
  selected,
  style,
}: {
  Content: (props: CustomOptionProps<Option>) => JSX.Element;
  value: Option;
  getOptionLabel: (option: Option) => string;
  onClick: () => void;
  onHovered: () => void;
  useKeyboard: boolean;
  selected: boolean;
  active: boolean;
  style?: CSSProperties;
}) => {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    // Keeps active element visible when using arrows
    if (!active || !useKeyboard) return;
    const el = ref.current!;
    const list = el.parentElement!;
    if (list.clientHeight + list.scrollTop < el.offsetTop + el.offsetHeight) {
      list.scrollTo({
        top: el.nextElementSibling
          ? el.offsetTop + el.offsetHeight + LIST_PADDING - list.clientHeight
          : list.scrollHeight,
      });
    }
    if (list.scrollTop > el.offsetTop) {
      list.scrollTo({
        top: el.previousElementSibling ? el.offsetTop - LIST_PADDING : 0,
      });
    }
  }, [useKeyboard, active]);

  useEffect(() => {
    if (!selected) return;
    const el = ref.current!;
    const list = el.parentElement!;
    list.scrollTo({
      top: el.offsetTop + el.clientHeight / 2 - list.clientHeight / 2,
    });
  }, [selected]);

  return (
    <button
      ref={ref}
      name="option"
      style={style}
      onMouseMove={onHovered}
      onMouseDown={(e) => {
        // Avoid lost of focus when coming from an input
        // It's also why we use onMouseDown, because it triggers before the blur event
        e.preventDefault();
        // Avoid click event to propagate to the element under after the popover closes (#21517)
        e.stopPropagation();
        onClick();
      }}
    >
      <Content
        value={value}
        label={getOptionLabel(value)}
        selected={selected}
        active={active}
      />
    </button>
  );
};

const DefaultCustomOption = ({
  label,
  active,
  selected,
}: CustomOptionProps) => (
  <CustomOptionWrapper active={active} selected={selected}>
    {label}
  </CustomOptionWrapper>
);

export const CustomOptionWrapper = ({
  active,
  selected,
  children,
  className,
}: {
  active: boolean;
  selected: boolean;
  children: ReactNode;
  className?: string;
}) => (
  <div
    className={classNames(
      "rounded px-10 py-8 text-14",
      selected
        ? "bg-primary text-white"
        : active
        ? "text-primary-dark bg-grey-100"
        : "text-primary-dark",
      className,
    )}
  >
    {children}
  </div>
);
