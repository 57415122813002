import { useField } from "hooks/useField";

import { Switch, ToggleProps } from "./Switch";

export const FormSwitch = ({
  name,
  disabled,
  onChange,
  ...rest
}: { name: string; onChange?: (v: boolean) => void } & Omit<
  ToggleProps,
  "checked" | "onChange"
>) => {
  const [{ value, disabled: disabledField }, { error }, { setValue }] =
    useField<boolean>({ name, disabled });

  return (
    <Switch
      checked={value}
      onChange={(val) => {
        setValue(val);
        onChange?.(val);
      }}
      disabled={disabledField}
      error={error}
      {...rest}
    />
  );
};
