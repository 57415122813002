import { ReactNode } from "react";
import classNames from "classnames";

import { Icon } from "components/Icon/Icon";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { IconName } from "icon-library";

import { LabelWrapper } from "../Label/LabelWrapper";
import styles from "./inputWrapper.module.css";

export type ExposedInputWrapperProps = RequiredInputWrapperProps &
  Partial<Omit<OptionalInputWrapperProps, "children">>;

type RequiredInputWrapperProps = {
  name: string;
};

type OptionalInputWrapperProps = {
  // LabelWrapper
  wrapperClassName: string | undefined;
  label: string | undefined;
  error: string | undefined;
  hint: string | JSX.Element | undefined;

  className: string | undefined;
  children: (className: string) => JSX.Element;
  required: boolean | undefined;
  gray: boolean | undefined;
  thinGray: boolean | undefined;
  leftInnerElement: IconName | JSX.Element | undefined;
  innerElement: ReactNode | undefined;
  rightInnerElement: ReactNode | undefined;
  rightInnerElementClassName: string | undefined;
  inlineError: "top" | "left" | "bottom" | "right" | undefined;
};

export const InputWrapper = ({
  wrapperClassName,
  label,
  error,
  hint,
  name,

  children,
  className,
  required,
  gray,
  thinGray,
  leftInnerElement,
  innerElement,
  rightInnerElement,
  rightInnerElementClassName,
  inlineError,
}: RequiredInputWrapperProps & OptionalInputWrapperProps) => (
  <LabelWrapper
    name={name}
    wrapperClassName={wrapperClassName}
    label={required && label && !label.endsWith("*") ? `${label}*` : label}
    error={inlineError ? undefined : error}
    hint={hint}
  >
    <div className="flex relative w-full">
      {leftInnerElement &&
        (typeof leftInnerElement === "string" ? (
          <Icon
            name={leftInnerElement}
            className="absolute z-2 self-center ml-10 left-0"
          />
        ) : (
          <div className="absolute z-2 self-center ml-8 left-0">
            {leftInnerElement}
          </div>
        ))}
      {children(
        classNames(className, styles.input, {
          [styles.error]: error,
          [styles.withLeftElement]: !!leftInnerElement,
          [styles.gray]: gray,
          [styles.thinGray]: thinGray,
        }),
      )}
      {innerElement}
      {rightInnerElement || (inlineError && error) ? (
        <div
          className={classNames(
            styles.rightInnerElement,
            rightInnerElementClassName,
          )}
        >
          {inlineError && error ? (
            <TooltipWrapper label={error} position={inlineError}>
              <Icon name="warning" className="text-danger" />
            </TooltipWrapper>
          ) : (
            rightInnerElement
          )}
        </div>
      ) : null}
    </div>
  </LabelWrapper>
);
