export const getConfigForHost =
  (host: string) =>
  <T>({
    dev,
    pr,
    staging,
    prod,
    preprod,
  }: {
    dev: T;
    pr: T;
    staging: T;
    prod: T;
    preprod: T;
  }) => {
    if (
      host.endsWith(".dev.nabla.com") ||
      host.match(/^localhost(:\d+)?$/u) !== null ||
      host.match(/^(192|172)\.\d+\.\d+\.\d+(:\d+)?$/u) ||
      host.match(/ngrok.app/u) !== null
    ) {
      return dev;
    }
    if (host.endsWith(".pro.pr.nabla.com")) return pr;
    if (host.endsWith("staging.nabla.com")) return staging;
    if (host.endsWith("preprod.nabla.com")) return preprod;
    // TODO(mleclercq) Remove *.iap2.nabla.com once fully migrated to new domain names for staging and PR
    if (host.endsWith("pro.iap2.nabla.com")) {
      if (host.endsWith("staging.pro.iap2.nabla.com")) return staging;
      return pr;
    }
    return prod;
  };
