import { CSSProperties, useRef } from "react";
import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { Spinner } from "components/Spinner/Spinner";
import { useTranslation } from "i18n";

import { LabelWrapper, NamedLabelWrapperProps } from "../Label/LabelWrapper";
import { CustomOptionProps, OptionsPopover } from "./OptionsPopover";
import styles from "./multiSelect.module.css";

export type MultiSelectProps<Option> = NamedLabelWrapperProps & {
  value: Option[];
  options: readonly Option[];
  getOptionId?: (o: Option) => string;
  getOptionLabel?: (o: Option) => string;
  CustomOption?: (props: CustomOptionProps<Option>) => JSX.Element;
  onChange: (value: Option[]) => void;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  creatable?: Option extends string ? boolean : never;
  loading?: boolean;
  hideDropdownIndicator?: boolean;
  thinGray?: boolean;
  optionsStyle?: CSSProperties;
  onSearchChanged?: (value: string | undefined) => void;
};

export const MultiSelect = <Option extends unknown>({
  wrapperClassName,
  label,
  hint,
  name,
  error,

  value,
  options,
  getOptionId = (o: Option) =>
    o && typeof o === "object" // Because null is an object, thanks JS
      ? // @ts-ignore
        o.uuid
      : o,
  getOptionLabel = (o: Option) => (o as any).toString(),
  CustomOption,
  onChange,
  className,
  placeholder,
  disabled,
  creatable,
  loading,
  hideDropdownIndicator,
  thinGray = false,
  optionsStyle,
  onSearchChanged,
}: MultiSelectProps<Option>) => {
  const divRef = useRef<HTMLDivElement>(null);
  const clickDidBlurRef = useRef(false);
  const t = useTranslation();

  return (
    <OptionsPopover<Option, true>
      options={options}
      value={value}
      onSelect={onChange}
      getOptionId={getOptionId}
      getOptionLabel={getOptionLabel}
      CustomOption={CustomOption}
      loading={loading}
      style={optionsStyle}
      targetRef={divRef}
      creatable={creatable}
      onSearchChanged={onSearchChanged}
    >
      {({
        inputRef,
        set,
        search,
        showOptions,
        openOptions,
        onKeyDown,
        ...inputProps
      }) => {
        const focus = showOptions || search !== undefined;

        return (
          <LabelWrapper
            wrapperClassName={wrapperClassName}
            label={label}
            error={error}
            hint={hint}
            name={name}
          >
            <div
              ref={divRef}
              role="textbox"
              className={classNames(styles.div, className, {
                [styles.focus]: focus,
                [styles.error]: error,
                [styles.disabled]: disabled,
                [styles.thinGray]: thinGray,
              })}
              onMouseDown={() => {
                clickDidBlurRef.current =
                  document.activeElement === inputRef.current;
              }}
              onClick={() => {
                if (!clickDidBlurRef.current && !disabled) {
                  openOptions();
                  inputRef.current?.focus();
                }
              }}
            >
              {value.map((o) => (
                <div
                  key={getOptionId(o)}
                  className="rounded bg-primary-100 text-primary text-14 pl-6 flex items-center"
                >
                  {getOptionLabel(o)}
                  <ClickableIcon
                    name="close"
                    size={9}
                    className="p-6 hover:opacity-70"
                    onMouseDown={(e) => {
                      e.preventDefault();
                      inputRef.current?.focus();
                      set(
                        value.filter((v) => getOptionId(v) !== getOptionId(o)),
                      );
                    }}
                  />
                </div>
              ))}
              <input
                ref={inputRef}
                spellCheck={false}
                name={name}
                value={search ?? ""}
                autoComplete="off"
                onKeyDown={(e) => {
                  onKeyDown(e);
                  if (e.key === "Backspace" && !search) set(value.slice(0, -1));
                }}
                {...inputProps}
                size={value.length ? (search ?? "").length + 1 : undefined}
                className={classNames({
                  "flex-fill": value.isEmpty(),
                  "bg-grey-100": thinGray && !focus,
                })}
                disabled={disabled}
                placeholder={
                  value.length
                    ? undefined
                    : placeholder ?? t("form.select.multi_select.pick")
                }
              />
              <div className="absolute right-8 flex items-center space-x-6 m-0">
                {loading && <Spinner small inline />}
                {!loading && value.length > 0 && (
                  <ClickableIcon
                    name="close"
                    size={12}
                    className="p-4 hover:opacity-80"
                    onClick={() => {
                      inputRef.current?.focus();
                      set([]);
                    }}
                  />
                )}
                {!hideDropdownIndicator && (
                  <Icon name="chevron" rotate={90} size={24} className="p-4" />
                )}
              </div>
            </div>
          </LabelWrapper>
        );
      }}
    </OptionsPopover>
  );
};
