import { useCallback, useEffect, useState } from "react";

// This hooks should be used to force render of a component, if for example a display
// is depending on Date.now() or on a state contained in a browser API
// It can also be used to actualise a view with a ref value, but it's often a code smell
export const useRerender = () => {
  const [, setCount] = useState(0);
  return useCallback(() => setCount((c) => c + 1), []);
};

export const useRerenderAt = (date: ISOString | undefined) => {
  const rerender = useRerender();

  useEffect(() => {
    if (!date) return;
    if (date.isPast()) return;
    const timeoutID = setTimeout(rerender, date.millisecondsFromNow());
    return () => clearTimeout(timeoutID);
  }, [date, rerender]);
};
