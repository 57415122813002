import { SidebarPage } from "components/SidebarPage/SidebarPage";
import { useTranslation } from "i18n";
import { routes } from "routes";

import { ApiLogs } from "../../shared/Developers/ApiLogs/ApiLogs";
import { Billing } from "./Billing/Billing";
import { ApiKeys } from "./ServerKeys/ApiKeys";
import { OAuthClientKeys } from "./ServerKeys/OAuthClientKeys";
import { CopilotApiWebhookEvents } from "./WebhookEvents/CopilotApiWebhookEvents";
import { CopilotApiWebhooks } from "./Webhooks/CopilotApiWebhooks";

export const Developers = () => {
  const t = useTranslation();
  return (
    <SidebarPage
      baseRoute={routes.DEVELOPERS}
      title={t("developers.developers.developers")}
      items={[
        {
          to: routes.OAUTH_CLIENTS_KEYS,
          icon: "lock",
          label: t("developers.oauth_clients"),
          Component: OAuthClientKeys,
          hasAccess: (accessor) => accessor.hasPermission("VIEW_API_KEYS"),
        },
        {
          to: routes.API_KEYS,
          icon: "lock",
          label: t("developers.server_keys.api_keys.api_keys"),
          Component: ApiKeys,
          hasAccess: (accessor) => accessor.hasPermission("VIEW_API_KEYS"),
        },
        {
          to: routes.API_LOGS,
          icon: "clipboard",
          label: t("developers.developers.api_logs"),
          Component: ApiLogs,
        },
        {
          to: routes.BILLING,
          icon: "dollar",
          label: t("developers.developers.billing"),
          Component: Billing,
        },
        {
          to: routes.WEBHOOKS,
          icon: "uploadCloud",
          label: t("developers.developers.webhooks"),
          Component: CopilotApiWebhooks,
        },
        {
          to: routes.WEBHOOK_EVENTS,
          icon: "history",
          label: t("developers.developers.webhook_events"),
          Component: CopilotApiWebhookEvents,
        },
      ]}
      withRedirect
    />
  );
};
