import { ReactNode } from "react";
import classNames from "classnames";

import { CommonPropsLabelProps, Label } from "./Label";
import styles from "./labelWrapper.module.css";

export type UnnamedLabelWrapperProps = CommonPropsLabelProps & {
  wrapperClassName?: string | undefined;
};
export type NamedLabelWrapperProps = UnnamedLabelWrapperProps & {
  name: string;
};

export const LabelWrapper = ({
  children,
  wrapperClassName,
  ...labelProps
}: {
  children: ReactNode;
  wrapperClassName: string | undefined;
  label: string | undefined;
  error: string | undefined;
  hint: string | JSX.Element | undefined;
} & ({ name: string; useDiv?: never } | { useDiv: true })) => (
  <div className={classNames(styles.wrapper, wrapperClassName, "justify-end")}>
    <Label {...labelProps} />
    {children}
  </div>
);
