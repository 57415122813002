import { useCallback, useEffect } from "react";
import { useQuery as useApolloQuery } from "@apollo/client";
import produce, { Draft } from "immer";

import { useAuth } from "auth/AuthContext";
import { Query, SchemaType } from "base-types";
import { useAppVersion } from "contexts/AppVersion/AppVersionContext";
import { BaseCacheUpdateOptions } from "types";

import {
  notifyGraphQLError,
  parseApolloError,
  ParsedGraphQLError,
} from "./errors";
import { useGraphQLClient } from "./GraphQLClientContext";
import { RequestContextOptions } from "./request-context-utils";
import { WithOperation } from "./types";
import { getOperationKey, getOutput } from "./utils";

export type QueryOptions<
  Variables,
  VariablesRequired,
  Schema extends SchemaType,
> = RequestContextOptions<Schema> & {
  notifyOnNetworkStatusChange?: boolean;
  pollInterval?: number;
  cacheOnly?: boolean;
} & (VariablesRequired extends true
    ?
        | { variables: Variables; skip?: boolean }
        | { variables?: Variables; skip: true }
    : { variables?: Variables; skip?: boolean });

const networkStatuses = [
  "loading",
  "setVariables",
  "fetchingMore",
  "refetch",
  "poll",
  "ready",
  "error",
] as const;

export type QueryOutput<Data, Variables> = {
  data: Data | undefined;
  previousData: Data | undefined;
  error: ParsedGraphQLError | undefined;
  loading: boolean;
} & QueryUtils<Data, Variables>;

export type QueryUtils<Data, Variables> = {
  networkStatus: typeof networkStatuses[number];
  refetch: (variables?: Variables) => Promise<Data | undefined>;
  nextPage: (
    variables: Partial<Variables>,
    write: (draft: Draft<Data>, result: Data) => void,
  ) => void;
  fetchMore: <FetchMoreData, FetchMoreVariables extends Partial<Variables>>(
    query: Query<FetchMoreData, FetchMoreVariables, true, SchemaType>,
    variables: FetchMoreVariables,
    write: (draft: Draft<Data>, result: FetchMoreData) => void,
  ) => Promise<void>;
  update: (baseOptions: BaseCacheUpdateOptions<Data>) => void;
};

export const useQuery = <
  Data,
  Variables,
  VariablesRequired,
  Schema extends SchemaType,
>(
  query: Query<Data, Variables, VariablesRequired, Schema>,

  // No need to pass an options object if none of the options are required.
  ...options: Partial<
    QueryOptions<Variables, VariablesRequired, Schema>
  > extends QueryOptions<Variables, VariablesRequired, Schema>
    ? [options?: QueryOptions<Variables, VariablesRequired, Schema>]
    : [options: QueryOptions<Variables, VariablesRequired, Schema>]
): QueryOutput<Data, Variables> => {
  const {
    cacheOnly,
    variables: initialVariables,
    pollInterval,
    skip,
    notifyOnNetworkStatusChange,
    requestContext,
  } = options[0] ?? {};

  const client = useGraphQLClient().graphQLClients[query.schemaType];
  const { state: appVersionState } = useAppVersion();
  const { state: authState } = useAuth();

  // Performing the same GraphQL operation with different request contexts might
  // yield different responses, so we must bypass the cache when a context is
  // explicitly passed–in other words for operations on unauthenticated schemas.
  //
  // For operations on authenticated schemas, the cache is cleared automatically
  // every time the authentication context changes.
  const shouldBypassCache = requestContext !== undefined;

  // Apollo deep compares the context, so we don't care if it changes identity.
  const apolloContext = {
    requestContext,

    // By default, Apollo de-duplicates queries with identical query strings,
    // variable values and operation names; but it doesn't take the context
    // into account, unfortunately. The only workaround is to disable query
    // deduplication when a context is explicitly passed.
    queryDeduplication: !shouldBypassCache,
  };

  const {
    data,
    previousData,
    error,
    loading,
    networkStatus,
    refetch,
    fetchMore,
  } = useApolloQuery<WithOperation<Data>, Variables>(query.document, {
    client,
    variables: initialVariables,
    fetchPolicy: shouldBypassCache
      ? "no-cache"
      : cacheOnly
      ? "cache-only"
      : "cache-first",
    errorPolicy: "all",
    pollInterval,
    skip,
    notifyOnNetworkStatusChange,
    context: apolloContext,
  });

  useEffect(() => {
    if (error) {
      notifyGraphQLError(appVersionState, authState, error, "SENTRY_ONLY");
    }
  }, [appVersionState, authState, error]);

  return {
    data: data ? getOutput(data) : undefined,
    previousData: previousData ? getOutput(previousData) : undefined,
    error: error && parseApolloError(error),
    loading: data ? false : loading,
    // Mapping number enum to string enum
    // 5 doesn't exist so we need to shift by 2 if > 5
    // https://github.com/apollographql/apollo-client/blob/main/src/core/networkStatus.ts
    networkStatus: networkStatuses[networkStatus - (networkStatus > 5 ? 2 : 1)],
    refetch: useCallback(
      (variables) =>
        refetch(variables).then((result) => getOutput(result.data)),
      [refetch],
    ),
    nextPage: (variables, write) => {
      fetchMore({
        variables: removeTypename(variables),
        updateQuery: updateQueryFromWrite(write),
      });
    },
    fetchMore: async (q, variables, write) => {
      await fetchMore({
        query: q.document,
        variables: removeTypename(variables),
        // Maybe will be simpler to type with Apollo 3.4
        updateQuery: updateQueryFromWrite<Data, any>(write),
      });
    },
    update: (baseOptions) => {
      if (skip) return;
      client.update({
        query,
        variables: initialVariables,
        ...baseOptions,
      });
    },
  };
};

const updateQueryFromWrite =
  <Data, FetchMoreData>(
    write: (draft: Draft<Data>, result: FetchMoreData) => void,
  ) =>
  (
    prev: WithOperation<Data>,
    { fetchMoreResult }: { fetchMoreResult?: WithOperation<FetchMoreData> },
  ) => {
    const prevOutput = getOutput(prev);
    const fetchMoreOutput = getOutput(fetchMoreResult);
    if (!prevOutput || !fetchMoreOutput) return prev;
    return {
      ...prev,
      [getOperationKey(prev)]: produce(prevOutput, (draft) => {
        write(draft, fetchMoreOutput as any);
      }),
    };
  };

export const removeTypename = <T extends unknown>(variables: T): T =>
  JSON.parse(JSON.stringify(variables), (key, value) =>
    key === "__typename" ? undefined : value,
  );
