import { useCallback } from "react";
import {
  getDefaultValues,
  getOperationDefinition,
  getStoreKeyName,
} from "@apollo/client/utilities";
import { DocumentNode } from "graphql";

import { WithOperation } from "./types";

export const getOperationKey = <Data>(data: WithOperation<Data>) =>
  Object.keys(data).find((k) => k !== "__typename") as "UnknownEndpointName";

export const getOutput = <Data>(
  data: WithOperation<Data> | null | undefined,
): Data | undefined => {
  if (!data) return undefined;
  const output = data[getOperationKey(data)];
  return output ?? undefined;
};

export const omitTypename = <T extends { __typename: string }>({
  __typename,
  ...rest
}: T) => rest;

const lastFetchedMap: { [key: string]: ISOString | undefined } = {};
export const useCacheTime = <TVariables>(
  document: DocumentNode,
  variables?: TVariables,
) => {
  const operationDefinition = getOperationDefinition(document)!;
  const key = getStoreKeyName(operationDefinition.name!.value, {
    ...getDefaultValues(operationDefinition),
    ...variables,
  });
  const setFetched = useCallback(
    () => (lastFetchedMap[key] = new Date().toISOString()),
    [key],
  );

  return {
    lastFetched: lastFetchedMap[key],
    setFetched,
  };
};
