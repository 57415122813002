// Those utils should NOT be used to configure/enable/disable product features
// based on the environment. You should use Gatekeeper for that.
// They are just here for rare configuration / networking edge-cases
import { Capacitor } from "@capacitor/core";
import { z } from "zod";

import {
  CloudRegionKnownValue,
  NablaRegionKnownValue,
} from "generated/account";

import { getConfigForHost } from "./config";

export const ORGANIZATION_ID_SEARCH_PARAM = "organizationId";
export const SUB_ORGANIZATION_ID_SEARCH_PARAM = "subOrganizationId";

const host = window.location.host;

export const isBehindIap =
  host.includes(".dev.") || host.includes("staging.") || host.includes("iap2.");

export const getConfig = getConfigForHost(host);
const protocol = Capacitor.isNativePlatform()
  ? getConfig({
      dev: "http:",
      pr: "https:",
      preprod: "https:",
      prod: "https:",
      staging: "https:",
    })
  : window.location.protocol;

// TODO: We still haven't migrated to the new region names in the DNS zone.
const regionUrlMap: Record<NablaRegionKnownValue, string> = {
  EU: "eu-west1",
  US: "us-central1",
};

// Used when parsing cloud regions from legacy URLs or tokens.
export const cloudRegionSchema = z
  .enum(["eu-west1", "us-central1"])
  .transform<CloudRegionKnownValue>(
    (label) =>
      ({
        "eu-west1": "EU_WEST1" as const,
        "us-central1": "US_CENTRAL1" as const,
      }[label]),
  );

// Used when parsing Nabla regions from URLs or tokens.
export const nablaRegionSchema = z
  .enum(["eu", "us"])
  .transform<NablaRegionKnownValue>(
    (label) =>
      ({
        eu: "EU" as const,
        us: "US" as const,
      }[label]),
  );

export const cloudRegionToNablaRegion = (
  cloudRegion: CloudRegionKnownValue,
): NablaRegionKnownValue =>
  ({
    EU_WEST1: "EU" as const,
    US_CENTRAL1: "US" as const,
  }[cloudRegion]);

const getPRNumber = () => Number(host.match(/pr-(\d+)\./u)?.[1]);

export const baseApiUrl = (region: NablaRegionKnownValue | undefined) => {
  const regionUrlPart = region ? `${regionUrlMap[region]}.` : "";

  return `${getConfig({
    dev: `${window.location.origin}/api/global`,
    staging: `${window.location.origin}/api/global`,
    pr: `${window.location.origin}/api/global`,
    preprod: `https://${regionUrlPart}api.preprod.nabla.com`,
    prod: `https://${regionUrlPart}api.nabla.com`,
  })}`;
};

export const apiUrl = (region: NablaRegionKnownValue | undefined) =>
  `${baseApiUrl(region)}/v1`;

const maybeCommitHash = process.env.VITE_COMMIT_HASH;
export const commitHash = maybeCommitHash!;
const version = maybeCommitHash?.slice(0, 8) ?? "-1";
console.info(`Nabla Console (${version})`);

export const envColor = getConfig({
  dev: "bg-primary",
  pr: "bg-green",
  staging: "bg-coral",
  preprod: "bg-body",
  prod: "bg-primary-dark",
});

export const envText = getConfig({
  dev: "dev",
  pr: "pr",
  staging: "staging",
  preprod: "preprod",
  prod: undefined,
});

export const isPaletteEnabled = getConfig({
  dev: true,
  pr: true,
  staging: true,
  preprod: false,
  prod: false,
});

export const copilotChromeExtensionId = getConfig({
  dev: "lmccikojlfmnfnopkollijmbiikdbplc",
  pr: "oikkjlmegcabjgliedepdpmaniflgbce",
  staging: "ealkocnnjfflknpfhgfldhojmfbjjnge",
  preprod: "jijkjelijmamocloaanlejmagppampja",
  prod: "gdhbaoemgglcgmkidhnhcellgnehaeol",
});

export const defaultHost = (() => {
  const stagingDefaultHost = "staging.pro.iap2.nabla.com";
  if (host.endsWith(stagingDefaultHost) || window.location.port === "3600") {
    return stagingDefaultHost;
  }

  const preprodDefaultHost = "pro.preprod.nabla.com";
  if (host.endsWith(preprodDefaultHost) || window.location.port === "3650") {
    return preprodDefaultHost;
  }

  const prodDefaultHost = "pro.nabla.com";
  if (host.endsWith(prodDefaultHost) || window.location.port === "3700") {
    return prodDefaultHost;
  }

  if (host.includes("pr-") && host.endsWith(".pro.iap2.nabla.com")) {
    return host.slice(host.lastIndexOf("pr-"));
  }

  return host;
})();

export const defaultDomain = (() => defaultHost.split(":")[0])();

export const defaultOrigin = `${protocol}//${defaultHost}`;

export const getSafeArea = (position: "bottom" | "top") => {
  const value = getComputedStyle(document.documentElement)
    .getPropertyValue(`--safe-area-${position}`)
    .slice(0, -2); // Remove "px"
  const number = Number(value);
  if (!number) return 0;
  // On iOS, value are a bit too big IMO (48 top, 34 bottom for iPhone X)
  // Need some investigation on android, so only truncate big values
  return number < 24 ? number : number - 10;
};

export const copilotWebappUrl = getConfig({
  dev: import.meta.env.VITE_COPILOT_URL
    ? import.meta.env.VITE_COPILOT_URL
    : "http://localhost:3010",
  pr: `https://pr-${getPRNumber()}.copilot.pr.nabla.com`,
  staging: "https://copilot.staging.nabla.com",
  preprod: "https://copilot.preprod.nabla.com",
  prod: "https://copilot.nabla.com",
});

export const copilotMobileAppDeeplinkUrl = "nabla://copilot.nabla.com";
