import { useCallback, useState } from "react";

import { useAuth } from "auth/AuthContext";

export const useLogout = () => {
  const auth = useAuth();
  const [loggingOut, setLoggingOut] = useState(false);

  return [
    useCallback(async () => {
      if (auth.state !== "LOGGED_IN") return;
      setLoggingOut(true);
      await auth.logout();
    }, [auth]),
    loggingOut,
  ] as const;
};
