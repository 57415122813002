// constants is a reserved module in node.
export const MESSAGES_KEY = "messages_v4";
export const DRAFT_KEY = "draft_v5";
export const DEBUG_MODE_KEY = "debug-mode";
export const REDIRECT_TO_KEY = "redirectTo";
export const DEVICE_UUID_KEY = "device-uuid";
export const RECENT_EMOJIS = "recent-emojis";
export const CPT_CODES_STATISTICS_KEY = "cptCodesStatistics";
export const ICD10_CM_CODES_STATISTICS_KEY = "icd10CodesStatistics";
export const ICD10_WHO_CODES_STATISTICS_KEY = "icd10WHOCodesStatistics";
export const QUEUED_NOTIFICATIONS_COOKIE_KEY = "queued-notifications";
export const KNOWN_ORGANIZATIONS_COOKIE_KEY = "known-organizations";
export const AUTH_TOKENS_KEY = "auth-tokens";
