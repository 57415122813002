import { useEffect, useState } from "react";

// A common UX trick is to avoid showing a loading spinner too early.
//
// The rationale is that most common requests should happen in a few hundred
// milliseconds, in which case the brain will perceive the action as being
// instantaneous _unless_ we flash a loading spinner right at the beginning
// for only tenths of milliseconds.

const DELAY_BEFORE_SPINNER_MS = 150;

export const useDelayedLoading = (loading: boolean) => {
  const [delayedLoading, setDelayedLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      const handler = setTimeout(() => {
        setDelayedLoading(true);
      }, DELAY_BEFORE_SPINNER_MS);
      return () => clearTimeout(handler);
    }
    setDelayedLoading(false);
    return; // eslint-disable-line no-useless-return
  }, [loading]);

  return delayedLoading;
};
