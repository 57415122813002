import { useEffect, useState } from "react";
import gql from "graphql-tag";
import { Location } from "history";
import { useLocation, useParams } from "react-router-dom";

import { SetupMfaByTotpOrPhonePayload, useAuth } from "auth/AuthContext";
import { AccountGoogleLoginButton } from "auth/components/AccountGoogleLoginButton";
import {
  AccountPasswordLoginForm,
  AccountPasswordLoginFormState,
} from "auth/components/AccountPasswordLoginForm";
import { ChoosePasswordForm } from "auth/components/ChoosePasswordForm";
import { SetupMfaForm } from "auth/components/SetupMfaForm";
import { EnvironmentBanner } from "components/EnvironmentBanner/EnvironmentBanner";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import {
  IdentitiesQuery,
  IdentitiesQueryData,
} from "components/IdentityPicker/IdentitiesQuery";
import {
  IdentityPickerConfirmationState,
  isPickableIdentity,
  PickableIdentity,
  usePickIdentity,
} from "components/IdentityPicker/utils";
import { PublicScreenWrapper } from "components/PublicScreenWrapper/PublicScreenWrapper";
import { Redirect } from "components/Routes/Redirect";
import {
  AccountIdentitiesData,
  ChooseAccountCredentialsAuthenticated,
  SetupMfaMethod,
} from "generated/account";
import { useMutation } from "graphql-client/useMutation";
import { useGoBack } from "hooks";
import { useSetLocaleAndTimezone } from "hooks/useSetLocaleAndTimezone";
import { useTranslation } from "i18n";
import { routes } from "routes";
import { run } from "utils";

// Shown after clicking on an identity in the `IdentityPicker`.
//
// If the clicked identity is compatible with the current login method, it will
// be picked automatically. Otherwise, we will ask the user to re-login with
// the proper method or to set up MFA when applicable.
export const FullPageIdentityPickerConfirmation = () => (
  // No image because this route can't be accessed in the Copilot flow.
  <PublicScreenWrapper>
    <EnvironmentBanner />
    <IdentitiesQuery>
      {(data) => <FullPageIdentityPickerConfirmationContent {...data} />}
    </IdentitiesQuery>
  </PublicScreenWrapper>
);

const FullPageIdentityPickerConfirmationContent = ({
  identities,
  accountUnverifiedEmail,
  accountLocale,
  accountTimezone,
  accountHasPassword,
  mfaState,
}: IdentitiesQueryData) => {
  useSetLocaleAndTimezone(accountLocale, accountTimezone);

  const { uuid: identityUuid } = useParams();
  const { state }: Location<IdentityPickerConfirmationState> = useLocation();
  const goBack = useGoBack(false);
  const onGoBack = state?.canGoBack ? () => goBack("/") : undefined;

  const identity = identities
    .flatMap((it) => it.identitiesForOrganization)
    .find((it) => it.uuid === identityUuid);

  if (!identity || !isPickableIdentity(identity)) {
    return <Redirect to="/" />;
  } else if (identity.status === "COMPATIBLE") {
    return <PickIdentity identity={identity} />;
  }

  return (
    <div className="w-full max-w-[450px] flex-col">
      {run(() => {
        switch (identity.status) {
          case "REQUIRES_PASSWORD_LOGIN":
            return accountHasPassword ? (
              <RequiresPasswordLogin
                identity={identity}
                accountEmail={accountUnverifiedEmail}
                onGoBack={onGoBack}
              />
            ) : (
              <RequiresPasswordSetup identity={identity} onGoBack={onGoBack} />
            );
          case "REQUIRES_GOOGLE_LOGIN":
            return (
              <RequiresGoogleLogin
                identity={identity}
                accountEmail={accountUnverifiedEmail}
                onGoBack={onGoBack}
              />
            );
          case "REQUIRES_MFA":
            return (
              <RequiresMfa
                accountEmail={accountUnverifiedEmail}
                mfaState={mfaState}
                onGoBack={onGoBack}
              />
            );
        }
      })}
    </div>
  );
};

// Picks the given identity when mounted.
const PickIdentity = ({ identity }: { identity: PickableIdentity }) => {
  const pickIdentity = usePickIdentity();
  useEffect(() => pickIdentity(identity), [pickIdentity, identity]);
  return null;
};

gql`
  # schema = ACCOUNT
  mutation ChooseAccountCredentialsAuthenticated(
    $newPassword: String!
    $verifyMfaCode: String
  ) {
    chooseAccountCredentials(
      mfa: { verify: { code: $verifyMfaCode } }
      newPassword: $newPassword
    ) {
      jwtTokens {
        accessToken
        refreshToken
      }
    }
  }
`;

const RequiresPasswordSetup = ({
  identity,
  onGoBack,
}: {
  identity: PickableIdentity;
  onGoBack?: () => void;
}) => {
  const t = useTranslation();
  const pickIdentity = usePickIdentity();
  const { accountLoginWithTokens } = useAuth();
  const [chooseAccountCredentials] = useMutation(
    ChooseAccountCredentialsAuthenticated,
    {
      onSuccess: ({ jwtTokens: { accessToken, refreshToken } }) => {
        accountLoginWithTokens({
          accountAccessToken: accessToken,
          accountRefreshToken: refreshToken,
        });

        pickIdentity(identity, { canGoBack: true, replace: true });
      },
    },
  );

  return (
    <div className="flex-col">
      {onGoBack && (
        <ClickableIcon
          name="chevron"
          rotate={180}
          className="mb-24 border rounded-full h-44 w-44"
          onClick={onGoBack}
        />
      )}

      <p className="mb-24">
        {t("full_page_identity_setup.requires_password_setup.subtitle", {
          organizationName: t("full_page_identity_setup.superuser"),
        })}
      </p>

      <ChoosePasswordForm
        onSubmit={async (password) => {
          await chooseAccountCredentials({ newPassword: password });
        }}
      />
    </div>
  );
};

const RequiresPasswordLogin = ({
  identity,
  accountEmail,
  onGoBack,
}: {
  identity: PickableIdentity;
  accountEmail: string;
  onGoBack?: () => void;
}) => {
  const t = useTranslation();

  const pickIdentity = usePickIdentity();
  const onSuccess = () =>
    pickIdentity(identity, { canGoBack: true, replace: true });

  const [passwordLoginFormState, setPasswordLoginFormState] =
    useState<AccountPasswordLoginFormState>({ step: "CREDENTIALS_FORM" });

  // Hide the rest of the layout if the password login form is past the initial step.
  if (passwordLoginFormState.step !== "CREDENTIALS_FORM") {
    return (
      <div className="flex-col">
        <AccountPasswordLoginForm
          state={passwordLoginFormState}
          onStateChange={setPasswordLoginFormState}
          fixedEmail={accountEmail}
          onSuccess={onSuccess}
        />
      </div>
    );
  }

  return (
    <div className="flex-col">
      {onGoBack && (
        <ClickableIcon
          name="chevron"
          rotate={180}
          className="mb-24 border rounded-full h-44 w-44"
          onClick={onGoBack}
        />
      )}

      <p className="mb-24">
        {t("full_page_identity_setup.requires_password_login.subtitle", {
          organizationName: t("full_page_identity_setup.superuser"),
        })}
      </p>

      <AccountPasswordLoginForm
        state={passwordLoginFormState}
        onStateChange={setPasswordLoginFormState}
        fixedEmail={accountEmail}
        onSuccess={onSuccess}
      />
    </div>
  );
};

const RequiresGoogleLogin = ({
  identity,
  accountEmail,
  onGoBack,
}: {
  identity: PickableIdentity;
  accountEmail: string;
  onGoBack?: () => void;
}) => {
  const t = useTranslation();

  return (
    <>
      {onGoBack && (
        <ClickableIcon
          name="chevron"
          rotate={180}
          className="mb-24 border rounded-full h-44 w-44"
          onClick={onGoBack}
        />
      )}

      <h2 className="text-black font-medium mb-4">
        {t("full_page_identity_setup.requires_google_login.title")}
      </h2>
      <p className="mb-24 leading-normal">
        {t("full_page_identity_setup.requires_google_login.subtitle", {
          organizationName: t("full_page_identity_setup.superuser"),
        })}
      </p>

      <AccountGoogleLoginButton
        payload={{
          emailLoginHint: accountEmail,
          // To automatically pick the right identity after redirection.
          redirectPath: `${routes.PICK_IDENTITY}/${identity.uuid}`,
        }}
      />
    </>
  );
};

gql`
  # schema = ACCOUNT
  mutation SetupMfaMethod($mfaCode: String, $newMethod: SetupMfaInput!) {
    setupMfaMethod(mfaCode: $mfaCode, newMethod: $newMethod) {
      jwtTokens {
        accessToken
        refreshToken
      }
    }
  }
`;

const RequiresMfa = ({
  accountEmail,
  mfaState,
  onGoBack,
}: {
  accountEmail: string;
  mfaState: AccountIdentitiesData["mfaState"];
  onGoBack?: () => void;
}) => {
  const t = useTranslation();
  const { accountLoginWithTokens } = useAuth();

  const [setupMfaMethod] = useMutation(SetupMfaMethod);

  // We should always be able to build a setup payload here because we should
  // always be in a `NotSetupMfaState`. Otherwise, it would mean that we've
  // somehow managed to log into an account with MFA without providing a valid
  // MFA code, so we would have other–much bigger–problems.
  const setupMfaPayload = buildSetupMfaPayload(accountEmail, mfaState);
  if (!setupMfaPayload) return null;

  return (
    <SetupMfaForm
      organizationName={t("full_page_identity_setup.superuser")}
      onSubmit={async (methodKind, code) => {
        const data = await setupMfaMethod({ newMethod: { code, methodKind } });
        if (!data) return "ERROR";

        accountLoginWithTokens({
          accountRefreshToken: data.jwtTokens.refreshToken,
          accountAccessToken: data.jwtTokens.accessToken,
        });
        return "SUCCESS";
      }}
      onGoBack={onGoBack}
      {...setupMfaPayload}
    />
  );
};

const buildSetupMfaPayload = (
  accountEmail: string,
  state: AccountIdentitiesData["mfaState"],
): SetupMfaByTotpOrPhonePayload | null => {
  // MFA needs to not be setup in order for us to be able to set it up.
  if (state.__typename !== "NotSetupMfaState") return null;

  const totpMethod = state.supportedMethods.find(
    (it): it is Extract<typeof it, { __typename: "TotpMfaMethod" }> =>
      it.__typename === "TotpMfaMethod",
  );

  const phoneMethod = state.supportedMethods.find(
    (it): it is Extract<typeof it, { __typename: "SmsMfaMethod" }> =>
      it.__typename === "SmsMfaMethod",
  );

  return {
    mfaByTotp: totpMethod
      ? { tentativeQrCode: totpMethod.tentativeQrCode }
      : undefined,
    // @ts-ignore: Valid because at least one method is available.
    mfaByPhone: phoneMethod
      ? {
          accountEmail,
          tentativePhoneNumber: phoneMethod.tentativePhone,
          antiAbuseToken: phoneMethod.mfaBySmsAntiAbuseToken,
        }
      : undefined,
  };
};
