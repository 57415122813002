import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { notifier } from "utils/notifier";
import { consumeQueuedNotifications } from "utils/queued-notifier";

/**
 * Listens to notifications queued from previous browsing sessions,
 * and replays them. For instance, this allows notifications to be
 * shown right after a hard redirect.
 */
export const QueuedNotificationsListener = () => {
  const location = useLocation();

  useEffect(() => {
    consumeQueuedNotifications().forEach(({ type, message }) => {
      switch (type) {
        case "success":
          notifier.success(message);
          break;
        case "info":
          notifier.info(message);
          break;
        case "error":
          notifier.error({ user: message });
          break;
      }
    });
  }, [location]);

  return null;
};
