declare global {
  interface Object {
    let<T, K>(
      this: NonNullable<T>,
      functionToApply: (arg: NonNullable<T>) => K,
    ): K;
    // .values conflict with current implementation of babel regenerator runtime
    // This could probably be changed once we migrated to less polyfill bundle with vite
    toValues<Key extends string, Value>(this: Record<Key, Value>): Value[];
    // Name consistency with toValues
    toEntries<Value>(this: Record<string, Value>): [string, Value][];

    /**
     * Returns a copy of the object without the given key, if it exists.
     */
    withoutKey<T, K extends PropertyKey>(this: T, key: K): Omit<T, K>;
  }
}

const defineObjectProperty = <T extends keyof Object>(
  key: T,
  value: Object[T],
) => {
  // We need to make it writable to ensure 3rd party libs can do: var x = {}; x.let = "whatever"
  // We got a conflict with lz-string
  Object.defineProperty(Object.prototype, key, { value, writable: true });
};

defineObjectProperty("let", function (this, functionToApply) {
  // @ts-ignore
  return functionToApply(this.valueOf());
});

defineObjectProperty("toValues", function (this) {
  return Object.values(this);
});

defineObjectProperty("toEntries", function (this) {
  return Object.entries(this);
});

defineObjectProperty("withoutKey", function (this, key) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [key]: _, ...rest } = this;
  return rest;
});

export {};
