import { staticT as t } from "i18n";

import { ConfirmationModal, ConfirmationModalProps } from "./ConfirmationModal";
import {
  ControlledConfirmationModal,
  ControlledConfirmationModalProps,
} from "./ControlledConfirmationModal";

const getDeletionProps = (suffix: string, ctaLabel: string | undefined) => ({
  message: `${t(
    "deletion_confirmation_modal.are_you_sure_you_want",
  )} ${suffix}`.addNonBrakingSpaces(),
  cta: {
    label: ctaLabel ?? t("modal.deletion_confirmation_modal.delete"),
    danger: true,
  },
});

export type DeletionConfirmationModalProps = {
  suffix: string;
  ctaLabel?: string;
} & Omit<ConfirmationModalProps, "body" | "cta">;

export const DeletionConfirmationModal = ({
  suffix,
  ctaLabel,
  ...modalProps
}: DeletionConfirmationModalProps) => {
  const { message, cta } = getDeletionProps(suffix, ctaLabel);
  return <ConfirmationModal body={message} cta={cta} {...modalProps} />;
};

export const ControlledDeletionConfirmationModal = ({
  suffix,
  ctaLabel,
  ...modalProps
}: {
  suffix: string;
  ctaLabel?: string;
} & Omit<ControlledConfirmationModalProps, "children" | "cta">) => {
  const { message, cta } = getDeletionProps(suffix, ctaLabel);
  return (
    <ControlledConfirmationModal children={message} cta={cta} {...modalProps} />
  );
};
