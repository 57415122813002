import { useField } from "hooks/useField";

import { DatePicker, DatePickerProps } from "./DatePicker";

export const FormDatePicker = <IsClearable extends boolean>({
  name,
  disabled,
  ...props
}: Omit<DatePickerProps<IsClearable>, "value" | "onChange" | "error"> & {
  name: string;
}) => {
  const [{ value, disabled: disabledField }, { error }, { setValue }] =
    useField<ISOString | undefined>({ name, disabled });

  return (
    <DatePicker
      value={value}
      disabled={disabledField}
      onChange={setValue}
      error={error}
      {...props}
    />
  );
};
