import { ReactNode } from "react";

import { useTargetState } from "hooks";

import { Popover, PopoverProps } from "./Popover";

export type UncontrolledPopoverProps = Omit<
  PopoverProps,
  "children" | "target" | "onClose"
> & {
  children: (props: {
    opened: boolean;
    setTarget: ReturnType<typeof useTargetState>[1];
  }) => JSX.Element | null;
  content: ReactNode | ((close: () => void) => ReactNode);
};

export const UncontrolledPopover = ({
  children,
  content,
  ...props
}: UncontrolledPopoverProps) => {
  const [target, setTarget] = useTargetState();

  return (
    <>
      {children({ opened: !!target, setTarget })}
      {target && (
        <Popover
          target={target}
          onClose={() => setTarget(undefined)}
          {...props}
        >
          {typeof content === "function"
            ? content(() => setTarget(undefined))
            : content}
        </Popover>
      )}
    </>
  );
};
