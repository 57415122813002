import * as Sentry from "@sentry/browser";
import { toast } from "react-toastify";

export const notifier = {
  error: ({
    user,
    sentry,
  }: {
    user?: string;
    sentry?:
      | { message: string; level?: "warning" | "error"; exception?: never }
      | { exception: any; message?: never };
  }) => {
    if (user) toast.error(user, { className: "toast bg-danger" });
    if (sentry) {
      if (sentry.message) {
        Sentry.captureMessage(
          sentry.message,
          sentry.level === "error" ? "error" : "warning",
        );
      } else {
        console.error(sentry.exception);
        Sentry.captureException(sentry.exception);
      }
    }
  },
  success: (message: string) =>
    toast.success(message, { className: "toast bg-success" }),
  info: (message: string) =>
    toast.info(message, { className: "toast bg-primary" }),
};
