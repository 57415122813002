import { ReactNode, useState } from "react";
import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { TopSafeArea } from "components/Mobile/SafeArea";
import { Spinner } from "components/Spinner/Spinner";
import { useAppVersion } from "contexts/AppVersion/AppVersionContext";
import { useTranslation } from "i18n";
import { Translation } from "i18n/Translation";

export const OutdatedVersionBanner = () => {
  const t = useTranslation();
  const { minimumCodeVersion, downloadingUpdate, state, availableCodeVersion } =
    useAppVersion();
  const [dismissedAvailableVersions, setDismissedAvailableVersions] = useState<
    number[]
  >([]);
  const { reload } = useAppVersion();

  if (state === "NONE" || !availableCodeVersion || !minimumCodeVersion) {
    return null;
  }

  if (
    (state === "UPDATE_AVAILABLE" || state === "UNKNOWN_ENUM_USED") &&
    dismissedAvailableVersions.includes(availableCodeVersion)
  ) {
    return null;
  }

  const isInfoStyle =
    state === "UPDATE_AVAILABLE" || state === "UNKNOWN_ENUM_USED";

  const ReloadButton = ({ children }: { children: ReactNode }) => (
    <button
      className="underline"
      onClick={() => reload()}
      children={children}
    />
  );

  return (
    <div
      className={classNames(
        "flex-col",
        isInfoStyle
          ? "bg-warning-100 text-warning-300"
          : "bg-white fixed inset-0 flex-center z-modal text-18",
      )}
    >
      <TopSafeArea />
      {downloadingUpdate ? (
        <div className="flex-center py-8">
          <div>{t("outdated_version_banner.downloading_the_new_version")}</div>
          <Spinner small inline className={classNames("ml-8 text-body")} />
        </div>
      ) : (
        <div className="py-8 px-44 flex-center">
          {isInfoStyle && <Icon name="info" className="mr-6" />}
          <span className="flex-shrink text-center">
            {
              {
                UPDATE_AVAILABLE: (
                  <Translation
                    k="outdated_version_banner.you_can_reload_the_page"
                    components={{ button: ReloadButton }}
                  />
                ),
                UNKNOWN_ENUM_USED: (
                  <Translation
                    k="outdated_version_banner.some_data_may_not_be_displayed"
                    components={{ button: ReloadButton }}
                  />
                ),
                RELEASING: t(
                  "outdated_version_banner.a_new_version_is_being_deployed",
                ),
                OUTDATED: (
                  <Translation
                    k="outdated_version_banner.please"
                    components={{ button: ReloadButton }}
                  />
                ),
              }[state]
            }
          </span>
          {isInfoStyle && (
            <ClickableIcon
              name="close"
              className="absolute right-6"
              onClick={() =>
                setDismissedAvailableVersions(
                  dismissedAvailableVersions.concat(availableCodeVersion),
                )
              }
            />
          )}
        </div>
      )}
      {!isInfoStyle && (
        <Icon name="logo" size={24} className="flex-center mx-auto mb-24" />
      )}
    </div>
  );
};
