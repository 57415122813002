/**
 replaceAll is a recent addition to the JS API: https://caniuse.com/?search=replaceall
 While it could be easily replaced with global regex, it's better to force users to update
 to avoid hard to debug CSS issues

 We also force people to Safari 14 by detecting the presence of MediaRecorder API.
 */
import { TopSafeArea } from "components/Mobile/SafeArea";
import { useTranslation } from "i18n";

const outdated = (() => {
  try {
    "aa".replaceAll("a", "b");
    MediaRecorder.toString();
    return false;
  } catch {
    return true;
  }
})();

export const OutdatedBrowserBanner = () => {
  const t = useTranslation();
  if (!outdated) return null;

  return (
    <div className="w-full bg-orange flex-col">
      <TopSafeArea />
      <div className="text-white p-8 text-center">
        {t(
          "outdated_browser_banner.please_note_this_browser_is_not_up_to_date",
        )}
      </div>
    </div>
  );
};
