import classNames from "classnames";

import styles from "./label.module.css";

export type CommonPropsLabelProps = {
  label?: string;
  error?: string;
  hint?: string | JSX.Element;
};
export type NamedLabelProps = CommonPropsLabelProps & {
  name: string;
  useDiv?: never;
  wrapperClassName?: string;
};
export type UnnamedLabelProps = CommonPropsLabelProps & {
  useDiv: true;
  wrapperClassName?: string;
};

export const Label = ({
  label,
  hint,
  error,
  wrapperClassName,
  ...rest
}: NamedLabelProps | UnnamedLabelProps) =>
  label || error || hint ? (
    <div className={classNames(styles.wrapper, wrapperClassName)}>
      {label ? (
        rest.useDiv ? (
          <div className={styles.left}>{label}</div>
        ) : (
          <label htmlFor={rest.name} className={styles.left}>
            {label}
          </label>
        )
      ) : null}
      {(error || hint) && (
        <div
          className={classNames("flex-grow-0 flex-shrink flex-basis-auto", {
            "text-danger": error,
          })}
        >
          {error ?? hint}
        </div>
      )}
    </div>
  ) : null;
