import { SidebarRoutes } from "components/Routes/SidebarRoutes";
import { useCopilotApiUser } from "contexts/User/UserContext";
import { MainSidebar, useConfigureSentryScope } from "singletons";
import { filterAvailableSidebarItems, SidebarItem } from "types";

import { routes } from "../../routes";
import { Help } from "./Help/Help";
import { ACCOUNT_MENU_ITEMS, MAIN_SIDEBAR_ITEMS } from "./pages";

export const CopilotApiApp = () => {
  const userContext = useCopilotApiUser();
  useConfigureSentryScope();

  const availableMainSidebarItems = filterAvailableSidebarItems(
    MAIN_SIDEBAR_ITEMS,
    userContext,
  );

  const availableAccountMenuItems = filterAvailableSidebarItems(
    ACCOUNT_MENU_ITEMS,
    userContext,
  );

  return (
    <div className="flex-col flex-fill lg:flex-row">
      <MainSidebar
        mainSidebarItems={availableMainSidebarItems}
        accountMenuItems={availableAccountMenuItems}
      />
      <SidebarRoutes
        items={(availableMainSidebarItems as SidebarItem[]).concat(
          availableAccountMenuItems,
        )}
        withRedirect
        otherRoutes={[{ to: routes.HELP, Component: Help }]}
      />
    </div>
  );
};
