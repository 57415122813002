import { useEffect, useRef, useState } from "react";

import { Icon } from "components/Icon/Icon";
import { PasswordStrengthBar } from "components/PasswordStrengthBar/PasswordStrengthBar";
import { Popover } from "components/Popover/Popover";
import { useFormState } from "hooks/useFormState";
import { useIsDesktop } from "hooks/useMediaQuery";
import { usePasswordEstimator } from "hooks/usePasswordEstimator";
import { useTranslation } from "i18n";

import { FormState } from "../Form/FormState";
import confettiSrc from "./confetti.jpg";
import { FormPasswordInput, PasswordInputProps } from "./PasswordInput";

export const FormPasswordInputWithValidation = ({
  name,
  onBlur,
  onFocus,
  ...rest
}: Omit<PasswordInputProps, "inputRef">) => {
  const passwordRef = useRef<HTMLInputElement>(null);
  const { estimate, passwordStrength, isStrongEnough, warning, suggestions } =
    usePasswordEstimator();
  const [passwordFocused, setPasswordFocused] = useState(true);
  const isDesktop = useIsDesktop();
  const t = useTranslation();

  const form = useFormState<Record<string, string | undefined>>();
  const initialValue = form.initialValues[name];
  useEffect(() => {
    if (initialValue) estimate(initialValue);
  }, [estimate, initialValue]);

  return (
    <>
      <FormPasswordInput
        name={name}
        inputRef={passwordRef}
        onBlur={(e) => {
          onBlur?.(e);
          setPasswordFocused(false);
        }}
        onFocus={(e) => {
          onFocus?.(e);
          setPasswordFocused(true);
        }}
        onChange={(e) => estimate(e.target.value)}
        validate={() =>
          isStrongEnough
            ? undefined
            : t("form_password_input_with_validation.not_strong_enough")
        }
        {...rest}
      />
      <FormState<{ [key: string]: string }>>
        {({ values }) => (
          <>
            {isDesktop &&
            values[name] &&
            ((passwordFocused && (suggestions.length > 0 || isStrongEnough)) ||
              (!isStrongEnough && !passwordFocused)) ? (
              <Popover
                target={passwordRef.current!}
                position="right"
                allowScrolling
                className="hidden lg:flex-col self-center p-24 space-y-16"
                style={{
                  backgroundImage: isStrongEnough
                    ? `url('${confettiSrc}') `
                    : undefined,
                  backgroundSize: "contain",
                }}
                onClose={undefined}
              >
                {isStrongEnough ? (
                  <>
                    <div
                      className="bg-grey-100 rounded-full flex-center mx-auto"
                      style={{ height: 100, width: 100 }}
                    >
                      <div
                        className="bg-white rounded-full flex-center"
                        style={{ height: 89, width: 89 }}
                      >
                        <Icon name="check" className="text-primary" size={25} />
                      </div>
                    </div>
                    <div className="mt-16 text-center text-primary-dark font-medium bg-white">
                      {t(
                        "form.input.form_password_input_with_validation.perfect",
                      )}
                    </div>
                  </>
                ) : (
                  <Suggestions warning={warning} suggestions={suggestions} />
                )}
              </Popover>
            ) : null}
            <div className="lg:hidden space-y-8">
              <Suggestions warning={warning} suggestions={suggestions} />
            </div>
          </>
        )}
      </FormState>
      <PasswordStrengthBar strength={passwordStrength} />
    </>
  );
};

const Suggestions = ({
  warning,
  suggestions,
}: {
  warning?: string;
  suggestions: string[];
}) => (
  <>
    {warning && (
      <div
        key={warning}
        className="flex items-center text-primary-dark text-14 font-medium"
      >
        <Icon name="warning" className="mr-8" />
        <span className="text-body"> {warning}</span>
      </div>
    )}
    {suggestions.map((suggestion) => (
      <div
        key={suggestion}
        className="flex items-center text-primary-dark text-14 font-medium"
      >
        <Icon name="chevron" className="mr-8" />
        <span className="text-body"> {suggestion}</span>
      </div>
    ))}
  </>
);
