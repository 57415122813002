import { createPath, parsePath, PartialPath } from "history";

// Adds search params to the given absolute URL, which may already contain search params.
export const makeUrl = (
  baseUrl: string,
  ...params: ConstructorParameters<typeof URLSearchParams>[0][]
): string => {
  const builder = new URL(baseUrl);
  appendSearchParams(builder.searchParams, ...params);
  return builder.toString();
};

// Adds search params to the given relative path, which may already contain search params.
export const makePath = (
  basePath: string | PartialPath,
  ...paramsToAppend: ConstructorParameters<typeof URLSearchParams>[0][]
): string => {
  const partialPath =
    typeof basePath === "string" ? parsePath(basePath) : basePath;
  const searchParams = new URLSearchParams(partialPath.search);
  appendSearchParams(searchParams, ...paramsToAppend);
  return createPath({
    ...partialPath,
    search: searchParams.toString().isNotEmpty()
      ? `?${searchParams.toString()}`
      : undefined,
  });
};

export const makeSearch = (
  ...params: ConstructorParameters<typeof URLSearchParams>[0][]
) => {
  const searchParams = new URLSearchParams();
  appendSearchParams(searchParams, ...params);
  return searchParams.toString().isNotEmpty()
    ? `?${searchParams.toString()}`
    : "";
};

// Mutates `sourceParams` to add the search params from `otherParams`.
const appendSearchParams = (
  sourceParams: URLSearchParams,
  ...otherParams: ConstructorParameters<typeof URLSearchParams>[0][]
) => {
  otherParams.forEach((constructorValue) => {
    new URLSearchParams(constructorValue).forEach((value, key) =>
      sourceParams.append(key, value),
    );
  });
  return sourceParams;
};
