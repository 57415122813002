import { useEffect, useMemo, useState } from "react";
import { ApolloLink, RequestHandler } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { Client } from "graphql-ws";

import { GqlSchemaAuthenticationKind, GRAPHQL_ENDPOINTS } from "api/endpoints";
import { createGraphQLWsClient } from "api/socket-client";
import { RequestContext } from "api/types";
import { useGetAuthenticatedRequestContextOrThrow } from "api/utils";
import { notifier } from "utils/notifier";
import { errorObservable } from "utils/observable";

import { SchemaType } from "../base-types";

export const useSchemaSubscriptionLink = <T extends SchemaType>(
  schemaType: T,
) => {
  const definition = GRAPHQL_ENDPOINTS[schemaType];
  const authenticationKind: GqlSchemaAuthenticationKind<T> =
    definition.authenticationKind;
  const getAuthenticatedRequestContextOrThrow =
    useGetAuthenticatedRequestContextOrThrow(authenticationKind);

  const [socketClient, setSocketClient] = useState<Client | undefined>();
  const [isSocketReady, setSocketReady] = useState(false);

  useEffect(() => {
    // By construction, this can only be true for authenticated schemas.
    const shouldOpen = "withSocket" in definition && definition.withSocket;
    if (!shouldOpen) return;

    // This can fail "for reasonable reasons" if the user is not connected or
    // is connected to the wrong schema, so we just ignore errors.
    let requestContext: RequestContext<GqlSchemaAuthenticationKind<T>>;
    try {
      requestContext = getAuthenticatedRequestContextOrThrow();
    } catch {
      return;
    }
    let client: Client | undefined;
    let disposed = false;
    createGraphQLWsClient(
      schemaType,
      { ...definition, isSocket: true },
      requestContext,
      {
        onClosed: () => setSocketReady(false),
        onConnected: () => setSocketReady(true),
      },
    )
      .then((c) => {
        if (disposed) return;
        client = c;
        setSocketClient(c);
      })
      .catch((err) => {
        notifier.error({
          sentry: {
            exception: err,
          },
        });
      });

    return () => {
      disposed = true;
      client?.dispose();
    };
  }, [schemaType, definition, getAuthenticatedRequestContextOrThrow]);

  const subscriptionLink: ApolloLink | RequestHandler = useMemo(() => {
    if (!socketClient) {
      return () =>
        errorObservable(
          `Subscriptions are not supported for schema ${schemaType}.`,
        );
    }

    return new GraphQLWsLink(socketClient);
  }, [socketClient, schemaType]);

  return { subscriptionLink, isSocketReady };
};
