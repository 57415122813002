import { Select, SelectProps } from "../Select/Select";
import { getTimePossibilities, Time } from "./utils";

export type TimePickerProps = Omit<
  SelectProps<Time>,
  "options" | "getOptionId" | "getOptionLabel"
> & {
  options?: Time[];
};

export const TimePicker = ({
  onChange,
  options,
  ...props
}: TimePickerProps) => (
  <Select
    getOptionLabel={displayTime}
    getOptionId={displayTime}
    placeholder="hh:mm"
    options={options ?? DEFAULT_POSSIBILITIES}
    onChange={onChange}
    hideDropdownIndicator
    optionsStyle={{
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 5,
      paddingBottom: 5,
    }}
    {...props}
  />
);

const DEFAULT_POSSIBILITIES = getTimePossibilities(8, 20, 15);

const displayTime = (time: Time) =>
  `${String(time.hours).padStart(2, "0")}:${String(time.minutes).padStart(
    2,
    "0",
  )}`;
