import { Button } from "components/Button/Button";
import { useTranslation } from "i18n";

import notFoundSrc from "./notFound.png";

export const NotFound = () => {
  const t = useTranslation();

  return (
    <div className="flex-col items-center text-center">
      <img src={notFoundSrc} style={{ height: 184 }} alt="Illustration" />
      <h1 className="mt-24 text-24 text-primary-dark font-medium">
        {t("error_page.not_found.nothing_to_see_here")}
      </h1>
      <span className="mt-12">
        {t("not_found.the_requested_page_does_not_exist_or_has_been_deleted")}
      </span>
      <Button className="mt-32" label={t("not_found.button_to_home")} to="/" />
    </div>
  );
};
