import { useCallback } from "react";
import { State, To } from "history";
import { useNavigate, useSearchParams } from "react-router-dom";

import { makePath } from "utils/url";

export const TARGET_FRONTEND_SEARCH_PARAM = "targetFrontend";
export const TARGET_SUB_ORGANIZATION_UUID_PARAM = "targetSubOrganizationUuid";

export const useNavigatePreservingTargetParams = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  return useCallback(
    (
      to: To,
      options?: {
        replace?: boolean;
        state?: State;
      },
    ) => {
      const targetFrontend = params.get(TARGET_FRONTEND_SEARCH_PARAM);
      const targetSubOrganizationUuid = params.get(
        TARGET_SUB_ORGANIZATION_UUID_PARAM,
      );
      const updatedTo = makePath(to, {
        ...(targetFrontend ? { targetFrontend } : {}),
        ...(targetSubOrganizationUuid ? { targetSubOrganizationUuid } : {}),
      });
      navigate(updatedTo, options);
    },
    [navigate, params],
  );
};
