import { Background } from "components/Background/Backgound";
import { Button } from "components/Button/Button";
import { useUser } from "contexts/User/UserContext";
import { StartAccountPasswordReset } from "generated/unauth-account";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

export const SecuritySettings = () => {
  const t = useTranslation();
  const { email } = useUser();
  const [startAccountPasswordReset] = useMutation(StartAccountPasswordReset, {
    onSuccess: () => notifier.success(t("preferences.security.email_success")),
  });

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <div className="flex-col">
        <h1 className="text-primary-dark text-24 font-bold">
          {t("preferences.security.security_settings")}
        </h1>
      </div>
      {email && (
        // Changing the password doesn't make sense if the user doesn't have an email, so this is fine.
        <div className="flex-col w-full items-start gap-24 p-32 bg-white border rounded shadow-sm-outlined">
          <div className="space-y-12">
            <h3 className="font-bold text-18 text-primary-dark">
              {t("preferences.security.password")}
            </h3>
            <p>{t("preferences.security.password_subtitle")}</p>
            <Button
              label={t("preferences.security.button_label")}
              onClick={() =>
                startAccountPasswordReset(
                  { email },
                  { requestContext: { regionByAccountEmail: email } },
                )
              }
            />
          </div>
        </div>
      )}
    </Background>
  );
};
