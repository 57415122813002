import { HTMLProps, useState } from "react";
import classNames from "classnames";
import { addDays, setHours, setMinutes } from "date-fns";
import * as Yup from "yup";

import { Button } from "components/Button/Button";
import { LinkSubmit } from "components/Button/LinkSubmit";
import { Submit } from "components/Button/Submit";
import { ButtonGroup } from "components/Form/ButtonGroup/ButtonGroup";
import { FormDateAndHour } from "components/Form/DatePicker/FormDateAndHour";
import { FormDateAndHours } from "components/Form/DatePicker/FormDateAndHours";
import { FormDatePicker } from "components/Form/DatePicker/FormDatePicker";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { FormPhoneInput } from "components/Form/Input/FormPhoneInput";
import { Input } from "components/Form/Input/Input";
import { FormPasswordInput } from "components/Form/Input/PasswordInput";
import { FormMultiSelect } from "components/Form/Select/FormMultiSelect";
import { FormSelect } from "components/Form/Select/FormSelect";
import { FormSwitch } from "components/Form/Switch/FormSwitch";
import { Icon } from "components/Icon/Icon";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { iconLibrary, IconName } from "icon-library";
import { colors } from "styles";

type FormValues = {
  input1: string;
  input2: string;
  input3: string;
  input4: string;
  input5: string;
  inputPhone: string | undefined;
  select1: string;
  select2: string | undefined;
  select3: string;
  select4: string[];
  switch: boolean;
};

type ButtonGroupType = "Yes" | "No" | "Maybe";
const ButtonGroupValues = ["Yes", "No", "Maybe"] as const;

export const PaletteApp = () => {
  const [search, setSearch] = useState("");
  const [buttonGroupSelected, setButtonGroupSelected] =
    useState<ButtonGroupType>("Yes");

  return (
    <div className="flex-col p-44 space-y-36 h-full overflow-y-auto">
      <Section name="Icons">
        <Input
          name="searchIcon"
          label="Search an icon"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="flex-wrap justify-center">
          {Object.keys(iconLibrary)
            .filter((key) => key.includes(search))
            .map((key) => (
              <TooltipWrapper key={key} label={key} className="m-24">
                <Icon name={key as IconName} size={24} />
              </TooltipWrapper>
            ))}
        </div>
        <div>
          Don't find the one you are looking for? See{" "}
          <a
            className="link"
            href="https://github.com/nabla/health/tree/master/web#adding-an-icon"
            rel="noopener noreferrer"
            target="_blank"
          >
            add an icon
          </a>
        </div>
      </Section>
      <Section name="Colors">
        <div className="flex-wrap justify-center">
          {Object.entries(colors).map(([name, value]) => (
            <div key={name} className="flex-col items-center m-24">
              <div className="h-44 w-44" style={{ backgroundColor: value }} />
              <div className="text-primary-dark">{name}</div>
              <div>{value}</div>
            </div>
          ))}
        </div>
      </Section>
      <Section name="Button">
        <div className="grid grid-cols-10 gap-10 items-center text-center">
          <span className="col-start-2 col-span-3 font-semibold">
            Primary button
          </span>
          <span className="col-span-3 font-semibold">Secondary button</span>
          <span className="col-span-3 font-semibold">Tertiary button</span>
          <span className="row-start-2 row-span-2 font-semibold">Default</span>
          <span className="row-start-4 row-span-2 font-semibold">Danger</span>
          <div>
            <Button label="Click me" small primary />
          </div>
          <div>
            <Button label="Click me" medium primary />
          </div>
          <div>
            <Button label="Click me" large primary />
          </div>
          <div>
            <Button label="Click me" small secondary />
          </div>
          <div>
            <Button label="Click me" medium secondary />
          </div>
          <div>
            <Button label="Click me" large secondary />
          </div>
          <div>
            <Button label="Click me" small tertiary />
          </div>
          <div>
            <Button label="Click me" medium tertiary />
          </div>
          <div>
            <Button label="Click me" large tertiary />
          </div>
          <div>
            <Button label="Click me" small primary disabled />
          </div>
          <div>
            <Button label="Click me" medium primary disabled />
          </div>
          <div>
            <Button label="Click me" large primary disabled />
          </div>
          <div>
            <Button label="Click me" small secondary disabled />
          </div>
          <div>
            <Button label="Click me" medium secondary disabled />
          </div>
          <div>
            <Button label="Click me" large secondary disabled />
          </div>
          <div>
            <Button label="Click me" small tertiary disabled />
          </div>
          <div>
            <Button label="Click me" medium tertiary disabled />
          </div>
          <div>
            <Button label="Click me" large tertiary disabled />
          </div>

          <div>
            <Button label="Click me" small primary danger />
          </div>
          <div>
            <Button label="Click me" medium primary danger />
          </div>
          <div>
            <Button label="Click me" large primary danger />
          </div>
          <div>
            <Button label="Click me" small secondary danger />
          </div>
          <div>
            <Button label="Click me" medium secondary danger />
          </div>
          <div>
            <Button label="Click me" large secondary danger />
          </div>
          <div className="col-span-3" />
          <div>
            <Button label="Click me" small primary disabled danger />
          </div>
          <div>
            <Button label="Click me" medium primary disabled danger />
          </div>
          <div>
            <Button label="Click me" large primary disabled danger />
          </div>
          <div>
            <Button label="Click me" small secondary disabled danger />
          </div>
          <div>
            <Button label="Click me" medium secondary disabled danger />
          </div>
          <div>
            <Button label="Click me" large secondary disabled danger />
          </div>
        </div>
        <div className="w-full flex-wrap justify-center gap-16">
          <Button leftIcon="archive" label="Icon Prefix" />
          <Button rightIcon="rightArrow" label="Icon Suffix" />
          <Button leftIcon="search" rightIcon="doubleChevron" label="Icons" />

          <Button loading label="loading" className="max-w-330" />
          <Button
            label="Click me to load"
            className="max-w-330"
            onClick={() => new Promise((resolve) => setTimeout(resolve, 2000))}
          />
        </div>
      </Section>
      <Section name="Form" width={500}>
        <Form<FormValues>
          className="w-full space-y-16"
          initialValues={{
            input1: "",
            input2: "email",
            input3: "disabled",
            input4: "mini 15",
            input5: "password",
            inputPhone: "",
            select1: "Paris",
            select2: undefined,
            select3: "",
            select4: [],
            switch: true,
          }}
          validationSchema={{
            input1: "required",
            input2: Yup.string().email(),
            input4: Yup.string().min(15),
            inputPhone: "required",
          }}
          onSubmit={() => Promise.resolve()}
        >
          <h3 className="text-24 text-primary-dark">Input</h3>
          <FormInput name="input1" placeholder="Placeholder" label="Label" />
          <FormInput name="input2" label="Label" />
          <FormPhoneInput
            name="inputPhone"
            label="Phone number"
            placeholder="Your phone number"
          />
          <FormInput name="input3" disabled />
          <FormInput name="input4" label="Min 15 chars" />
          <FormPasswordInput name="input5" autoComplete="new-password" />
          <h3 className="text-24 text-primary-dark">Select</h3>
          <FormSelect name="select1" label="Single" options={CITIES} />
          <FormSelect
            name="select2"
            label="Clearable"
            options={CITIES}
            isClearable
          />
          <FormSelect
            name="select3"
            label="Creatable"
            options={CITIES}
            creatable
          />
          <FormMultiSelect name="select4" label="Multiple" options={CITIES} />
          <h3 className="text-24 text-primary-dark">Date and Time</h3>
          <FormDatePicker name="datePickerDay" label="Only days" />
          <FormDateAndHour name="dateAndHour" label="With hour" />
          <FormDateAndHour
            name="dateAndHourWithPossibilities"
            label="With a given set of possibilities"
            possibilities={[
              setMinutes(setHours(addDays(new Date(), 1), 12), 0).toISOString(),
              setMinutes(setHours(addDays(new Date(), 1), 13), 0).toISOString(),
              setMinutes(setHours(addDays(new Date(), 2), 12), 0).toISOString(),
            ]}
          />
          <FormDateAndHours
            startName="startDate"
            endName="endDate"
            label="With hours"
          />
          <h3 className="text-24 text-primary-dark">Switch</h3>
          <FormSwitch name="switch" label="Switch" />
          <FormSwitch name="switch" label="Disabled" disabled />
          <h3 className="text-24 text-primary-dark">ButtonGroup</h3>
          <ButtonGroup
            value={buttonGroupSelected}
            options={ButtonGroupValues}
            onChange={(o) => setButtonGroupSelected(o)}
            getOptionLabel={(o) => o}
          />
          <h3 className="text-24 text-primary-dark">Submit</h3>
          <Submit label="Submit" />
          <LinkSubmit label="LinkSubmit" />
        </Form>
      </Section>
      <Section name="Tooltip">
        <div className="w-full flex justify-center space-x-30">
          <TooltipWrapper label="This tooltip is on the top" position="top">
            <span>Top</span>
          </TooltipWrapper>
          <TooltipWrapper
            label="This tooltip is on the bottom"
            position="bottom"
          >
            <span>Bottom</span>
          </TooltipWrapper>
          <TooltipWrapper label="This tooltip is on the right" position="right">
            <span>Right</span>
          </TooltipWrapper>
          <TooltipWrapper label="This tooltip is on the left" position="left">
            <span>Left</span>
          </TooltipWrapper>
        </div>
      </Section>
    </div>
  );
};

const Section = ({
  name,
  width,
  className,
  style,
  children,
  ...props
}: { name: string; width?: number } & HTMLProps<HTMLElement>) => (
  <section
    className={classNames(
      "flex-col items-center space-y-16",
      { "mx-auto": width },
      className,
    )}
    style={{ width, ...style }}
    {...props}
  >
    <h2 className="title">{name}</h2>
    {children}
  </section>
);

const CITIES = [
  "Paris",
  "Marseille",
  "Lyon",
  "Toulouse",
  "Nice",
  "Nantes",
  "Montpellier",
  "Strasbourg",
  "Bordeaux",
  "Lille",
  "Rennes",
  "Reims",
  "Saint-Étienne",
  "Toulon",
  "Le Havre",
  "Grenoble",
  "Dijon",
  "Angers",
  "Nîmes",
  "Saint-Denis",
  "Villeurbanne",
  "Clermont-Ferrand",
  "Le Mans",
  "Aix-en-Provence",
  "Brest",
  "Tours",
];
