import { useCallback, useMemo } from "react";
import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  InMemoryCache,
  useMutation as useApolloMutation,
} from "@apollo/client";

import { useMaybeAuth } from "auth/AuthContext";
import { Mutation, SchemaType } from "base-types";
import { useAppVersion } from "contexts/AppVersion/AppVersionContext";
import { useSyncRef } from "hooks/useSyncRef";
import { GraphQLClient } from "types";

import { notifyGraphQLError, parseApolloError } from "./errors";
import { RequestContextOptions } from "./request-context-utils";
import { WithOperation } from "./types";
import { VariablesWithoutUpdateInputs, wrapUpdateInputs } from "./updateInputs";
import {
  Executable,
  MutationInstanciationOptions,
  UseMutationFn,
} from "./useMutation";
import { useUnauthSchemaHttpLink } from "./useUnauthSchemaHttpLink";
import { getOutput } from "./utils";

export const useUnauthMutation: UseMutationFn = <
  Data,
  Variables,
  Schema extends SchemaType,
  ThrowOnErrorInstanciation extends boolean,
>(
  mutation: Mutation<Data, Variables, Schema>,
  options?: Omit<
    MutationInstanciationOptions<Data, Variables, ThrowOnErrorInstanciation>,
    "optimisticResponse"
  > &
    Partial<RequestContextOptions<Schema>>,
): [
  Executable<
    Data,
    VariablesWithoutUpdateInputs<Variables>,
    Schema,
    boolean,
    ThrowOnErrorInstanciation
  >,
  boolean,
] => {
  const {
    onSuccess,
    onError,
    variables: initialVariables,
    requestContext,
    throwOnError,
    ...unchangedOptions
  } = options ?? {};

  const httpLink = useUnauthSchemaHttpLink(mutation.schemaType);

  // TODO(Pierre) It works and that's probably ok for now because there's only one schema that is supposed
  //  to work with unauthenticated mutations (copilot-api-unauthenticated.graphql)
  //  If there was another, we'd need to build a list of clients like in GraphQLProvider per schema instead, like
  //  what we have with GraphqlProvider
  const client = useMemo(() => {
    const cache = new InMemoryCache();
    return new ApolloClient({
      link: new ApolloLink(httpLink),
      cache,
    }) as GraphQLClient;
  }, [httpLink]);

  const instantiationOptionsRef = useSyncRef({
    client,
    onSuccess,
    onError,
    updateInputsPaths: mutation.updateInputsPaths,
    requestContext,
  });

  const [execute, { loading }] = useApolloMutation<
    WithOperation<Data>,
    Variables
  >(mutation.document, {
    client,
    ...unchangedOptions,
    variables: wrapUpdateInputs(initialVariables, mutation.updateInputsPaths),
  });
  const { state: appVersionState } = useAppVersion();
  const auth = useMaybeAuth();

  return [
    useCallback(
      (variables, ...runtimeOptionsArray) => {
        const {
          requestContext: runtimeRequestContext,
          onError: runtimeOnError,
          throwOnError: runtimeThrowOnError,
        } = runtimeOptionsArray[0] ?? {};

        const actualRequestContext =
          runtimeRequestContext ??
          instantiationOptionsRef.current.requestContext;

        // See `useQuery` for an in-depth explanation.
        const apolloContext = {
          requestContext: actualRequestContext,
          queryDeduplication: false,
        };

        return execute({
          fetchPolicy: "no-cache",
          context: apolloContext,
          variables: wrapUpdateInputs(
            variables,
            instantiationOptionsRef.current.updateInputsPaths,
          ),
        })
          .then(({ data }) => getOutput(data))
          .catch((e: ApolloError) => {
            const parsedError = parseApolloError(e);
            if (throwOnError || runtimeThrowOnError) throw parsedError;
            const secondLevelHandler = () =>
              instantiationOptionsRef.current.onError
                ? instantiationOptionsRef.current.onError(parsedError, () =>
                    notifyGraphQLError(appVersionState, auth?.state, e),
                  )
                : notifyGraphQLError(appVersionState, auth?.state, e);
            runtimeOnError
              ? runtimeOnError(parsedError, secondLevelHandler)
              : secondLevelHandler();
          }) as Promise<Data>;
      },
      [auth, execute, instantiationOptionsRef, appVersionState, throwOnError],
    ),
    loading,
  ];
};
