import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  useField as useFormikField,
} from "formik";
import { FieldValidator } from "formik/dist/types";

import { useFormExtraContext } from "components/Form/Form/FormExtraContext";

import { useFormState } from "./useFormState";

export const useField = <Val = any>({
  name,
  disabled,
  validate,
}: {
  name: string;
  disabled?: boolean;
  validate?: FieldValidator;
}): [
  FieldInputProps<Val> & { disabled: boolean },
  FieldMetaProps<Val> & { valid: boolean },
  FieldHelperProps<Val>,
] => {
  const [fieldProps, { error, ...otherMetaProps }, helpersProps] =
    useFormikField<Val>({ name, validate });
  const { isSubmitting, submitCount } = useFormState();
  const { disabled: formDisabled } = useFormExtraContext();

  return [
    {
      ...fieldProps,
      disabled: isSubmitting || !!disabled || formDisabled,
    },
    {
      error: submitCount > 0 && error ? error : undefined,
      valid: !error,
      ...otherMetaProps,
    },
    helpersProps,
  ];
};
