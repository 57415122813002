import { VerifyMfaByTotpOrPhonePayload } from "auth/AuthContext";
import { IsEmailVerificationCodeValidData } from "generated/unauth-account";

export const buildVerifyMfaPayload = (
  accountEmail: string,
  state: IsEmailVerificationCodeValidData["mfaState"],
): VerifyMfaByTotpOrPhonePayload | null => {
  // MFA needs to already be setup in order for us to be able to verify it.
  if (state.__typename !== "SetupMfaState") return null;

  const totpMethod = state.supportedMethods.find(
    (it): it is Extract<typeof it, { __typename: "TotpMfaMethod" }> =>
      it.__typename === "TotpMfaMethod" && it.isSetup,
  );

  const phoneMethod = state.supportedMethods.find(
    (it): it is Extract<typeof it, { __typename: "SmsMfaMethod" }> =>
      it.__typename === "SmsMfaMethod" && it.isSetup,
  );

  return {
    mfaByTotp: totpMethod ? true : undefined,
    // @ts-ignore: Valid because at least one method is available.
    mfaByPhone: phoneMethod
      ? {
          accountEmail,
          phoneNumber: phoneMethod.phone!, // Valid because method is set up.
          antiAbuseToken: phoneMethod.mfaBySmsAntiAbuseToken,
        }
      : undefined,
  };
};
