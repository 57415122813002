import classNames from "classnames";

import { Spinner } from "components/Spinner/Spinner";
import { PaginationProps } from "graphql-client/usePaginatedQuery";
import { useTranslation } from "i18n";

export const NextPageButton = ({
  fetchingMore,
  nextPage,
  className,
}: { className?: string } & PaginationProps) => {
  const t = useTranslation();

  return fetchingMore ? (
    <Spinner inline small className="my-16" />
  ) : nextPage ? (
    <button
      className={classNames("button-link my-6 text-14", className)}
      style={{ lineHeight: "20px" }}
      onClick={() => nextPage()}
    >
      {t("button.next_page_button.show_more")}
    </button>
  ) : null;
};
