type CookieOptions = {
  path: string;
  domain: string;
  maxAgeSeconds: number;
  expires: Date;
  secure: boolean;
  sameSite: "lax" | "strict" | "none";
};

const getCookies = () =>
  document.cookie
    .split(";")
    .filter((pair) => pair.isNotBlank())
    .reduce((cookies, pair) => {
      const [rawKey, rawValue] = pair.split("=");
      const key = decodeURIComponent(rawKey.trim());
      const value = decodeURIComponent(rawValue.trim());
      return cookies.set(key, value);
    }, new Map<string, string>());

export const getCookie = (key: string) => getCookies().get(key);

export const setCookie = (
  key: string,
  value: string,
  {
    path,
    domain,
    maxAgeSeconds,
    expires,
    secure,
    sameSite,
  }: Partial<CookieOptions> = {},
) => {
  const p = [`${encodeURIComponent(key)}=${encodeURIComponent(value)}`];
  if (path !== undefined) p.push(`path=${path}`);
  if (domain !== undefined) p.push(`domain=${domain}`);
  if (maxAgeSeconds !== undefined) p.push(`max-age=${maxAgeSeconds}`);
  if (expires !== undefined) p.push(`expires=${expires.toUTCString()}`);
  if (secure !== undefined) p.push("secure");
  if (sameSite !== undefined) p.push(`samesite=${sameSite}`);
  document.cookie = p.join("; ");
};

export const removeCookie = (
  key: string,
  options: Omit<Partial<CookieOptions>, "maxAgeSeconds"> = {},
) => setCookie(key, "", { ...options, maxAgeSeconds: 0 });
