import { InputHTMLAttributes, ReactNode } from "react";
import classNames from "classnames";

import { Icon } from "components/Icon/Icon";
import { IconName } from "icon-library";

import styles from "./checkBox.module.css";

export type CheckBoxProps = {
  name: string;
  label?: string | ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
  hasError?: boolean;
  iconNameOn?: IconName;
  iconNameOff?: IconName;
} & Omit<InputHTMLAttributes<HTMLInputElement>, "style" | "onChange">;

export const CheckBox = ({
  name,
  label,
  checked,
  onChange,
  hasError,
  iconNameOn,
  iconNameOff,
  className,
  ...props
}: CheckBoxProps) => (
  <label className={classNames(styles.label, className)}>
    <div className="flex flex-shrink items-center leading-normal">
      <input
        type="checkbox"
        name={name}
        className={classNames("appearance-none w-16 h-16", {
          "mr-12": label,
        })}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        {...props}
      />
      <Icon
        name={checked ? iconNameOn ?? "checkOn" : iconNameOff ?? "checkOff"}
        className={classNames("absolute left-0", {
          "text-primary": checked,
          "text-danger": hasError,
        })}
      />
      {label}
    </div>
  </label>
);
