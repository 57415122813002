import { InputHTMLAttributes, RefObject } from "react";
import classNames from "classnames";

import { ExposedInputWrapperProps, InputWrapper } from "./InputWrapper";

export type InputProps = InputHTMLAttributes<HTMLInputElement> &
  ExposedInputWrapperProps & {
    inputRef?: RefObject<HTMLInputElement>;
    htmlRequired?: boolean;
  };

export const Input = ({
  wrapperClassName,
  label,
  error,
  hint,
  name,
  required,
  className,
  gray,
  thinGray,
  leftInnerElement,
  innerElement,
  rightInnerElement,
  rightInnerElementClassName,
  inlineError,

  inputRef,
  htmlRequired,

  maxLength,
  value,
  ...rest
}: InputProps) => (
  <InputWrapper
    wrapperClassName={wrapperClassName}
    label={label}
    error={error}
    hint={
      hint ??
      (maxLength && typeof value === "string"
        ? `${value.length}/${maxLength}`
        : undefined)
    }
    name={name}
    required={required}
    className={className}
    gray={gray}
    thinGray={thinGray}
    leftInnerElement={leftInnerElement}
    innerElement={innerElement}
    rightInnerElement={rightInnerElement}
    rightInnerElementClassName={rightInnerElementClassName}
    inlineError={inlineError}
  >
    {(fullClassName) => (
      <input
        spellCheck={false}
        ref={inputRef}
        id={name}
        name={name}
        value={value}
        maxLength={maxLength}
        className={classNames(fullClassName, {
          // Disable the number input spinner for number fields.
          "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none":
            rest.type === "number",
        })}
        required={htmlRequired}
        onWheel={(e) => {
          e.currentTarget.blur();
        }}
        {...rest}
      />
    )}
  </InputWrapper>
);
