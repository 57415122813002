import { ComponentProps, ReactNode } from "react";
import classNames from "classnames";
// eslint-disable-next-line no-restricted-imports
import { useI18nTranslation } from "react-i18next";

import { EnvironmentBanner } from "components/EnvironmentBanner/EnvironmentBanner";
import { run } from "utils";

import carePlatformBackground from "./care-platform-background.png";
import copilotBackgroundEn from "./copilot-background-en.png";
import copilotBackgroundFr from "./copilot-background-fr.png";
import styles from "./background.module.css";

export type PublicScreenImage = "CARE_PLATFORM" | "COPILOT" | "NONE";

export const PublicScreenWrapper = ({
  image = "COPILOT",
  children,
}: {
  image?: PublicScreenImage;
  children: ReactNode;
}) => {
  const { i18n } = useI18nTranslation();

  const imageData = run(() => {
    switch (image) {
      case "NONE":
        return null;
      case "CARE_PLATFORM":
        return {
          width: 800,
          source: carePlatformBackground,
        };
      case "COPILOT":
        return {
          width: 430,
          source:
            i18n.language === "fr" ? copilotBackgroundFr : copilotBackgroundEn,
        };
    }
  });

  return (
    <div className="flex h-full overflow-hidden">
      <div className="w-full overflow-auto lg:w-1/2 h-full bg-white flex-col items-center">
        <div className="w-full my-auto px-20 py-44 flex-col items-center">
          {children}
        </div>
      </div>

      <div
        className={classNames(
          styles.backgroundHolder,
          "hidden lg:w-1/2 lg:flex-center",
        )}
      >
        <Background />
        {imageData && (
          <img
            src={imageData.source}
            alt="Nabla"
            className="w-full p-20"
            style={{ maxWidth: imageData.width, marginBottom: -47, zIndex: 3 }}
          />
        )}
      </div>
    </div>
  );
};

export const PublicScreenWrapperWithEnvironmentBanner = ({
  children,
  ...props
}: ComponentProps<typeof PublicScreenWrapper>) => (
  <PublicScreenWrapper {...props}>
    <EnvironmentBanner />
    {children}
  </PublicScreenWrapper>
);

export const Background = () => (
  <>
    <div className={styles.backgroundGradient} />
    <div className={styles.backgroundPattern}>
      <div className={styles.backgroundPatternInner} />
    </div>
    <div className={styles.backgroundNoise} />
  </>
);
