import { ButtonHTMLAttributes } from "react";

import { useFormExtraContext } from "components/Form/Form/FormExtraContext";

import { useFormState } from "./useFormState";

export const useSubmit = <
  SubmitContext extends Record<string, unknown> = { genericRequired: true },
>({
  disabled,
  requiresDirty,
  requiresValid,
  context,
}: {
  disabled: boolean;
  requiresDirty?: boolean;
  requiresValid?: boolean;
  context?: SubmitContext;
}): {
  submitProps: Omit<ButtonHTMLAttributes<HTMLButtonElement>, "name">;
  isSubmitting: boolean;
} => {
  const { isSubmitting, isValid, dirty, submitForm } = useFormState<
    Record<string, unknown>,
    SubmitContext
  >();
  const { disabled: formDisabled } = useFormExtraContext();

  return {
    submitProps: {
      type: "submit",
      onClick: (e) => {
        // Avoid triggering `onSubmit` on the parent <Form>.
        e.preventDefault();
        e.stopPropagation();
        void submitForm(context);
      },
      disabled:
        disabled ||
        formDisabled ||
        (requiresDirty && !dirty) ||
        (requiresValid && !isValid),
    },
    isSubmitting,
  };
};
