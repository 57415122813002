import { ReactNode, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { Button } from "components/Button/Button";
import { TargetFrontendKnownValues } from "generated/unauth-account";
import { useTranslation } from "i18n";
import { getKnownValue } from "utils/enum";
import { copilotChromeExtensionId, copilotWebappUrl } from "utils/environment";

export const CopilotSubscriptionSucceeded = () => {
  const t = useTranslation();
  const [searchParams] = useSearchParams();
  const targetFrontend = getKnownValue(
    searchParams.get("targetFrontend"),
    TargetFrontendKnownValues,
  );

  const backToCopilot = useCallback(() => {
    switch (targetFrontend) {
      case "COPILOT_WEB_APP":
        window.location.href = copilotWebappUrl;
        break;
      case "COPILOT_CHROME_EXTENSION":
        window.chrome.runtime.sendMessage(copilotChromeExtensionId, {
          type: "open",
        });
        break;
      default:
        console.log("Redirect to target frontend", targetFrontend);
        break;
    }
  }, [targetFrontend]);

  return (
    <Container>
      {t("copilot.subscription_succeeded.message")}
      <br />
      {t("copilot.subscription_succeeded.description")}
      {targetFrontend && (
        <Button
          large
          secondary
          className="mt-30 text-black"
          label={t("copilot.subscription_succeeded.next")}
          onClick={backToCopilot}
        />
      )}
    </Container>
  );
};

const Container = ({ children }: { children: ReactNode }) => (
  <div className="flex absolute inset-0 bg-gradient-to-tr from-[#abf5ea] to-[#a1f7d6]">
    <div className="m-auto items-start p-44 leading-[28px] justify-center flex flex-col text-white font-normal rounded-[8px] max-w-[550px] bg-gradient-to-r from-[#323338] to-[#1f2024] border border-transparent shadow-[0_4px_4px_rgba(0,0,0,0.2)] font-['Euclid_Circular_B']">
      <div className="flex items-center gap-8 text-[25px] leading-[37.5px]">
        <svg
          width="80"
          height="24"
          viewBox="0 0 60 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="text-white"
        >
          <path
            d="M4.37265 16.9755V7.4996L11.8411 16.9755H14.7983V1.65003H11.3447V11.1043L3.89778 1.65003H0.940613V16.9755H4.37265ZM21.6548 17.2129C23.101 17.2129 24.1586 16.479 24.5904 15.8962V16.9755H27.8497V6.18291H24.5904V7.26217C24.1586 6.67937 23.101 5.94547 21.6548 5.94547C18.8271 5.94547 16.647 8.5141 16.647 11.5792C16.647 14.6443 18.8271 17.2129 21.6548 17.2129ZM22.3887 14.2558C20.9209 14.2558 19.928 13.1333 19.928 11.5792C19.928 10.0251 20.9209 8.90264 22.3887 8.90264C23.8565 8.90264 24.8494 10.0251 24.8494 11.5792C24.8494 13.1333 23.8565 14.2558 22.3887 14.2558ZM36.5409 17.2129C39.3686 17.2129 41.5487 14.6443 41.5487 11.5792C41.5487 8.5141 39.3686 5.94547 36.5409 5.94547C35.0947 5.94547 34.0371 6.67937 33.6054 7.26217V0.786621H30.346V16.9755H33.6054V15.8962C34.0371 16.479 35.0947 17.2129 36.5409 17.2129ZM35.807 14.2558C34.3392 14.2558 33.3463 13.1333 33.3463 11.5792C33.3463 10.0251 34.3392 8.90264 35.807 8.90264C37.2748 8.90264 38.2677 10.0251 38.2677 11.5792C38.2677 13.1333 37.2748 14.2558 35.807 14.2558ZM46.6568 16.9755V0.786621H43.3974V16.9755H46.6568ZM53.5133 17.2129C54.9595 17.2129 56.0171 16.479 56.4488 15.8962V16.9755H59.7082V6.18291H56.4488V7.26217C56.0171 6.67937 54.9595 5.94547 53.5133 5.94547C50.6856 5.94547 48.5055 8.5141 48.5055 11.5792C48.5055 14.6443 50.6856 17.2129 53.5133 17.2129ZM54.2472 14.2558C52.7794 14.2558 51.7865 13.1333 51.7865 11.5792C51.7865 10.0251 52.7794 8.90264 54.2472 8.90264C55.715 8.90264 56.7079 10.0251 56.7079 11.5792C56.7079 13.1333 55.715 14.2558 54.2472 14.2558Z"
            fill="currentColor"
          />
        </svg>
      </div>
      <div className="text-20 mt-36">{children}</div>
    </div>
  </div>
);
