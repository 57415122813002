import { ButtonHTMLAttributes, ReactElement } from "react";
import classNames from "classnames";

import { useSubmit } from "hooks/useSubmit";

export const LinkSubmit = ({
  label,
  disabled = false,
  requiresValid = false,
  className,
  ...rest
}: {
  label: string | ReactElement;
  disabled?: boolean;
  requiresValid?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { submitProps } = useSubmit({ disabled, requiresValid });

  return (
    <button
      className={classNames("button-link", className)}
      {...rest}
      {...submitProps}
    >
      {label}
    </button>
  );
};
