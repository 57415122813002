import { range, rangeWithStep } from "utils";

export type Time = { hours: number; minutes: number };

export const getTimePossibilities = (
  startHour = 8,
  endHour = 20,
  minutesStep = 15,
) =>
  range(startHour, endHour).flatMap((h) =>
    rangeWithStep(0, 60, minutesStep).map(
      (m): Time => ({ hours: h, minutes: m }),
    ),
  );

export const alignDateToTimePossibilities = (
  date: ISOString,
  timePossibilities: Time[],
) => {
  const hourMinutePossibilities = timePossibilities.map((time) => [
    time.hours,
    time.minutes,
  ]);

  const targetHours = date.getDate().getHours();
  const targetMinutes = date.getDate().getMinutes();

  const [alignedHours, alignedMinutes] =
    hourMinutePossibilities.find(
      ([hours, minutes]) =>
        hours > targetHours ||
        (hours === targetHours && minutes >= targetMinutes),
    ) ?? hourMinutePossibilities.at(-1)!;

  return date
    .setHours(alignedHours)
    .setMinutes(alignedMinutes)
    .setMilliseconds(0);
};

export const isStartBeforeEnd = (start: Time, end: Time) => {
  if (start.hours !== end.hours) return start.hours < end.hours;
  return start.minutes < end.minutes;
};

export const addMinutes = (time: Time, minutes: number): Time => {
  const addedHours = Math.floor((time.minutes + minutes) / 60);
  const newHours = (time.hours + addedHours).modulo(24);
  const newMinutes = (time.minutes + minutes).modulo(60);
  return {
    hours: newHours,
    minutes: newMinutes,
  };
};

export type FormatType = "MM/dd/yyyy" | "dd/MM/yyyy";

export const isValidDate = (date: string, format: FormatType) =>
  format === "MM/dd/yyyy"
    ? date.match(/^(0[1-9]|1[0-2])\/(0[1-9]|[1-2]\d|3[0-1])\/(19|20)\d\d$/u) !==
      null
    : date.match(/^(0[1-9]|[1-2]\d|3[0-1])\/(0[1-9]|1[0-2])\/(19|20)\d\d$/u) !==
      null;
