import { useEffect } from "react";

import { run } from "utils";
import { baseApiUrl, isBehindIap } from "utils/environment";
import { makeUrl } from "utils/url";

// Forces users to log in to IAP (https://cloud.google.com/iap) if needed by
// redirecting them to a page served on the backend's domain.
export const IapRedirect = () => {
  useEffect(() => {
    if (!isBehindIap) return;

    void run(async () => {
      let shouldRedirect: boolean;
      try {
        const response = await fetch(`${baseApiUrl(undefined)}/health`, {
          method: "GET",
          credentials: "include",
        });
        shouldRedirect = response.status !== 200;
      } catch {
        shouldRedirect = true;
      }

      if (shouldRedirect) {
        window.location.href = makeUrl(
          `${baseApiUrl(undefined)}/app-redirect`,
          {
            return_to_full_url: window.location.href,
          },
        );
      }
    });
  }, []);

  return null;
};
