import { useState } from "react";

import { ClickableIcon } from "components/Icon/ClickableIcon";

export const Secret = ({ secret }: { secret: string }) => {
  const [hidden, setHidden] = useState(true);
  const text = hidden ? `${secret.slice(0, 8)}...` : secret;
  return (
    <div className="flex items-center">
      <ClickableIcon
        name={hidden ? "eye" : "eyeSlash"}
        onClick={() => setHidden(!hidden)}
      />
      <p className="whitespace-nowrap w-330 overflow-x-auto">{text}</p>
    </div>
  );
};
