import { Operation } from "@apollo/client";
import axios from "axios";
import { print } from "graphql";
import { z } from "zod";

import { SchemaType } from "base-types";

import { queryEndpointCancellable } from "./api-client";
import { GqlSchemaAuthenticationKind, GRAPHQL_ENDPOINTS } from "./endpoints";
import { EndpointDefinition, RequestContext } from "./types";

/**
 * Queries a GraphQL schema and returns the validated response.
 */
export const queryGqlSchema = <T extends SchemaType>(
  schemaType: T,
  operation: Operation,
  requestContext: RequestContext<GqlSchemaAuthenticationKind<T>>,
  clientName?: string,
) => {
  const definition = getEndpointDefinition(schemaType);
  const cancelTokenSource = axios.CancelToken.source();
  return {
    promise: queryEndpointCancellable(
      definition,
      {
        clientName,
        params: {
          op: operation.operationName,
        },
        data: {
          operationName: operation.operationName,
          query: print(operation.query),
          variables: operation.variables,
        },
        requestContext,
      },
      cancelTokenSource.token,
    ),
    cancel: cancelTokenSource.cancel,
  };
};

// Builds an endpoint definition for a GraphQL schema.
// We do this dynamically to be able to decide whether to use the regular path
// or the slow path depending on the operation.
const getEndpointDefinition = <T extends SchemaType>(schemaType: T) => {
  const definition = GRAPHQL_ENDPOINTS[schemaType];
  return {
    ...definition,
    path: definition.path,
    requestParamsSchema: z.object({
      op: z.string(),
    }),
    requestDataSchema: z.object({
      operationName: z.string(),
      query: z.string(),
      variables: z.record(z.string(), z.unknown()),
    }),
    responseSchema: z.object({
      data: z.record(z.string(), z.any()).nullish(),
      errors: z.array(z.any()).optional(),
      extensions: z.record(z.string(), z.any()).optional(),
    }),
  } satisfies EndpointDefinition<GqlSchemaAuthenticationKind<T>>;
};
