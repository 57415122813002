import { FieldValidator } from "formik/dist/types";

import { useField } from "hooks/useField";

import { useFormExtraContext } from "../Form/FormExtraContext";
import { Input, InputProps } from "./Input";

export type FormInputProps = Omit<InputProps, "value"> & {
  validate?: FieldValidator;
};

export const FormInput = ({
  name,
  disabled,
  onBlur,
  onChange,
  validate,
  ...props
}: FormInputProps) => {
  const [
    { onBlur: formikOnBlur, onChange: formikOnChange, ...otherFieldProps },
    { error },
  ] = useField({ name, disabled, validate });
  const isRequired = useFormExtraContext().requiredKeys.includes(name);

  return (
    <Input
      error={error}
      onBlur={(e) => {
        onBlur?.(e);
        formikOnBlur(e);
      }}
      onChange={(e) => {
        onChange?.(e);
        formikOnChange(e);
      }}
      required={props.required ?? isRequired}
      {...otherFieldProps}
      {...props}
    />
  );
};
