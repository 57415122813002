import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { trackEvent } from "tracking";

export const usePageViews = () => {
  const location = useLocation();
  useEffect(() => {
    trackEvent({
      name: "Page View",
      properties: { pathname: location.pathname },
    });
  }, [location.pathname]);
};
