import { changeLanguage, staticT as t } from "i18n";

const defaultLanguage = navigator.language.startsWith("fr") ? "fr" : "en";

export let language = defaultLanguage;

export const setLanguage = (value: string) => {
  language = value;
  document.documentElement.lang = t("utils.intl.en");
  changeLanguage(value);
};

export const resetLanguage = () => setLanguage(defaultLanguage);

export const intl = <T>(en: T, fr: T) => {
  switch (language) {
    case "fr":
      return fr;
    case "en":
    default:
      return en;
  }
};
export const optionalIntl = <T>(value: [T, T] | T) =>
  Array.isArray(value) ? intl(...value) : value;

export const getLocale = (value: string) => {
  switch (value) {
    case "fr":
      return "FRENCH";
    case "en":
    default:
      return "ENGLISH";
  }
};

export const getLanguageFromLocale = (value: string) => {
  switch (value) {
    case "FRENCH":
      return "fr";
    case "ENGLISH":
    default:
      return "en";
  }
};

export const getLanguageCode = (value: string) => {
  switch (value) {
    case "fr":
      return "fr-FR";
    case "en":
    default:
      return "en-US";
  }
};

// To init html lang attribute
setLanguage(defaultLanguage);
