import { useEffect, useState } from "react";

export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => clearTimeout(handler);
  }, [value, delay]);

  return debouncedValue;
};

export type SearchInputProps = {
  name: string;
  value: string;
  onChange: (e: { currentTarget: { value: string } }) => void;
};

export const useSearch = (): SearchInputProps => {
  const [search, setSearch] = useState("");

  return {
    name: "search",
    value: search,
    onChange: (e: { currentTarget: { value: string } }) =>
      setSearch(e.currentTarget.value),
  };
};

export const useDebounceSearch = (): {
  inputProps: SearchInputProps;
  search?: string;
} => {
  const inputProps = useSearch();
  const debouncedSearch = useDebounce(inputProps.value, 300);

  return {
    inputProps,
    search: debouncedSearch ? debouncedSearch : undefined,
  };
};
