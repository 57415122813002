import { ReactNode } from "react";
import classNames from "classnames";
import { Resizable } from "re-resizable";
import { useLocation, useSearchParams } from "react-router-dom";

import { Icon } from "components/Icon/Icon";
import { Link } from "components/Link/Link";
import { SidebarRoutes } from "components/Routes/SidebarRoutes";
import { useUser } from "contexts/User/UserContext";
import { useIsDesktop } from "hooks/useMediaQuery";
import {
  filterAvailableSidebarItems,
  resolveSidebarLabel,
  RouteProps,
  SidebarItem,
} from "types";
import { optionalIntl } from "utils/intl";
import { sectionTo } from "utils/routing";

export type SidebarPageProps<Item extends SidebarItem = SidebarItem> = {
  title: string | [string, string];
  topRight?: ReactNode;
  baseRoute: string;
  items: Item[];
  children?:
    | ReactNode
    | ((selectedItem: Item | undefined) => JSX.Element | null);
  mainClassName?: string;
  footer?: ReactNode;
  otherRoutes?: RouteProps[];
  hasSectionQueryParam?: boolean;
  withRedirect?: boolean;
};

export const SidebarPage = <Item extends SidebarItem>({
  title,
  topRight,
  baseRoute,
  items,
  children,
  mainClassName,
  footer,
  otherRoutes,
  hasSectionQueryParam,
  withRedirect,
}: SidebarPageProps<Item>) => {
  const userContext = useUser();
  const isDesktop = useIsDesktop();
  const { pathname } = useLocation();
  const [params] = useSearchParams();

  const availableItems = filterAvailableSidebarItems(items, userContext);

  const selected = hasSectionQueryParam
    ? availableItems.find((i) => params.get("section") === i.to) ??
      availableItems[0]
    : pathname === baseRoute
    ? undefined
    : availableItems.find((i) => pathname.startsWith(`${baseRoute}/${i.to}`));

  const mobileShowSidebar =
    pathname === baseRoute ||
    (selected?.mobileStickOnSidebar &&
      pathname === `${baseRoute}/${selected.to}`);

  return (
    <div
      className={classNames("flex-fill overflow-hidden flex", {
        "sm:ml-[-100vw]": !mobileShowSidebar,
      })}
    >
      {isDesktop ? (
        <Resizable
          className="flex-col lg:border-r truncate"
          minWidth="10vw"
          maxWidth="50vw"
          enable={{
            top: false,
            bottom: false,
            bottomLeft: false,
            bottomRight: false,
            left: false,
            right: isDesktop,
            topLeft: false,
            topRight: false,
          }}
          // FIXME(nathanael): Find a way to use --sidebar-width instead of hardcoding.
          defaultSize={{ height: "100%", width: 320 }}
        >
          <SideBarPageContent
            title={title}
            baseRoute={baseRoute}
            topRight={topRight}
            availableItems={availableItems}
            selected={selected}
            children={children}
            mainClassName={mainClassName}
            footer={footer}
            hasSectionQueryParam={hasSectionQueryParam}
          />
        </Resizable>
      ) : (
        <div
          className="flex-col lg:border-r"
          style={{ width: "var(--sidebar-width)" }}
        >
          <SideBarPageContent
            title={title}
            baseRoute={baseRoute}
            availableItems={availableItems}
            selected={selected}
            topRight={topRight}
            children={children}
            mainClassName={mainClassName}
            footer={footer}
            hasSectionQueryParam={hasSectionQueryParam}
          />
        </div>
      )}
      <SidebarRoutes
        items={hasSectionQueryParam ? [] : availableItems}
        otherRoutes={otherRoutes}
        withRedirect={withRedirect ?? false}
      />
    </div>
  );
};

const SideBarPageContent = <Item extends SidebarItem>({
  title,
  topRight,
  children,
  mainClassName,
  footer,
  availableItems,
  selected,
  hasSectionQueryParam,
}: Omit<SidebarPageProps<Item>, "items"> & {
  availableItems: Item[];
  selected: Item | undefined;
  hasSectionQueryParam: boolean | undefined;
}) => {
  const isDesktop = useIsDesktop();

  const pillsDisplay =
    !isDesktop &&
    availableItems.isNotEmpty() &&
    availableItems.every((i) => i.mobileStickOnSidebar);

  return (
    <>
      <div
        className={classNames(
          "px-16 flex items-center",
          pillsDisplay ? "py-10" : "py-16",
        )}
      >
        <div className="flex-fill text-primary-dark font-bold text-18 truncate">
          {optionalIntl(title)}
        </div>
        {topRight}
      </div>
      <div
        className={classNames(
          "flex-col px-8",
          pillsDisplay ? "flex-wrap" : "flex-col",
        )}
      >
        {availableItems.map((props) => (
          <Item
            key={props.to}
            selected={props.to === selected?.to}
            pillsDisplay={pillsDisplay}
            hasSectionQueryParam={hasSectionQueryParam}
            {...props}
          />
        ))}
      </div>
      <div className={classNames("flex-fill flex-col", mainClassName)}>
        {typeof children === "function" ? children(selected) : children}
      </div>
      {footer}
    </>
  );
};

const Item = ({
  to,
  icon,
  label,
  useCount,
  selected,
  pillsDisplay,
  hasSectionQueryParam,
  hidden,
}: SidebarItem & {
  selected: boolean;
  pillsDisplay: boolean;
  hasSectionQueryParam: boolean | undefined;
}) => {
  const accessProps = useUser();
  const resolvedLabel = resolveSidebarLabel(label, accessProps);
  const count = useCount?.();

  if (hidden) return null;
  return (
    <Link
      to={hasSectionQueryParam ? sectionTo(to) : to}
      className={classNames(
        "rounded px-8 py-6 flex items-center text-14",
        selected
          ? "bg-primary-100 text-primary font-medium"
          : "list-element border-l-2",
      )}
      style={{ paddingTop: "7px", paddingBottom: "5px" }}
    >
      {icon && (
        <Icon
          name={icon}
          size={20}
          className={classNames("mr-8 mb-2", {
            "ml-2": selected,
          })}
        />
      )}
      <div>
        {resolvedLabel}
        {pillsDisplay ? ` (${count === undefined ? "-" : count})` : ""}
      </div>
      {count !== undefined && !pillsDisplay && (
        // This intermediate div allow e2e test to make the difference between count loading and 0
        <div className="ml-auto">
          {count > 0 && <div className="text-14">{count}</div>}
        </div>
      )}
    </Link>
  );
};
