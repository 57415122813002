// organize-imports-ignore TS wrongly thinks FormStateType is not used :/
import { FormState as FormStateType, useFormState } from "hooks/useFormState";

export const FormState = <
  FormValues extends Record<string, unknown> = { genericRequired: true },
  SubmitContext extends Record<string, unknown> = { genericRequired: true },
>({
  children,
}: {
  children: (
    props: FormStateType<FormValues, SubmitContext>,
  ) => JSX.Element | null;
}) => children(useFormState<FormValues, SubmitContext>());
