import { useEffect, useState } from "react";
import classNames from "classnames";
import DayPicker, { DayPickerProps } from "react-day-picker";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { Popover } from "components/Popover/Popover";
import { Spinner } from "components/Spinner/Spinner";
import { useTargetState } from "hooks";
import { useSyncRef } from "hooks/useSyncRef";
import { useTranslation } from "i18n";
import { now } from "utils/date";

import { LabelWrapper, UnnamedLabelWrapperProps } from "../Label/LabelWrapper";
import styles from "./datePicker.module.css";

// AFAIK there was no way to get the defaults from
// DayPicker
const dayPickerClassNames: DayPickerProps["classNames"] = {
  container: styles.dayPicker,
  wrapper: "",
  interactionDisabled: "hidden",
  months: "",
  month: styles.month,
  navBar: "hidden",
  navButtonPrev: "hidden",
  navButtonNext: "hidden",
  navButtonInteractionDisabled: "hidden",
  caption: "hidden",
  weekdays: styles.weekdays,
  weekdaysRow: styles.weekdaysRow,
  weekday: styles.weekday,
  body: styles.body,
  week: styles.week,
  weekNumber: "hidden",
  day: styles.day,
  footer: "hidden",
  todayButton: "hidden",
  today: styles.today,
  selected: "",
  disabled: "",
  outside: "invisible border-none",
};

export type DatePickerProps<IsClearable extends boolean> =
  UnnamedLabelWrapperProps & {
    value: ISOString | undefined;
    onChange: (
      value: IsClearable extends true ? ISOString | undefined : ISOString,
    ) => void;
    disabled?: boolean;
    // possibilities
    possibilities?: ISOString[];
    loading?: boolean;
    onDisplayedTimeRangeChange?: (from: ISOString, to: ISOString) => void;
    // customization
    onlyPastDate?: boolean;
    initialSelectedMonth?: ISOString;
    pill?: boolean;
    placeholder?: string;
    withYearControls?: boolean;
    isClearable?: IsClearable;
  };

export const DatePicker = <IsClearable extends boolean>({
  wrapperClassName,
  label,
  error,
  hint,
  value,

  onChange,
  onlyPastDate,
  initialSelectedMonth,
  pill,
  disabled,
  withYearControls,
  possibilities,
  loading,
  onDisplayedTimeRangeChange,
  placeholder,
  isClearable,
}: DatePickerProps<IsClearable>) => {
  const t = useTranslation();
  const [calendarTarget, setCalendarTarget] = useTargetState();

  const [selectedMonth, setSelectedMonth] = useState(
    value ?? initialSelectedMonth ?? now(),
  );

  const onDisplayedTimeRangeChangeRef = useSyncRef(onDisplayedTimeRangeChange);

  useEffect(() => {
    onDisplayedTimeRangeChangeRef.current?.(
      selectedMonth.startOfMonth(),
      selectedMonth.startOfMonth().plusMonths(1),
    );
  }, [onDisplayedTimeRangeChangeRef, selectedMonth]);

  const isSelected = (date: ISOString) =>
    value ? value.isSameDay(date) : false;

  const selectable = (date: ISOString) => {
    if (isSelected(date)) return false;
    if (onlyPastDate) return date.isPast();
    if (loading) return false;
    if (!possibilities) return true;
    return possibilities.some((d) => d.isSameDay(date));
  };

  return (
    <LabelWrapper
      wrapperClassName={wrapperClassName}
      label={label}
      error={error}
      hint={hint}
      useDiv
    >
      <button
        type="button"
        onClick={setCalendarTarget}
        disabled={disabled}
        className={classNames(
          pill
            ? [
                "flex-row-reverse rounded-full px-12 py-8 text-12",
                calendarTarget ? "bg-primary text-white" : "bg-grey-100",
              ]
            : "h-40 border rounded px-8",
          "flex items-center",
          { "bg-grey-100": disabled },
        )}
      >
        <span
          className={
            pill
              ? calendarTarget
                ? "text-white"
                : undefined
              : value
              ? "text-primary-dark"
              : "text-placeholder"
          }
        >
          {value
            ? value.format("date")
            : placeholder ?? t("form.date_picker.date_picker.pick")}
        </span>
        {value && isClearable && (
          <Icon
            name="close"
            size={12}
            className="ml-12 hover:bg-grey-100 rounded"
            onClick={(e) => {
              onChange(undefined!);
              e.stopPropagation();
            }}
          />
        )}
        <Icon name="calendar" className={pill ? "mr-8" : "ml-auto"} />
      </button>
      {calendarTarget && (
        <Popover
          target={calendarTarget}
          onClose={() => setCalendarTarget(undefined)}
          className="my-10 p-24"
          style={{ width: 300 }}
          position={["bottom", "bottom-left", "top-left"]}
        >
          <div className="flex mb-20 items-center">
            <span className="mr-auto flex items-center text-primary-dark font-medium">
              {selectedMonth.format("monthAndYear").upperFirst()}
              {loading && <Spinner inline small className="ml-12" />}
            </span>
            {withYearControls && (
              <ClickableIcon
                className="mr-10 py-4"
                name="doubleChevron"
                size={14}
                rotate={180}
                onClick={() => setSelectedMonth(selectedMonth.minusYears(1))}
              />
            )}
            <ClickableIcon
              id="prevMonth"
              className="mr-10 py-4"
              name="chevron"
              size={14}
              rotate={180}
              onClick={() => setSelectedMonth(selectedMonth.minusMonths(1))}
            />
            <ClickableIcon
              id="nextMonth"
              className="py-4"
              name="chevron"
              size={14}
              onClick={() => setSelectedMonth(selectedMonth.plusMonths(1))}
            />
            {withYearControls && (
              <ClickableIcon
                className="ml-10 py-4"
                name="doubleChevron"
                size={14}
                onClick={() => setSelectedMonth(selectedMonth.plusYears(1))}
              />
            )}
          </div>
          <DayPicker
            modifiers={{
              "cursor-pointer": (date) => selectable(date.toISOString()),
              "bg-grey-100": onlyPastDate
                ? { after: new Date() }
                : { before: new Date() },
            }}
            className={styles.dayPicker}
            month={selectedMonth.getDate()}
            firstDayOfWeek={1}
            weekdaysShort={[
              t("utils.date.week_day_short.sunday"),
              t("utils.date.week_day_short.monday"),
              t("utils.date.week_day_short.tuesday"),
              t("utils.date.week_day_short.wednesday"),
              t("utils.date.week_day_short.thursday"),
              t("utils.date.week_day_short.friday"),
              t("utils.date.week_day_short.saturday"),
            ]}
            selectedDays={value ? [value.getDate()] : undefined}
            onDayClick={(day) => {
              if (!selectable(day.toISOString())) return;
              const date = day.toISOString();
              onChange(date);
              setCalendarTarget(undefined);
            }}
            classNames={dayPickerClassNames}
            renderDay={(date) => (
              <div
                className={classNames(
                  "mx-auto w-24 h-24 rounded-full flex-center",
                  {
                    "bg-primary-100 text-primary":
                      possibilities && selectable(date.toISOString()),
                    "bg-primary text-white": isSelected(date.toISOString()),
                  },
                )}
              >
                {date.toISOString().format("monthDay")}
              </div>
            )}
          />
          {possibilities?.isEmpty() && (
            <div className="mt-12 px-12">
              {t("date_picker.sorry_there_is_no_date_available_this_month_")}
            </div>
          )}
        </Popover>
      )}
    </LabelWrapper>
  );
};
