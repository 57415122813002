import { OperationDefinitionNode } from "graphql/language/ast";

import { Mutation, Query, SchemaType } from "base-types";
import { KnownUnionValue, possibleTypes, PossibleTypes } from "types/apollo";
import { Nullable } from "types/utils";

export const isKnownUnionValue = <
  T extends keyof PossibleTypes,
  U extends Nullable<{ __typename: PossibleTypes[T] | "FutureValue" }>,
>(
  unionName: T,
  object: U,
): object is KnownUnionValue<U> =>
  object
    ? (possibleTypes[unionName] as string[]).includes(object.__typename)
    : false;

export const getKnownUnionValue = <
  Union extends keyof PossibleTypes,
  Element extends Nullable<{
    __typename: PossibleTypes[Union] | "FutureValue";
  }>,
>(
  unionName: Union,
  o: Element,
): KnownUnionValue<Element> | null =>
  isKnownUnionValue(unionName, o) ? o : null;

export const knownUnionValueFilter =
  <
    Union extends keyof PossibleTypes,
    Element extends Nullable<{
      __typename: PossibleTypes[Union] | "FutureValue";
    }>,
  >(
    unionName: Union,
  ) =>
  (o: Element): o is KnownUnionValue<Element> =>
    isKnownUnionValue(unionName, o);

export const getOperationName = (
  queryOrMutation:
    | Query<unknown, unknown, unknown, SchemaType>
    | Mutation<unknown, unknown, SchemaType>,
) =>
  queryOrMutation.document.definitions.find(
    (definition): definition is OperationDefinitionNode =>
      definition.kind === "OperationDefinition",
  )?.name?.value;
