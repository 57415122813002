import classNames from "classnames";

import { Button, FormButtonProps } from "components/Button/Button";
import { useWaitPromise } from "hooks/useWaitPromise";
import { MaybePromise } from "types";

import { Modal, ModalProps } from "./Modal";

export type ControlledConfirmationModalProps = ModalProps & {
  cta: Omit<FormButtonProps, "loading" | "className" | "onClick">;
  onConfirm: (close: () => void) => MaybePromise;
  bodyClassName?: string;
};

export const ControlledConfirmationModal = ({
  children,
  cta,
  onHide,
  onConfirm,
  bodyClassName,
  ...modalProps
}: ControlledConfirmationModalProps) => {
  const [waitPromise, loading] = useWaitPromise();

  return (
    <Modal onHide={onHide} {...modalProps}>
      <div
        className={classNames(
          "w-full text-center whitespace-pre-wrap",
          bodyClassName,
        )}
      >
        {children}
      </div>
      <Button
        {...cta}
        loading={loading}
        className="mt-24"
        onClick={() => waitPromise(onConfirm(onHide))}
      />
    </Modal>
  );
};
