import { useState } from "react";
import gql from "graphql-tag";

import { Submit } from "components/Button/Submit";
import { CopiableText } from "components/CopiableText/CopiableText";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { Modal } from "components/Modal/Modal";
import {
  CreateServerKey,
  ServerKeyFragment,
  UpdateServerKey,
} from "generated/organizationuser";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  # schema = ORGANIZATION_USER
  mutation CreateServerKey($serverKeyInput: CreateServerKeyInput!) {
    createServerKey(serverKeyInput: $serverKeyInput) {
      serverKey {
        ...ServerKey
      }
      bearerToken
    }
  }

  mutation UpdateServerKey(
    $uuid: UUID!
    $serverKeyInput: UpdateServerKeyInput!
  ) {
    updateServerKey(uuid: $uuid, serverKeyInput: $serverKeyInput) {
      serverKey {
        ...ServerKey
      }
    }
  }
`;

type FormValues = {
  name: string;
  description: string;
};

export const ServerKeyComposer = ({
  serverKey,
  showComposer,
  onClose,
}: {
  serverKey?: ServerKeyFragment;
  showComposer: boolean;
  onClose: () => void;
}) => {
  const t = useTranslation();
  const [serverKeyToken, setServerKeyToken] = useState<string | undefined>(
    undefined,
  );
  const [createServerKey] = useMutation(CreateServerKey, {
    refetchQueries: ["AllServerKeys"],
    onSuccess: (data) => {
      notifier.success(
        t("developers.server_keys.server_key_composer.server_api_key_created"),
      );
      setServerKeyToken(data.bearerToken);
    },
  });
  const [updateServerKey] = useMutation(UpdateServerKey, {
    onSuccess: () => {
      notifier.success(
        t("developers.server_keys.server_key_composer.server_api_key_updated"),
      );
      onClose();
    },
  });

  return (
    <Modal
      title={
        serverKeyToken
          ? t("developers.server_keys.server_key_composer.server_api_key_token")
          : serverKey
          ? t(
              "developers.server_keys.server_key_composer.edit_the_server_api_key",
            )
          : t(
              "developers.server_keys.server_key_composer.add_a_new_server_api_key",
            )
      }
      onHide={() => {
        setServerKeyToken(undefined);
        onClose();
      }}
      show={showComposer}
    >
      {serverKeyToken !== undefined && (
        <div className="w-full">
          <p className="my-24">{t("server_key_composer.token_information")}</p>
          <CopiableText text={serverKeyToken} />
        </div>
      )}
      {!serverKeyToken && (
        <Form<FormValues>
          className="flex-col w-full mt-24 space-y-24"
          initialValues={{
            name: serverKey?.name ?? "",
            description: serverKey?.description ?? "",
          }}
          onSubmit={({ name, description }) => {
            const serverKeyInput = {
              name,
              description,
            };
            return serverKey
              ? updateServerKey({ uuid: serverKey.uuid, serverKeyInput })
              : createServerKey({ serverKeyInput });
          }}
        >
          <FormInput
            name="name"
            label={t("developers.server_keys.server_key_composer.name")}
            placeholder={t("developers.server_keys.server_key_composer.name")}
            wrapperClassName="flex-fill"
          />
          <FormTextArea
            name="description"
            label={t("developers.server_keys.server_key_composer.description")}
            placeholder={t(
              "developers.server_keys.server_key_composer.description",
            )}
            wrapperClassName="flex-fill"
          />
          <Submit
            className="mt-36"
            label={
              serverKey
                ? t("developers.server_keys.server_key_composer.save")
                : t("developers.server_keys.server_key_composer.create")
            }
          />
        </Form>
      )}
    </Modal>
  );
};
