import { CSSProperties } from "react";
import classNames from "classnames";

import styles from "./tooltip.module.css";

export const Tooltip = ({
  className,
  position,
  style,
  label,
}: {
  position?: "top" | "bottom" | "left" | "right";
  label: string | JSX.Element;
  className?: string;
  style?: CSSProperties;
}) => (
  <div
    className={classNames(styles.tooltip, className, {
      [styles.top]: position === "top",
      [styles.bottom]: position === "bottom",
      [styles.left]: position === "left",
      [styles.right]: position === "right",
      "whitespace-normal text-center": style?.width,
    })}
    style={style}
  >
    {label}
  </div>
);
