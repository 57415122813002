import { Icon } from "components/Icon/Icon";
import { useTranslation } from "i18n";
import { copyToClipBoard } from "utils";

export const CopiableText = ({
  title,
  text,
}: {
  title?: string;
  text: string;
}) => {
  const t = useTranslation();

  return (
    <div className="flex justify-between items-start rounded border bg-grey-100 p-12 my-12">
      <div className="flex-shrink overflow-hidden">
        {title && <div className="text-12">{title}</div>}
        <div className="text-primary-dark break-all">{text}</div>
      </div>
      <button
        className="flex-center rounded border bg-white text-primary px-12 py-4"
        style={{ marginTop: "-4px", marginLeft: "8px", marginRight: "-4px" }}
        type="button"
        onClick={() =>
          copyToClipBoard(text, t("copiable_text.copiable_text.copied"))
        }
      >
        {t("copiable_text.copiable_text.copy")}
        <Icon className="ml-10" name="copy" />
      </button>
    </div>
  );
};
