import classNames from "classnames";
import gql from "graphql-tag";

import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { FormSelect } from "components/Form/Select/FormSelect";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Modal } from "components/Modal/Modal";
import { SendFakeWebhookEvent } from "generated/organizationuser";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

import { AppFlavor } from "../platform";
import { webhookEventTypes } from "../WebhookEvents/types";

gql`
  # schema = ORGANIZATION_USER
  mutation SendFakeWebhookEvent(
    $webhookUuid: UUID!
    $eventType: String!
    $eventDataJson: String!
  ) {
    sendFakeWebhookEvent(
      webhookUuid: $webhookUuid
      eventType: $eventType
      eventDataJson: $eventDataJson
    ) {
      webhookCall {
        ...WebhookCall
      }
    }
  }
`;

type FormValues = {
  eventDataJson: string;
  eventType: string;
};

export const PayloadTestModal = ({
  onHide,
  webhookUuid,
  appFlavor,
}: {
  onHide: () => void;
  webhookUuid: UUID;
  appFlavor: AppFlavor;
}) => {
  const t = useTranslation();
  const [sendFakeWebhookEvent] = useMutation(SendFakeWebhookEvent, {
    onSuccess: () => {
      notifier.success(
        t("developers.webhooks.payload_test_modal.payload_sent"),
      );
      onHide();
    },
  });

  return (
    <Modal onHide={onHide}>
      <Form<FormValues>
        className="w-full text-center whitespace-pre-wrap space-y-8"
        initialValues={{ eventType: "", eventDataJson: "" }}
        onSubmit={({ eventDataJson, eventType }) =>
          sendFakeWebhookEvent({ webhookUuid, eventDataJson, eventType })
        }
      >
        <FormSelect
          label={t("developers.webhooks.payload_test_modal.event_type")}
          name="eventType"
          options={webhookEventTypes(appFlavor)}
        />

        <FormTextArea label="JSON" name="eventDataJson" />
        <FormState<FormValues>>
          {({ values }) => (
            <ClickableIcon
              name="send"
              type="submit"
              className={classNames(
                "hover:bg-grey-100 rounded absolute inset-br-8",
                { "text-primary": values.eventType.isNotBlank() },
              )}
              disabled={values.eventType.isBlank()}
            />
          )}
        </FormState>
      </Form>
    </Modal>
  );
};
