import { useCallback } from "react";
import { Observable, RequestHandler } from "@apollo/client";

import { ApiError } from "api/api-client";
import { queryGqlSchema } from "api/graphql-client";
import { SchemaType } from "base-types";
import { useAppVersion } from "contexts/AppVersion/AppVersionContext";

import { useGetRequestContextOrThrow } from "./request-context-utils";

export const useSchemaHttpLink = <T extends SchemaType>(
  schemaType: T,
  clientName?: string,
): RequestHandler => {
  const { updateMinimumCodeVersionIfNeeded } = useAppVersion();
  const getRequestContextOrThrow = useGetRequestContextOrThrow(schemaType);

  return useCallback(
    (operation) =>
      new Observable((observer) => {
        let requestContext;
        try {
          requestContext = getRequestContextOrThrow(operation);
        } catch (e) {
          observer.error(e);
          return;
        }

        const { promise, cancel } = queryGqlSchema(
          schemaType,
          operation,
          requestContext,
          clientName,
        );

        promise
          .then(({ data, requestId, minimumCodeVersion }) => {
            if (minimumCodeVersion) {
              updateMinimumCodeVersionIfNeeded(minimumCodeVersion);
            }
            operation.setContext({ requestId });
            observer.next(data);
            observer.complete();
          })
          .catch((error: unknown) => {
            if (error instanceof ApiError) {
              if (error.isCanceled) return; // Don't propagate cancellation errors.
              operation.setContext({ requestId: error.requestId });
            }
            observer.error(error);
          });

        return cancel;
      }),
    [
      schemaType,
      clientName,
      updateMinimumCodeVersionIfNeeded,
      getRequestContextOrThrow,
    ],
  );
};
