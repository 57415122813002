import { Capacitor } from "@capacitor/core";
import * as Yup from "yup";

import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { useIsPWA } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";

export const MfaCodeForm = ({
  subtitle,
  onSubmit,
}: {
  subtitle?: string;
  onSubmit: (code: string) => Promise<"SUCCESS" | "ERROR">;
}) => {
  const t = useTranslation();
  const isMobile = useIsPWA() || Capacitor.isNativePlatform();

  return (
    <Form<{ code: string }>
      className="flex-col"
      initialValues={{ code: "" }}
      initialErrors={{ code: "" }}
      validationSchema={{
        code: Yup.string()
          .required(" ")
          .matches(/^[0-9]{6}$/u, " "),
      }}
      onSubmit={async ({ code }, { setErrors }) => {
        const status = await onSubmit(code);
        if (status === "ERROR") {
          setErrors({ code: t("verify_mfa_form.invalid_code") });
        }
      }}
    >
      <FormInput
        name="code"
        label={t("verify_mfa_form.code_input_label")}
        placeholder={t("verify_mfa_form.code_input_placeholder")}
        inputMode="numeric"
        autoFocus
        autoComplete={isMobile ? "one-time-code" : "off"}
        className="mb-10"
      />
      {subtitle && <p className="leading-normal">{subtitle}</p>}
      <Submit
        large
        label={t("verify_mfa_form.validate")}
        requiresValid
        className="mt-28"
      />
    </Form>
  );
};
