import { ReactElement, useEffect } from "react";

import {
  defaultHost,
  getConfig,
  ORGANIZATION_ID_SEARCH_PARAM,
} from "utils/environment";

// Automatically redirects legacy URLs (with the organization ID in the domain
// name) to new URLs (with the organization ID as a search parameter).
export const LegacyUrlRedirect = ({ children }: { children: ReactElement }) => {
  const organizationStringId = getOrganizationStringIdFromHostname();

  useEffect(() => {
    if (organizationStringId) {
      const newUrl = new URL(window.location.href);
      newUrl.host = defaultHost;

      // Don't override existing organization ID query parameters.
      if (!newUrl.searchParams.has(ORGANIZATION_ID_SEARCH_PARAM)) {
        newUrl.searchParams.set(
          ORGANIZATION_ID_SEARCH_PARAM,
          organizationStringId,
        );
      }

      window.location.replace(newUrl.toString());
    }
  }, [organizationStringId]);

  return organizationStringId ? null : children;
};

const getOrganizationStringIdFromHostname = () => {
  const organizationStringIdCanAppearInHostname = getConfig({
    dev: false,
    staging: true,
    pr: false,
    preprod: true,
    prod: true,
  });

  const host = window.location.host;
  return organizationStringIdCanAppearInHostname &&
    host.endsWith(defaultHost) &&
    host !== defaultHost
    ? host.split(".")[0]?.trimOrNull()
    : null;
};
