import { Route, Routes } from "react-router-dom";

import { Background } from "components/Background/Backgound";
import { NotFound } from "components/ErrorPage/NotFound";
import { useUser } from "contexts/User/UserContext";
import { filterAvailableSidebarItems, RouteProps, SidebarItem } from "types";

import { Redirect } from "./Redirect";

export const SidebarRoutes = ({
  items,
  otherRoutes,
  withRedirect,
}: {
  items: SidebarItem[];
  otherRoutes?: RouteProps[];
  withRedirect: boolean;
}) => {
  const userContext = useUser();
  const filteredItems = filterAvailableSidebarItems(items, userContext);
  const filteredOtherRoutes = otherRoutes
    ? filterAvailableSidebarItems(otherRoutes, userContext)
    : [];

  return (
    <Routes>
      {filteredOtherRoutes.map(({ to, Component }) => (
        <Route key={to} path={`${to}*`} element={<Component />} />
      ))}
      {filteredItems.map(({ to, Component }) => (
        <Route key={to} path={`${to}*`} element={<Component />} />
      ))}
      <Route
        path=""
        element={
          filteredItems.isEmpty() ? (
            <Background className="flex-fill" />
          ) : withRedirect ? (
            <Redirect to={filteredItems[0].to} />
          ) : null
        }
      />
      <Route
        path="*"
        element={
          <div className="flex-fill flex-center">
            <NotFound />
          </div>
        }
      />
    </Routes>
  );
};
