import classNames from "classnames";

import { envColor, envText } from "utils/environment";

export const EnvironmentBanner = () =>
  envText ? (
    <div
      className={classNames(
        "h-18 w-80 text-10 text-white text-center absolute font-medium border border-black ",
        envColor,
      )}
      style={{ top: 12, left: -19, transform: "rotate(-45deg)" }}
    >
      {envText}
    </div>
  ) : null;
