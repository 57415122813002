import { useCallback } from "react";

import { useSyncRefNoDelay } from "./useSyncRef";

/**
 * Returns a memoized version of `callback` whose identity never changes,
 * even when the dependencies of the callback change.
 */
export const useStableCallback = <T extends any[], U>(
  callback: (...args: T) => U,
): ((...args: T) => U) => {
  const callbackRef = useSyncRefNoDelay(callback);
  return useCallback(
    (...args: T) => callbackRef.current(...args),
    [callbackRef],
  );
};
