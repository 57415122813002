import { SVGProps } from "react";

import { iconLibrary, IconName } from "icon-library";

export type IconProps = Omit<SVGProps<SVGSVGElement>, "width" | "height"> & {
  name: IconName;
  rotate?: number;
  size?: number;
  title?: string;
};

export const Icon = ({
  name,
  size = 20,
  rotate,
  style,
  ...rest
}: IconProps) => {
  const SVG = iconLibrary[name];
  return (
    <SVG
      data-icon={name}
      height={size}
      width={size}
      style={{
        transform: rotate ? `rotate(${rotate}deg)` : undefined,
        ...style,
      }}
      {...rest}
    />
  );
};
