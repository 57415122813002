import { QUEUED_NOTIFICATIONS_COOKIE_KEY } from "consts";

import { getCookie, removeCookie, setCookie } from "./cookies";
import { defaultDomain } from "./environment";

export type QueuedNotification = {
  type: "error" | "success" | "info";
  message: string;
};

/**
 * Similar to [notifier], but queues the notifications to be displayed the
 * next time a route changes or the page is hard-reloaded, across sub-domains.
 *
 * See [QueuedNotificationsListener].
 */
export const queuedNotifier = {
  success: (message: string) => queueNotification({ type: "success", message }),
  error: (message: string) => queueNotification({ type: "error", message }),
  info: (message: string) => queueNotification({ type: "info", message }),
};

const queueNotification = (notification: QueuedNotification) => {
  const allNotifications = consumeQueuedNotifications();
  allNotifications.push(notification);
  setCookie(QUEUED_NOTIFICATIONS_COOKIE_KEY, JSON.stringify(allNotifications), {
    path: "/",
    domain: defaultDomain,
  });
};

export const consumeQueuedNotifications = (): QueuedNotification[] => {
  const rawNotifications = getCookie(QUEUED_NOTIFICATIONS_COOKIE_KEY);
  const parsedNotifications = rawNotifications
    ? JSON.parse(rawNotifications)
    : [];
  removeCookie(QUEUED_NOTIFICATIONS_COOKIE_KEY, {
    path: "/",
    domain: defaultDomain,
  });
  return parsedNotifications;
};
