import { useMemo } from "react";

import { CodeInputMode } from "auth/views/AccountLoginLink";
import { Button } from "components/Button/Button";
import { TargetFrontendKnownValue } from "generated/unauth-account";
import { useTranslation } from "i18n";
import {
  copilotMobileAppDeeplinkUrl,
  copilotWebappUrl,
} from "utils/environment";

type Reason = "INVALID" | "ALREADY_USED";

export const InvalidLinkError = ({
  reason = "INVALID",
  codeInputMode = "LINK",
  targetFrontend,
}: {
  reason?: Reason;
  codeInputMode?: CodeInputMode;
  targetFrontend?: TargetFrontendKnownValue;
}) => {
  const t = useTranslation();
  const redirect =
    targetFrontend === "COPILOT_WEB_APP"
      ? { external: copilotWebappUrl }
      : targetFrontend === "COPILOT_MOBILE_APP"
      ? { external: copilotMobileAppDeeplinkUrl }
      : { to: "/" };
  const message = useMemo(() => {
    switch (reason) {
      case "INVALID":
        switch (codeInputMode) {
          case "MANUAL":
            return t("sign_in.invalid_code.title");
          case "LINK":
            return t("choose_credentials.invalid_link.title");
        }
      case "ALREADY_USED":
        switch (codeInputMode) {
          case "MANUAL":
            return t("sign_in.already_used_code.title");
          case "LINK":
            return t("sign_in.already_used_link.title");
        }
    }
  }, [reason, codeInputMode, t]);
  return (
    <div className="flex-col">
      <p className="text-16 mb-16 text-center">{message}</p>
      {targetFrontend !== "COPILOT_CHROME_EXTENSION" && (
        <Button
          secondary
          label={t("error_page.error_page.back_to_home")}
          {...redirect}
        />
      )}
    </div>
  );
};
