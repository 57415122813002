// organize-imports-ignore
// The order matters here. We want Sentry.init to be the first code that runs
// to capture any bad app initialisation.
// extensions comes next because they can be used anywhere in src.
import "sentry-init";
import "extensions";
import { createRoot } from "react-dom/client";

import { App } from "./App";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
