import { RefObject, useLayoutEffect, useRef } from "react";
import classNames from "classnames";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";

import styles from "./textArea.module.css";

export type TextAreaProps = TextareaAutosizeProps & {
  inputRef?: RefObject<HTMLTextAreaElement>;
};

export const TextArea = ({
  inputRef,
  className,
  minRows = 3,
  autoFocus,
  ...props
}: TextAreaProps) => {
  const defaultInputRef = useRef<HTMLTextAreaElement>(null);

  const usedInputRef = inputRef ?? defaultInputRef;
  useLayoutEffect(() => {
    if (!autoFocus) return;
    const length = usedInputRef.current!.value.length;
    usedInputRef.current!.setSelectionRange(length, length);
  }, [autoFocus, usedInputRef]);

  return (
    <TextareaAutosize
      ref={usedInputRef}
      className={classNames(styles.textArea, className)}
      autoFocus={autoFocus}
      minRows={minRows}
      {...props}
    />
  );
};
