// organize-imports-ignore For some reason BaseQuery gets deleted otherwise.
import { Query as BaseQuery, SchemaType } from "base-types";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import { Spinner } from "components/Spinner/Spinner";
import { QueryOptions, QueryUtils, useQuery } from "graphql-client/useQuery";

export type NoSpinnerOutput<Data> =
  | { loading: true; data: undefined }
  | { loading: false; data: Data };

export type QueryProps<
  Data,
  Variables,
  VariablesRequired,
  Schema extends SchemaType,
  NoSpinner extends boolean = false,
> = QueryOptions<Variables, VariablesRequired, Schema> & {
  query: BaseQuery<Data, Variables, VariablesRequired, Schema>;
  noSpinner?: NoSpinner;
  children: (
    output: NoSpinner extends true ? NoSpinnerOutput<Data> : Data,
    utils: QueryUtils<Data, Variables>,
  ) => JSX.Element | null;
};
export const Query = <
  Data,
  Variables,
  VariablesRequired,
  Schema extends SchemaType,
  NoSpinner extends boolean = false,
>(
  props: QueryProps<Data, Variables, VariablesRequired, Schema, NoSpinner>,
) => {
  const { query, noSpinner, children, ...options } = props;
  const { data, error, loading, ...utils } = useQuery<
    Data,
    Variables,
    VariablesRequired,
    Schema
  >(query, props);

  if (options.skip) return null;

  // TS can't narrow down NoSpinner
  const output = (o: NoSpinnerOutput<Data> | Data) =>
    children(
      // @ts-ignore
      o,
      utils,
    );

  if (noSpinner) {
    if (loading) return output({ loading, data: undefined });
    if (data) return output({ loading, data });
  } else {
    if (loading) return <Spinner />;
    if (data) return output(data);
  }

  if (error) return <ErrorPage error={error} />;

  if (options.cacheOnly) return null; // If cache only, an empty output is expected
  return <ErrorPage error={null} />; // Otherwise, something went wrong
};
