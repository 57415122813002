import {
  addDays,
  addHours,
  addMilliseconds,
  addMinutes,
  addMonths,
  addSeconds,
  addWeeks,
  addYears,
  differenceInCalendarDays,
  differenceInSeconds,
  setDate,
  setHours,
  setMilliseconds,
  setMinutes,
  setMonth,
  setSeconds,
  setYear,
} from "date-fns";
import { DateTime, Settings } from "luxon";

import { staticT as t } from "i18n";
import { now } from "utils/date";

// Custom date and time GraphQL scalars.
//
// The `DateTime` and `LocalDate` scalars are sent as strings over the wire.
// Since we want to be able to perform some operations on these scalars, we
// could either write custom `DateTime` and `LocalDate` classes as well as a
// code generation step to automatically transform the strings into instances
// of these classes; or we can instead declare these operations directly as
// extensions on `String` and let TypeScript handle type safety.

declare global {
  // A date represented as a string in ISO 8601 format (e.g. 2019-09-18).
  interface LocalDate extends String {
    asDateString(this: LocalDate): string;
    toDateTimeAtUtcMidnight(this: LocalDate): DateTime;
    toDateAtUtcMidnight(this: LocalDate): Date;
    toIsoStringAtUtcMidnight(this: LocalDate): ISOString;

    // Formats the date using the current locale's date format.
    formatDateInLocale(this: LocalDate): string;
  }

  // A date and time represented as a string in ISO 8601 format.
  interface ISOString extends String {
    getDate(this: ISOString): Date;
    getTime(this: ISOString): number;

    asString(this: ISOString): string;

    millisecondsFromNow(this: ISOString): number;
    secondsFromNow(this: ISOString): number;
    secondsFrom(this: ISOString, date: ISOString): number;

    alignToSeconds(this: ISOString): ISOString;

    plusMilliseconds(this: ISOString, milliseconds: number): ISOString;
    minusMilliseconds(this: ISOString, milliseconds: number): ISOString;
    plusSeconds(this: ISOString, seconds: number): ISOString;
    minusSeconds(this: ISOString, seconds: number): ISOString;
    plusMinutes(this: ISOString, minutes: number): ISOString;
    minusMinutes(this: ISOString, minutes: number): ISOString;
    plusHours(this: ISOString, hours: number): ISOString;
    minusHours(this: ISOString, hours: number): ISOString;
    plusDays(this: ISOString, days: number): ISOString;
    minusDays(this: ISOString, days: number): ISOString;
    plusWeeks(this: ISOString, weeks: number): ISOString;
    minusWeeks(this: ISOString, weeks: number): ISOString;
    plusMonths(this: ISOString, months: number): ISOString;
    minusMonths(this: ISOString, months: number): ISOString;
    plusYears(this: ISOString, years: number): ISOString;
    minusYears(this: ISOString, years: number): ISOString;

    startOfDay(this: ISOString): ISOString;
    endOfDay(this: ISOString): ISOString;
    startOfWeek(this: ISOString): ISOString;
    endOfWeek(this: ISOString): ISOString;
    startOfMonth(this: ISOString): ISOString;
    endOfMonth(this: ISOString): ISOString;
    setMilliseconds(this: ISOString, milliseconds: number): ISOString;
    setSeconds(this: ISOString, seconds: number): ISOString;
    setMinutes(this: ISOString, minutes: number): ISOString;
    setHours(this: ISOString, hours: number): ISOString;
    setDay(this: ISOString, day: number): ISOString;
    setMonth(this: ISOString, month: number): ISOString;
    setYear(this: ISOString, year: number): ISOString;

    isSameDay(this: ISOString, date: ISOString): boolean;
    isToday(this: ISOString): boolean;
    isTomorrow(this: ISOString): boolean;
    isYesterday(this: ISOString): boolean;
    isBefore(this: ISOString, date: ISOString): boolean;
    isBeforeOrEqual(this: ISOString, date: ISOString): boolean;
    isAfter(this: ISOString, date: ISOString): boolean;
    isAfterOrEqual(this: ISOString, date: ISOString): boolean;
    isPast(this: ISOString): boolean;
    isFuture(this: ISOString): boolean;
    isBeforeToday(this: ISOString): boolean;
    isAfterToday(this: ISOString): boolean;

    weekday(this: ISOString): number; // from 1 to 7

    format(
      this: ISOString,
      format:
        | "shortDate" // 25/01 (if same year) or 25/01/2029
        | "date" // 25/01/2021
        | "monthDay" // 25 (for calendars)
        | "time" // 22:46
        | "dateAndTime" // 25/01/2021 22:46
        | "monthAndYear" // janv. 2021
        | "file" // 2021-09-10-13h54
        | {
            relative: RelativeTimeFormat;
            withDatePrefix?: boolean; // Adds "le" before non-relative dates.
          }
        // For other exceptional cases (calendars, pickers, files)
        // See https://date-fns.org/v2.16.1/docs/format
        | { exception: string },
    ): string;
  }

  interface Date {
    toISOString(this: Date): ISOString;

    getHours(this: Date): number;
    getMinutes(this: Date): number;

    startOfDay(this: Date): Date;
    endOfDay(this: Date): Date;
    startOfWeek(this: Date): Date;
    endOfWeek(this: Date): Date;
    startOfMonth(this: Date): Date;
    endOfMonth(this: Date): Date;

    plusDays(this: Date, days: number): Date;
    minusDays(this: Date, days: number): Date;
  }
}

const defineLocalDateProperty = <
  // Property names must not overlap between `ISOString` and `LocalDate`.
  K extends Exclude<keyof LocalDate, keyof ISOString>,
>(
  key: K,
  value: LocalDate[K],
) => {
  Object.defineProperty(String.prototype, key, { value });
};

defineLocalDateProperty("asDateString", function () {
  return this as unknown as string;
});

defineLocalDateProperty("toDateTimeAtUtcMidnight", function () {
  return DateTime.fromISO(this.asDateString(), {
    zone: "utc",
    setZone: false,
  });
});

defineLocalDateProperty("toDateAtUtcMidnight", function () {
  return this.toDateTimeAtUtcMidnight().toJSDate();
});

defineLocalDateProperty("toIsoStringAtUtcMidnight", function () {
  return this.toDateAtUtcMidnight().toISOString();
});

defineLocalDateProperty("formatDateInLocale", function () {
  return this.toDateTimeAtUtcMidnight().toFormat(t("common.date_format"));
});

export type RelativeTimeFormat =
  | "timeOrDate" // il y a 10m, il y a 3h, {week if >1d}
  | "timeOrDateWithTime" // il y a 10m, il y a 3h, {weekWithTime if >1d}
  | "week" // aujourd'hui, hier, mercredi, {date}
  | "weekWithTime"; // {week} à {time}

const defineISOStringProperty = <
  // Property names must not overlap between `ISOString` and `LocalDate`.
  K extends Exclude<keyof ISOString, keyof LocalDate>,
>(
  key: K,
  value: ISOString[K],
) => {
  Object.defineProperty(String.prototype, key, { value });
};

defineISOStringProperty("getDate", function () {
  return new Date(this as unknown as string);
});
defineISOStringProperty("getTime", function () {
  return this.getDate().getTime();
});

defineISOStringProperty("asString", function () {
  return this as unknown as string;
});

defineISOStringProperty("millisecondsFromNow", function () {
  return Math.abs(new Date().getTime() - this.getTime());
});
defineISOStringProperty("secondsFromNow", function () {
  return Math.floor(this.millisecondsFromNow() / 1000);
});
defineISOStringProperty("secondsFrom", function (date) {
  return Math.abs(differenceInSeconds(this.getDate(), date.getDate()));
});

defineISOStringProperty("alignToSeconds", function () {
  const date = this.getDate();
  date.setMilliseconds(0);
  return date.toISOString();
});

defineISOStringProperty("plusMilliseconds", function (milliseconds) {
  return addMilliseconds(this.getDate(), milliseconds).toISOString();
});
defineISOStringProperty("minusMilliseconds", function (milliseconds) {
  return this.plusMilliseconds(-milliseconds);
});
defineISOStringProperty("plusSeconds", function (seconds) {
  return addSeconds(this.getDate(), seconds).toISOString();
});
defineISOStringProperty("minusSeconds", function (seconds) {
  return this.plusSeconds(-seconds);
});
defineISOStringProperty("plusMinutes", function (minutes) {
  return addMinutes(this.getDate(), minutes).toISOString();
});
defineISOStringProperty("minusMinutes", function (minutes) {
  return this.plusMinutes(-minutes);
});
defineISOStringProperty("plusHours", function (hours) {
  return addHours(this.getDate(), hours).toISOString();
});
defineISOStringProperty("minusHours", function (hours) {
  return this.plusHours(-hours);
});
defineISOStringProperty("plusDays", function (days) {
  return addDays(this.getDate(), days).toISOString();
});
defineISOStringProperty("minusDays", function (days) {
  return this.plusDays(-days);
});
defineISOStringProperty("plusWeeks", function (weeks) {
  return addWeeks(this.getDate(), weeks).toISOString();
});
defineISOStringProperty("minusWeeks", function (weeks) {
  return this.plusWeeks(-weeks);
});
defineISOStringProperty("plusMonths", function (months) {
  return addMonths(this.getDate(), months).toISOString();
});
defineISOStringProperty("minusMonths", function (months) {
  return this.plusMonths(-months);
});
defineISOStringProperty("plusYears", function (years) {
  return addYears(this.getDate(), years).toISOString();
});
defineISOStringProperty("minusYears", function (years) {
  return this.plusYears(-years);
});

defineISOStringProperty("startOfDay", function () {
  return this.getDate().startOfDay().toISOString();
});
defineISOStringProperty("endOfDay", function () {
  return this.getDate().endOfDay().toISOString();
});
defineISOStringProperty("startOfWeek", function () {
  // Until we target US/Canada, startOfWeek = startOfISOWeek
  return this.getDate().startOfWeek().toISOString();
});
defineISOStringProperty("endOfWeek", function () {
  return this.getDate().endOfWeek().toISOString();
});
defineISOStringProperty("startOfMonth", function () {
  return this.getDate().startOfMonth().toISOString();
});
defineISOStringProperty("endOfMonth", function () {
  return this.getDate().endOfMonth().toISOString();
});

defineISOStringProperty("setMilliseconds", function (milliseconds) {
  return setMilliseconds(this.getDate(), milliseconds).toISOString();
});
defineISOStringProperty("setSeconds", function (seconds) {
  return setSeconds(this.getDate(), seconds).toISOString();
});
defineISOStringProperty("setMinutes", function (minutes) {
  return setMinutes(this.getDate(), minutes).toISOString();
});
defineISOStringProperty("setHours", function (hours) {
  return setHours(this.getDate(), hours).toISOString();
});
defineISOStringProperty("setDay", function (dayOfMonth) {
  return setDate(this.getDate(), dayOfMonth).toISOString();
});
defineISOStringProperty("setMonth", function (month) {
  return setMonth(this.getDate(), month).toISOString();
});
defineISOStringProperty("setYear", function (year) {
  return setYear(this.getDate(), year).toISOString();
});

defineISOStringProperty("isSameDay", function (date) {
  const thisLuxon = DateTime.fromJSDate(this.getDate());
  const dateLuxon = DateTime.fromJSDate(date.getDate());
  return (
    thisLuxon.year === dateLuxon.year &&
    thisLuxon.month === dateLuxon.month &&
    thisLuxon.day === dateLuxon.day
  );
});

defineISOStringProperty("isToday", function () {
  return this.isSameDay(now());
});

defineISOStringProperty("isTomorrow", function () {
  return this.isSameDay(now().plusDays(1));
});

defineISOStringProperty("isYesterday", function () {
  return this.isSameDay(now().minusDays(1));
});

defineISOStringProperty("isBefore", function (date) {
  return this.getTime() < date.getTime();
});
defineISOStringProperty("isBeforeOrEqual", function (date) {
  return this.getTime() <= date.getTime();
});
defineISOStringProperty("isAfter", function (date) {
  return this.getTime() > date.getTime();
});
defineISOStringProperty("isAfterOrEqual", function (date) {
  return this.getTime() >= date.getTime();
});
defineISOStringProperty("isPast", function () {
  return this.isBefore(now());
});
defineISOStringProperty("isFuture", function () {
  return this.isAfter(now());
});
defineISOStringProperty("isBeforeToday", function () {
  return this.isPast() && !this.isToday();
});
defineISOStringProperty("isAfterToday", function () {
  return this.isFuture() && !this.isToday();
});
defineISOStringProperty("weekday", function () {
  return DateTime.fromJSDate(this.getDate()).weekday;
});

defineISOStringProperty("format", function (format) {
  const libFormat = (stringFormat: string) =>
    DateTime.fromISO(this as unknown as string)
      .setZone(Settings.defaultZone)
      .toFormat(stringFormat, {
        locale: t("extensions.date.enus"),
      });

  if (typeof format === "string") {
    return libFormat(
      {
        monthAndYear: "MMM y",
        date: t("common.date_format"),
        shortDate:
          this.getDate().getFullYear() === new Date().getFullYear()
            ? t("common.short_date_format")
            : t("common.date_format"),
        monthDay: "d",
        time: "HH:mm",
        file: "yyyy-MM-dd-HH'h'mm",
        dateAndTime: t("common.date_and_time_format"),
      }[format],
    );
  }

  if ("exception" in format) return libFormat(format.exception);

  // Relative
  if (
    format.relative === "timeOrDate" ||
    format.relative === "timeOrDateWithTime"
  ) {
    const numSecs = this.secondsFromNow();
    if (this.isPast()) {
      if (numSecs < 60) return t("extensions.date.less_than_one_minute_ago");
      if (numSecs < 60 * 60) {
        const minutes = Math.floor(numSecs / 60);
        return t("extensions.date.minutes_ago", { minutes });
      }
      if (numSecs < 24 * 60 * 60) {
        const hours = Math.floor(numSecs / 60 / 60);
        return t("extensions.date.hours_ago", { hours });
      }
    } else {
      if (numSecs < 60) return t("extensions.date.in_less_than_one_minute");
      if (numSecs < 60 * 60) {
        const minutes = Math.ceil(numSecs / 60);
        return t("extensions.date.in_minutes", { minutes });
      }
      if (numSecs < 24 * 60 * 60) {
        const hours = Math.ceil(numSecs / 60 / 60);
        return t("extensions.date.in_hours", { hours });
      }
    }
  }

  const relativeDate = (() => {
    if (this.isToday()) return t("extensions.date.today");
    if (this.isYesterday()) return t("extensions.date.yesterday");
    if (this.isTomorrow()) return t("extensions.date.tomorrow");
    if (Math.abs(differenceInCalendarDays(new Date(), this.getDate())) < 7) {
      return this.format({ exception: "EEEE" });
    }
    return `${
      format.withDatePrefix ? t("extensions.date.the_") : ""
    }${this.format("shortDate")}`;
  })();

  return format.relative === "weekWithTime" ||
    format.relative === "timeOrDateWithTime"
    ? `${relativeDate} ${t("extensions.date.at")} ${this.format("time")}`
    : relativeDate;
});

const defineDateProperty = <K extends keyof Date>(key: K, value: Date[K]) => {
  Object.defineProperty(Date.prototype, key, { value });
};

defineDateProperty("startOfDay", function () {
  return DateTime.fromJSDate(this).startOf("day").toJSDate();
});

defineDateProperty("endOfDay", function () {
  return DateTime.fromJSDate(this).endOf("day").toJSDate();
});

defineDateProperty("startOfWeek", function () {
  return DateTime.fromJSDate(this).startOf("week").toJSDate();
});

defineDateProperty("endOfWeek", function () {
  return DateTime.fromJSDate(this).endOf("week").toJSDate();
});

defineDateProperty("startOfMonth", function () {
  return DateTime.fromJSDate(this).startOf("month").toJSDate();
});

defineDateProperty("endOfMonth", function () {
  return DateTime.fromJSDate(this).endOf("month").toJSDate();
});

defineDateProperty("getHours", function () {
  return DateTime.fromJSDate(this).get("hour");
});

defineDateProperty("getMinutes", function () {
  return DateTime.fromJSDate(this).get("minute");
});

defineDateProperty("plusDays", function (days: number) {
  return DateTime.fromJSDate(this).plus({ day: days }).toJSDate();
});

defineDateProperty("minusDays", function (days: number) {
  return DateTime.fromJSDate(this).minus({ day: days }).toJSDate();
});
