import { useSubmit } from "hooks/useSubmit";

import { Button, FormButtonProps } from "./Button";

export const Submit = <
  SubmitContext extends Record<string, unknown> = { genericRequired: true },
>({
  disabled = false,
  requiresDirty = false,
  requiresValid = false,
  context,
  ...rest
}: {
  requiresDirty?: boolean;
  requiresValid?: boolean;
  context?: SubmitContext;
} & FormButtonProps) => {
  const { submitProps, isSubmitting } = useSubmit<SubmitContext>({
    disabled,
    requiresDirty,
    requiresValid,
    context,
  });
  return <Button loading={isSubmitting} {...rest} {...submitProps} />;
};
