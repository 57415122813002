import {
  ComponentProps,
  FunctionComponent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { Location } from "history";
import { useLocation, useSearchParams } from "react-router-dom";

import { useLoggedInAuth } from "auth/AuthContext";
import {
  TARGET_FRONTEND_SEARCH_PARAM,
  TARGET_SUB_ORGANIZATION_UUID_PARAM,
} from "auth/utils/copilot";
import { Button } from "components/Button/Button";
import {
  IdentitiesQuery,
  IdentitiesQueryData,
} from "components/IdentityPicker/IdentitiesQuery";
import { IdentityPicker } from "components/IdentityPicker/IdentityPicker";
import {
  IdentityPickerState,
  isPickableIdentity,
  usePickIdentity,
} from "components/IdentityPicker/utils";
import {
  PublicScreenImage,
  PublicScreenWrapperWithEnvironmentBanner,
} from "components/PublicScreenWrapper/PublicScreenWrapper";
import { Spinner } from "components/Spinner/Spinner";
import {
  TargetFrontend,
  TargetFrontendKnownValues,
} from "generated/unauth-account";
import { useSetLocaleAndTimezone } from "hooks/useSetLocaleAndTimezone";
import { useTranslation } from "i18n";
import { run } from "utils";
import { getKnownValue } from "utils/enum";

// Shown after logging in to an account.
export const FullPageIdentityPicker = () => {
  const [searchParams] = useSearchParams();
  const targetFrontend = getKnownValue(
    searchParams.get(TARGET_FRONTEND_SEARCH_PARAM),
    TargetFrontendKnownValues,
  );
  const targetSubOrganizationUuid = searchParams.get(
    TARGET_SUB_ORGANIZATION_UUID_PARAM,
  );

  return (
    <IdentitiesQuery
      filter={{
        kind: run(() => {
          switch (targetFrontend) {
            case "COPILOT_CHROME_EXTENSION":
            case "COPILOT_WEB_APP":
            case "COPILOT_MOBILE_APP":
              return "COPILOT_ASSISTANT_IDENTITIES" as const;
            case null:
              return "CONSOLE_IDENTITIES" as const;
          }
        }),
        onlyInSubOrganizationUuid: targetSubOrganizationUuid ?? undefined,
      }}
    >
      {(data) => (
        <FullPageIdentityPickerContent
          {...data}
          targetFrontend={targetFrontend}
        />
      )}
    </IdentitiesQuery>
  );
};

const FullPageIdentityPickerContent = ({
  identities,
  accountLocale,
  accountTimezone,
  targetFrontend,
}: IdentitiesQueryData & {
  targetFrontend: TargetFrontend | null;
}) => {
  useSetLocaleAndTimezone(accountLocale, accountTimezone);

  const t = useTranslation();
  const { logout } = useLoggedInAuth();
  const { state }: Location<IdentityPickerState> = useLocation();

  const publicScreenImage: PublicScreenImage = "COPILOT";

  if (identities.isEmpty()) {
    return (
      <PublicScreenWrapperWithEnvironmentBanner image={publicScreenImage}>
        <div className="flex-col items-center gap-36 w-full max-w-[450px]">
          <h1 className="title">Something went wrong</h1>
          <div className="text-16 leading-20 text-extension-grey-2 text-center">
            Your account does not have access to the <b>Nabla Core API</b>,
            please sign up using another email address or reach out to{" "}
            <a
              className="link"
              href="mailto:api@nabla.com"
              target="_blank"
              rel="noreferrer"
            >
              api@nabla.com
            </a>{" "}
            for assistance.
            <br />
          </div>

          <Button
            secondary
            onClick={logout}
            label={t("pick_identity.logout")}
          />
        </div>
      </PublicScreenWrapperWithEnvironmentBanner>
    );
  }

  const PickerLayout = ({ children }: { children: ReactNode }) => (
    <PublicScreenWrapperWithEnvironmentBanner image={publicScreenImage}>
      <div className="flex-col items-center gap-36 w-full max-w-[450px]">
        <h1 className="title">{t("pick_identity.title")}</h1>
        {children}

        <button onClick={logout} className="p-8 text-primary hover:opacity-70">
          {t("pick_identity.logout")}
        </button>
      </div>
    </PublicScreenWrapperWithEnvironmentBanner>
  );

  return state?.shouldAutomaticallyPickSingleIdentity ? (
    <IdentityPickerWithAutomaticPickingOfSingleIdentity
      identities={identities}
      pickerLayout={PickerLayout}
      targetFrontend={targetFrontend}
    />
  ) : (
    <IdentityPickerWithLayout
      identities={identities}
      pickerLayout={PickerLayout}
      targetFrontend={targetFrontend}
    />
  );
};

// Displays an identity picker if needed, redirecting the user automatically
// if there is only a single pickable identity to choose from.
const IdentityPickerWithAutomaticPickingOfSingleIdentity = ({
  identities,
  targetFrontend,
  ...rest
}: ComponentProps<typeof IdentityPickerWithLayout>) => {
  const singlePickableIdentity = useMemo(() => {
    const allIdentities = identities.flatMap(
      (it) => it.identitiesForOrganization,
    );

    const pickableIdentities = allIdentities.filter(isPickableIdentity);
    return pickableIdentities.length === 1 ? pickableIdentities[0] : null;
  }, [identities]);

  const pickIdentity = usePickIdentity();
  const shouldAutomaticallyPickSingleIdentity = singlePickableIdentity !== null;
  const startedAutomaticallyPickingSingleIdentityRef = useRef(false);

  useEffect(() => {
    if (!shouldAutomaticallyPickSingleIdentity) return;
    if (startedAutomaticallyPickingSingleIdentityRef.current) return;
    startedAutomaticallyPickingSingleIdentityRef.current = true;
    pickIdentity(singlePickableIdentity, { targetFrontend });
  }, [
    shouldAutomaticallyPickSingleIdentity,
    pickIdentity,
    singlePickableIdentity,
    targetFrontend,
  ]);

  return shouldAutomaticallyPickSingleIdentity ? (
    <Spinner />
  ) : (
    <IdentityPickerWithLayout
      identities={identities}
      targetFrontend={targetFrontend}
      {...rest}
    />
  );
};

const IdentityPickerWithLayout = ({
  pickerLayout: PickerLayout,
  ...rest
}: ComponentProps<typeof IdentityPicker> & {
  pickerLayout: FunctionComponent<{ children: ReactNode }>;
}) => (
  <PickerLayout>
    <IdentityPicker {...rest} />
  </PickerLayout>
);
