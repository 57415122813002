import { useState } from "react";
import { FieldValidator } from "formik/dist/types";

import { ClickableIcon } from "components/Icon/ClickableIcon";

import { FormInput } from "./FormInput";
import { Input, InputProps } from "./Input";

export type PasswordInputProps = Omit<
  InputProps,
  "type" | "rightInnerElement"
> & {
  autoComplete?: "new-password" | "current-password";
  validate?: FieldValidator;
};

const usePasswordInputProps = () => {
  const [showCharacters, setShowCharacter] = useState(false);

  return {
    type: showCharacters ? "text" : "password",
    rightInnerElement: (
      <ClickableIcon
        name={showCharacters ? "eye" : "eyeSlash"}
        onMouseDown={(e) => {
          e.preventDefault(); // Avoid input blur
          setShowCharacter(!showCharacters);
        }}
      />
    ),
  };
};

export const PasswordInput = (props: PasswordInputProps) => {
  const passwordInputProps = usePasswordInputProps();

  return <Input {...passwordInputProps} {...props} />;
};

export const FormPasswordInput = (props: PasswordInputProps) => {
  const passwordInputProps = usePasswordInputProps();

  return <FormInput {...passwordInputProps} {...props} />;
};
