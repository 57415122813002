import { useField } from "hooks/useField";

import { MultiSelect, MultiSelectProps } from "./MultiSelect";

export type FormMultiSelectProps<Option> = Omit<
  MultiSelectProps<Option>,
  "value" | "error" | "onChange"
> &
  Partial<Pick<MultiSelectProps<Option>, "onChange">>;

export const FormMultiSelect = <Option extends unknown>({
  name,
  onChange,
  disabled,
  ...props
}: FormMultiSelectProps<Option>) => {
  const [{ value, disabled: fieldDisabled }, { error }, { setValue }] =
    useField<Option[]>({ name, disabled });

  return (
    <MultiSelect
      name={name}
      value={value}
      error={error}
      disabled={fieldDisabled}
      onChange={(v) => {
        setValue(v);
        onChange?.(v);
      }}
      {...props}
    />
  );
};
