import { useMemo } from "react";
import { ApolloClient, InMemoryCache, Operation, split } from "@apollo/client";
import produce from "immer";

import { Fragment, Query, SchemaType } from "base-types";
import {
  FragmentUpdateOptions,
  GraphQLClient,
  possibleTypes,
  QueryUpdateOptions,
} from "types";

import { PartialResultError, useErrorLink } from "./errors";
import { WithOperation } from "./types";
import { useSchemaHttpLink } from "./useSchemaHttpLink";
import { useSchemaSubscriptionLink } from "./useSchemaSubscriptionLink";
import { getOperationKey, getOutput } from "./utils";

export type CustomGraphQLClient = {
  client: GraphQLClient;
  isSocketReady: boolean;
};

export const useSchemaClient = <T extends SchemaType>({
  schemaType,
  addPartialResultError,
}: {
  schemaType: T;
  addPartialResultError: (error: PartialResultError) => void;
}): CustomGraphQLClient => {
  const httpLink = useSchemaHttpLink(schemaType);
  const errorLink = useErrorLink(addPartialResultError);
  const { subscriptionLink, isSocketReady } =
    useSchemaSubscriptionLink(schemaType);

  const memoizedClient: GraphQLClient = useMemo(
    () => {
      console.debug(`Creating a new Apollo client for ${schemaType}.`);

      const link = split(
        isSubscriptionPredicate,
        subscriptionLink,
        errorLink.concat(httpLink),
      );

      const cache = new InMemoryCache({
        dataIdFromObject: (obj: any) =>
          obj.uuid && obj.__typename
            ? getCacheId(obj.__typename, obj.uuid)
            : undefined,
        possibleTypes,
        typePolicies: {
          Query: {
            fields: {
              cachedQAInbox: {
                read: (existing) => existing ?? [],
              },
              patient: {
                // If we received null for a patient query, there is more chance that's a backend resolution error
                // for a sub part of the EHR than the patient was in reality just deleted.
                // So in that case we're keeping existing data to allow navigating in the rest of the EHR
                merge: (existing, incoming, { mergeObjects }) =>
                  incoming ? mergeObjects(existing, incoming) : existing,
              },
            },
          },

          // The `me` query will always refer to the same doctor or copilot user.
          MeDoctorOutput: {
            keyFields: [],
            fields: { doctor: { merge: true } },
          },
          MeOutput: {
            keyFields: [],
            fields: { user: { merge: true } },
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        afterWriteCallback: () => {},
      });

      const client = new ApolloClient({ link, cache }) as GraphQLClient;

      client.read = (
        queryOrFragment: Fragment<any> | Query<any, any, any, SchemaType>,
        variablesOrUuid: any,
      ) => {
        if ("fragmentName" in queryOrFragment) {
          return client.readFragment({
            id: getCacheId(queryOrFragment.entityName, variablesOrUuid),
            fragment: queryOrFragment.document,
            fragmentName: queryOrFragment.fragmentName,
          });
        }
        return getOutput(
          client.readQuery({
            query: queryOrFragment.document,
            variables: variablesOrUuid,
          }),
        );
      };

      client.update = <Data, Variables, VariablesRequired>(
        options:
          | QueryUpdateOptions<Data, Variables, VariablesRequired>
          | FragmentUpdateOptions<Data>,
      ) => {
        if ("query" in options) {
          const result = client.readQuery<WithOperation<Data>, Variables>({
            query: options.query.document,
            variables: options.variables,
          });
          const currentOutput = getOutput(result);
          if (!result || !currentOutput) return; // The query has not been executed yet
          if (options.skip?.(currentOutput)) return;

          client.writeQuery({
            query: options.query.document,
            variables: options.variables,
            data: {
              ...result,
              [getOperationKey(result)]: produce(currentOutput, options.write),
            },
          });
        } else {
          const opts = {
            id: getCacheId(options.fragment.entityName, options.uuid),
            fragment: options.fragment.document,
            fragmentName: options.fragment.fragmentName,
          };
          const result = client.readFragment<Data>(opts);
          if (!result) return;
          if (options.skip?.(result)) return;
          client.writeFragment({
            ...opts,
            data: produce(result, options.write),
          });
        }
      };

      client.updateAll = ({ query, queryName, write }) => {
        const allVariables: { [key: string]: any }[] = [];
        client.cache.modify({
          id: "ROOT_QUERY",
          fields: {
            [queryName]: (data, { storeFieldName }) => {
              const index = storeFieldName.indexOfOrUndefined("(");
              allVariables.push(
                index ? JSON.parse(storeFieldName.slice(index + 1, -1)) : {},
              );
              return data;
            },
          },
        });
        allVariables.forEach((variables) => {
          client.update({ query, variables, write });
        });
      };

      client.remove = (entityName, uuid) => {
        client.cache.evict({ id: getCacheId(entityName, uuid) });
      };

      client.evict = ({ entityName, uuid, field }) => {
        client.cache.evict({
          id: getCacheId(entityName, uuid),
          fieldName: field,
        });
      };

      client.evictQuery = (queryName, variable) => {
        client.cache.modify({
          id: "ROOT_QUERY",
          fields: {
            [queryName]: (data, { storeFieldName, DELETE }) => {
              if (
                variable === "ALL" ||
                storeFieldName.includes(`"${variable}"`)
              ) {
                return DELETE;
              }
              return data;
            },
          },
        });
      };

      return client;
    },
    // These links will only change when the authentication context changes.
    [schemaType, httpLink, errorLink, subscriptionLink],
  );

  return { client: memoizedClient, isSocketReady };
};

export const getCacheId = (entityName: string, uuid: string) =>
  `${entityName}:${uuid}`;

const isSubscriptionPredicate = (def: Operation): boolean =>
  def.query.definitions.some(
    ({ kind, operation }: any) =>
      kind === "OperationDefinition" && operation === "subscription",
  );

// Extensions provided by `patches/@apollo+client+3.6.5.patch`.
declare module "@apollo/client" {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface FieldFunctionOptions {
    storageArgs: string[]; // The path of the current field in the merge tree.
    userContext: Record<string, any>; // User-defined mutable context for the current merge operation.
  }

  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface InMemoryCacheConfig {
    afterWriteCallback: (userContext: Record<any, any>) => void;
  }
}
