import { useField } from "hooks/useField";

import { CheckBox, CheckBoxProps } from "./CheckBox";

export const FormCheckbox = ({
  name,
  disabled,
  onChange,
  ...props
}: Omit<CheckBoxProps, "onChange" | "checked"> & {
  name: string;
  onChange?: (newValue: boolean) => void;
}) => {
  const [{ value, disabled: fieldDisabled }, { error }, { setValue }] =
    useField({ name, disabled });

  return (
    <CheckBox
      name={name}
      checked={value}
      onChange={(newValue) => {
        onChange?.(newValue);
        setValue(newValue);
      }}
      hasError={!!error}
      disabled={fieldDisabled}
      {...props}
    />
  );
};
