import { Draft } from "immer";

import { Query, SchemaType } from "base-types";
import { ephemeralUuidV4 } from "utils/stackoverflow";

import { QueryOptions, QueryOutput, useQuery } from "./useQuery";

type DefaultPageElement = { uuid: UUID } | { __typename: "FutureValue" } | null;
type PageOutput<Cursor, PageElement> = {
  data: PageElement[];
  hasMore: boolean;
  nextCursor: Cursor;
};

export type PaginatedQueryOptions<
  Data,
  Cursor,
  Variables extends { cursor?: Cursor },
  VariablesRequired,
  Schema extends SchemaType,
  PageElement,
> = Omit<
  QueryOptions<Variables, VariablesRequired, Schema>,
  "notifyOnNetworkStatusChange"
> &
  (Data extends PageOutput<Cursor, DefaultPageElement>
    ? { selector?: never; uuidSelector?: never }
    :
        | {
            selector: (data: Data) => PageOutput<Cursor, DefaultPageElement>;
            uuidSelector?: never;
          }
        | {
            selector: (data: Data) => PageOutput<Cursor, PageElement>;
            uuidSelector: (element: PageElement) => UUID | undefined | null;
          });

export type PaginationProps = {
  nextPage: (() => void) | undefined;
  fetchingMore: boolean;
};
export type PaginatedQueryUtils<Data, Variables> = PaginationProps &
  Pick<QueryOutput<Data, Variables>, "fetchMore" | "refetch" | "update">;

export const usePaginatedQuery = <
  Data,
  Cursor,
  Variables extends { cursor?: Cursor },
  VariablesRequired,
  Schema extends SchemaType,
  PageElement,
>(
  query: Query<Data, Variables, VariablesRequired, Schema>,
  {
    // @ts-ignore
    selector = (d) => d,
    // @ts-ignore
    uuidSelector = (el: unknown) => el?.uuid,
    ...options
  }: PaginatedQueryOptions<
    Data,
    Cursor,
    Variables,
    VariablesRequired,
    Schema,
    PageElement
  >,
): Omit<QueryOutput<Data, Variables>, "nextPage" | "networkStatus"> &
  PaginatedQueryUtils<Data, Variables> => {
  const { nextPage, networkStatus, data, ...output } = useQuery<
    Data,
    Variables,
    VariablesRequired,
    Schema
  >(query, {
    ...(options as unknown as QueryOptions<
      Variables,
      VariablesRequired,
      Schema
    >),
    notifyOnNetworkStatusChange: true,
  });

  const page = data ? selector(data) : undefined;

  return {
    data,
    ...output,
    fetchingMore: networkStatus === "fetchingMore",
    nextPage:
      networkStatus === "ready" && page?.hasMore
        ? () => {
            nextPage(
              { cursor: page.nextCursor } as Partial<Variables>,
              (draft, result) => {
                mergePages({
                  draft,
                  result,
                  selector: selector as (
                    data: Data,
                  ) => PageOutput<unknown, PageElement>,
                  uuidSelector,
                });
              },
            );
          }
        : undefined,
  };
};

export const mergePages = <Data, PageElement>({
  draft,
  result,
  selector,
  uuidSelector,
}: {
  draft: Draft<Data>;
  result: Data;
  selector: (data: Data) => PageOutput<unknown, PageElement>;
  uuidSelector: (element: PageElement) => UUID | undefined | null;
}) => {
  const currentPage = selector(draft as Data);
  const nextPageResult = selector(result);
  currentPage.hasMore = nextPageResult.hasMore;
  currentPage.nextCursor = nextPageResult.nextCursor;
  currentPage.data = currentPage.data
    .concat(nextPageResult.data)
    .distinctBy((it) => uuidSelector(it) ?? ephemeralUuidV4());
};
