import { ReactNode } from "react";
import { NavLink } from "react-router-dom";

import { EnvironmentBanner } from "components/EnvironmentBanner/EnvironmentBanner";
import { Icon } from "components/Icon/Icon";
import { IdentitiesQuery } from "components/IdentityPicker/IdentitiesQuery";
import { IdentityPicker } from "components/IdentityPicker/IdentityPicker";
import { TopSafeArea } from "components/Mobile/SafeArea";
import { OrganizationLogo } from "components/OrganizationLogo/OrganizationLogo";
import { UncontrolledPopover } from "components/Popover/UncontrolledPopover";
import { useUser } from "contexts/User/UserContext";
import { AccountMenu } from "singletons/MainSidebar/AccountMenu";
import { StickyDebugIcon } from "singletons/MainSidebar/StickyDebugIcon";
import { AccountMenuItem, resolveSidebarLabel, ThinSidebarItem } from "types";
import { isDef } from "utils";

export type ThinSideBarProps<Item extends ThinSidebarItem = ThinSidebarItem> = {
  items: Item[];
  accountMenuItems?: AccountMenuItem[];
  bottomItems?: ReactNode;
  footer?: ReactNode;
};

export const ThinSideBar = <Item extends ThinSidebarItem>({
  items,
  accountMenuItems,
  bottomItems,
  footer,
}: ThinSideBarProps<Item>) => {
  const organizationLogo = <OrganizationLogo fixedIcon="people" />;

  return (
    <div className="flex-col bg-light-black">
      <TopSafeArea />
      <nav className="sm:h-60 lg:flex-fill lg:w-80 flex lg:flex-col">
        <div className="flex-fill flex lg:flex-col overflow-y-auto overflow-x-hidden">
          {/* Only show the identity picker when logged with account tokens. */}
          <UncontrolledPopover
            noArrow
            position="bottom-left"
            className="sm:mt-10 sm:ml-10 lg:mt-[-65px] lg:ml-[87px]"
            content={(close) => (
              <div className="px-16 py-14 sm:max-h-[calc(100vh-80px)] max-h-[calc(100vh-30px)] overflow-y-auto">
                <IdentitiesQuery>
                  {({ identities }) => (
                    <IdentityPicker
                      identities={identities}
                      onAfterPick={close}
                    />
                  )}
                </IdentitiesQuery>
              </div>
            )}
          >
            {({ opened, setTarget }) => (
              <div
                className="sm:w-[70px] lg:h-80 flex-center relative cursor-pointer"
                onClick={setTarget}
              >
                {organizationLogo}
                <Icon
                  name="chevron"
                  rotate={opened ? -90 : 90}
                  size={18}
                  className="relative -mr-18 text-sidebar-100"
                />
                <EnvironmentBanner />
              </div>
            )}
          </UncontrolledPopover>
          {items.map((item) => (
            <Item key={item.to} {...item} />
          ))}
        </div>
        <StickyDebugIcon />
        {bottomItems}
        {accountMenuItems && <AccountMenu items={accountMenuItems} />}
      </nav>
      {footer}
    </div>
  );
};

const Item = ({ to, icon, label, useCount, maxCount }: ThinSidebarItem) => {
  const max = maxCount ?? 99;
  const accessProps = useUser();
  const resolvedLabel = resolveSidebarLabel(label, accessProps);
  const count = useCount?.();

  return (
    // This id allows e2e test
    <div className="sm:w-80 lg:my-4 flex-center relative" id={resolvedLabel}>
      {isDef(count) && (
        // This intermediate div allow e2e test to make the difference between count loading and 0
        <div className="absolute top-6 right-20 pointer-events-none">
          {count > 0 && (
            <div
              className="w-18 h-18 bg-danger rounded-full border border-white text-white flex-center"
              style={{ fontSize: count > max ? 8 : 10 }} // Makes `99+` fit in the badge
            >
              {count > max ? `${max}+` : count}
            </div>
          )}
        </div>
      )}
      <NavLink
        key={to}
        to={to}
        activeClassName="!text-white bg-white bg-opacity-10"
        className="w-72 flex-col pt-8 flex-center shadow-light text-sidebar-100 hover:bg-white hover:bg-opacity-10"
        style={{ borderRadius: 8, paddingBottom: 5 }}
      >
        <Icon name={icon} />
        <p className="text-10 -mt-2 text-center font-medium">{resolvedLabel}</p>
      </NavLink>
    </div>
  );
};
