import * as Sentry from "@sentry/browser";

import { commitHash, getConfig } from "utils/environment";

if (process.env.NODE_ENV === "production") {
  const environment = getConfig<string | false>({
    dev: false,
    pr: false,
    staging: "staging",
    preprod: "preprod",
    prod: "prod",
  });
  if (environment) {
    Sentry.init({
      dsn: "https://4467577b04bc49f08ea62bf3c9f9c0da@sentry-relay.nabla.com/4504525093339136",
      release: `nabla-tech.console-${commitHash}`, // Should be in sync with getSentryReleaseName in Jenkins
      environment,
    });
  }
}
