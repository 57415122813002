import { ReactNode } from "react";
import classNames from "classnames";

import { Submit } from "components/Button/Submit";
import { Form, FormProps } from "components/Form/Form/Form";

import { Modal, ModalProps } from "./Modal";
import styles from "./formModal.module.css";

export const FormModal = <Values extends Record<string, unknown>>({
  title,
  show = true,
  onHide,
  alignTopOnMobile = true,
  children,
  xl,
  submitLabel,
  showSubmitButton = true,
  className,
  otherButton,
  ...other
}: FormProps<Values> &
  Pick<ModalProps, "title" | "xl" | "show" | "onHide" | "alignTopOnMobile"> & {
    submitLabel: string;
    showSubmitButton?: boolean;
    otherButton?: ReactNode;
  }) => (
  <Modal
    title={title}
    show={show}
    onHide={onHide}
    alignTopOnMobile={alignTopOnMobile}
    xl={xl}
  >
    <Form<Values> className={classNames(styles.form, className)} {...other}>
      {children}
      {showSubmitButton && <Submit className="mt-36" label={submitLabel} />}
      {otherButton}
    </Form>
  </Modal>
);
