import { useCallback } from "react";
import { Observable, RequestHandler } from "@apollo/client";

import { ApiError } from "api/api-client";
import { queryGqlSchema } from "api/graphql-client";
import { useAppVersion } from "contexts/AppVersion/AppVersionContext";

import { RequestContextOptions } from "./request-context-utils";

export const useUnauthSchemaHttpLink = (
  clientName?: string,
): RequestHandler => {
  const { updateMinimumCodeVersionIfNeeded } = useAppVersion();

  return useCallback(
    (operation) =>
      new Observable((observer) => {
        let requestContext;
        try {
          requestContext = (
            operation.getContext() as RequestContextOptions<"UNAUTHENTICATED_COPILOT_API_DEVELOPER">
          ).requestContext!;
        } catch (e) {
          observer.error(e);
          return;
        }

        const { promise, cancel } = queryGqlSchema(
          "UNAUTHENTICATED_COPILOT_API_DEVELOPER",
          operation,
          requestContext,
          clientName,
        );

        promise
          .then(({ data, requestId, minimumCodeVersion }) => {
            if (minimumCodeVersion) {
              updateMinimumCodeVersionIfNeeded(minimumCodeVersion);
            }
            operation.setContext({ requestId });
            observer.next(data);
            observer.complete();
          })
          .catch((error: unknown) => {
            if (error instanceof ApiError) {
              if (error.isCanceled) return; // Don't propagate cancellation errors.
              operation.setContext({ requestId: error.requestId });
            }
            observer.error(error);
          });

        return cancel;
      }),
    [clientName, updateMinimumCodeVersionIfNeeded],
  );
};
