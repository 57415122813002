import { createContext, useContext } from "react";

export const FormExtraContext = createContext<{
  disabled: boolean;
  requiredKeys: string[];
} | null>(null);
FormExtraContext.displayName = "FormExtraContext";

export const useFormExtraContext = () => {
  const context = useContext(FormExtraContext);
  if (!context) throw new Error("FormExtraContext not available");
  return context;
};
