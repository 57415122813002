import { useState } from "react";
import { differenceInMilliseconds } from "date-fns";
import { DateTime } from "luxon";

import { useField } from "hooks/useField";
import { useTranslation } from "i18n";

import { LabelWrapper } from "../Label/LabelWrapper";
import { DatePicker, DatePickerProps } from "./DatePicker";
import { TimePicker } from "./TimePicker";
import { Time } from "./utils";

export const FormDateAndHours = <IsClearable extends boolean>({
  wrapperClassName,
  label,
  hint,
  startName,
  endName,
  hoursStartLabel,
  hoursEndLabel,

  disabled,
  disableHours,
  loading,
  timePossibilities,
  ...props
}: Omit<
  DatePickerProps<IsClearable>,
  "value" | "onChange" | "error" | "noteComposer" | "possibilities"
> & {
  startName: string;
  endName: string;
  disableHours?: boolean;
  hoursStartLabel?: string;
  hoursEndLabel?: string;
  timePossibilities?: Time[];
}) => {
  const t = useTranslation();
  const [
    { value: currentStart, disabled: startDisabled },
    { error: startError },
    { setValue: setStart },
  ] = useField<ISOString | undefined>({ name: startName, disabled });
  const [
    { value: currentEnd, disabled: endDisabled },
    { error: endError },
    { setValue: setEnd },
  ] = useField<ISOString | undefined>({ name: endName, disabled });

  const [baseDate, setBaseDate] = useState<ISOString | undefined>(
    currentStart?.startOfDay(),
  );

  const startTime = currentStart
    ? {
        hours: currentStart.getDate().getHours(),
        minutes: currentStart.getDate().getMinutes(),
      }
    : undefined;

  const endTime = currentEnd
    ? {
        hours: currentEnd.getDate().getHours(),
        minutes: currentEnd.getDate().getMinutes(),
      }
    : undefined;

  const currentDuration =
    currentStart && currentEnd
      ? differenceInMilliseconds(currentEnd.getDate(), currentStart.getDate()) /
        60000
      : null;

  const setStartTimeWithAutomaticEndUpdateIfNeeded = (value: ISOString) => {
    setStart(value);
    if (currentDuration) setEnd(value.plusMinutes(currentDuration));
  };

  const setEndTimeWithAutomaticStartUpdateIfNeeded = (value: ISOString) => {
    setEnd(
      currentStart?.isAfter(value)
        ? // end time brought before start time, move it to next day to have a <24h over-midnight period.
          withTimeFrom(currentStart.plusDays(1), value)
        : currentStart &&
          differenceInMilliseconds(value.getDate(), currentStart.getDate()) >
            24 * 60 * 60 * 1000
        ? // over-midnight time interval is now greater than 24h, let's bring it back to base date.
          withTimeFrom(currentStart, value)
        : value,
    );
  };

  return (
    <LabelWrapper
      wrapperClassName={wrapperClassName}
      label={undefined}
      error={startError ?? endError}
      hint={hint}
      useDiv
    >
      <div className="flex items-end">
        <DatePicker
          wrapperClassName="flex-2"
          label={label}
          value={baseDate}
          loading={loading}
          disabled={startDisabled}
          onChange={(value: ISOString | undefined) => {
            setBaseDate(value);
            value &&
              currentStart &&
              setStartTimeWithAutomaticEndUpdateIfNeeded(
                withTimeFrom(value, currentStart),
              );
          }}
          {...props}
        />
        {!disableHours && (
          <>
            <TimePicker
              wrapperClassName="flex-1 mx-20"
              name={`${startName}_hours`}
              label={
                hoursStartLabel ??
                t("form.date_picker.form_date_and_hours.start")
              }
              value={startTime}
              loading={loading}
              disabled={startDisabled || disableHours}
              options={timePossibilities}
              onChange={(value: Time) => {
                if (baseDate === undefined) return;
                setStartTimeWithAutomaticEndUpdateIfNeeded(
                  toISOString(baseDate, value),
                );
              }}
            />
            <TimePicker
              wrapperClassName="flex-1"
              name={endName}
              label={
                hoursEndLabel ??
                (currentEnd && currentStart?.isSameDay(currentEnd))
                  ? t("form.date_picker.form_date_and_hours.end")
                  : t("form.date_picker.form_date_and_hours.end.next_day")
              }
              value={endTime}
              loading={loading}
              disabled={endDisabled || disableHours}
              options={timePossibilities}
              onChange={(value: Time) => {
                if (currentEnd === undefined) return;
                setEndTimeWithAutomaticStartUpdateIfNeeded(
                  toISOString(currentEnd, value),
                );
              }}
            />
          </>
        )}
      </div>
    </LabelWrapper>
  );
};

const toISOString = (baseDate: ISOString, time: Time) =>
  DateTime.fromObject({
    year: baseDate.getDate().getFullYear(),
    month: baseDate.getDate().getMonth() + 1,
    day: baseDate.getDate().getDate(),
    hour: time.hours,
    minute: time.minutes,
  })
    .toJSDate()
    .toISOString();

const withTimeFrom = (baseDate: ISOString, dateForTime: ISOString) =>
  DateTime.fromObject({
    year: baseDate.getDate().getFullYear(),
    month: baseDate.getDate().getMonth() + 1,
    day: baseDate.getDate().getDate(),
    hour: dateForTime.getDate().getHours(),
    minute: dateForTime.getDate().getMinutes(),
  })
    .toJSDate()
    .toISOString();
