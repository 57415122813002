import classNames from "classnames";

import { LabelWrapper, UnnamedLabelWrapperProps } from "../Label/LabelWrapper";
import styles from "./buttonGroup.module.css";

export type ButtonGroupProps<O> = UnnamedLabelWrapperProps & {
  value: O;
  options: Readonly<O[]>;
  onChange?: (o: O) => void;
  disabled?: boolean;
  getOptionLabel: (o: O) => string;
  className?: string;
  buttonClassName?: string;
};

export const ButtonGroup = <O extends unknown>({
  wrapperClassName,
  label,
  error,
  hint,

  value,
  options,
  getOptionLabel,
  onChange,
  disabled = false,
  className,
  buttonClassName,
}: ButtonGroupProps<O>) => (
  <LabelWrapper
    wrapperClassName={wrapperClassName}
    label={label}
    error={error}
    hint={hint}
    useDiv
  >
    <div
      className={classNames(styles.buttonGroup, className, {
        "border-danger": !!error,
      })}
    >
      {options.map((o) => (
        <button
          key={getOptionLabel(o)}
          className={classNames(
            styles.button,
            { [styles.selected]: value === o },
            buttonClassName,
          )}
          disabled={disabled}
          onClick={() => onChange?.(o)}
          type="button"
        >
          {getOptionLabel(o)}
        </button>
      ))}
    </div>
  </LabelWrapper>
);
