import { Fragment } from "react";
import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Popover } from "components/Popover/Popover";
import { useGraphQLClient } from "graphql-client/GraphQLClientContext";
import { useTargetState } from "hooks";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useDebugMode } from "hooks/useStorageState";
import { useTranslation } from "i18n";

export const StickyDebugIcon = () => {
  const t = useTranslation();
  const { partialResultErrors, clearPartialResultErrors } = useGraphQLClient();
  const [debugMode] = useDebugMode();
  const isDesktop = useIsDesktop();
  const [target, setTarget] = useTargetState();

  const hasError = partialResultErrors.length > 0;
  if (!hasError && !debugMode) return null;

  return (
    <>
      <ClickableIcon
        name="bug"
        onClick={hasError ? setTarget : undefined}
        title={
          hasError
            ? undefined
            : t("main_sidebar.sticky_debug_icon.debug_mode_on")
        }
        className={classNames(
          hasError
            ? "text-orange hover:opacity-80"
            : "text-primary cursor-default",
          isDesktop ? "mt-12" : "ml-12",
        )}
      />
      {target && (
        <Popover
          target={target}
          position={isDesktop ? "top-left" : "bottom-right"}
          className={classNames(
            "p-12 flex-col items-center overflow-auto",
            isDesktop ? "mb-4 ml-10" : "mt-4 mr-10",
          )}
          allowScrolling
          noArrow
          style={{ width: isDesktop ? 300 : 250, maxHeight: 500 }}
          onClose={() => setTarget(undefined)}
        >
          {partialResultErrors.map(
            ({ operation, errors, requestId }, index) => (
              <Fragment key={index}>
                <div className="text-primary-dark">{operation}</div>
                <div className="text-10">{requestId}</div>
                {errors.map((e, i) => (
                  <div key={i} className="text-12 text-danger break-all">
                    {e.message}
                    {" : "}
                    {e.path?.join(".")}
                  </div>
                ))}
              </Fragment>
            ),
          )}
          <button
            className="button-link"
            onClick={() => {
              setTarget(undefined);
              clearPartialResultErrors();
            }}
          >
            {t("main_sidebar.sticky_debug_icon.clear")}
          </button>
        </Popover>
      )}
    </>
  );
};
