import classNames from "classnames";

import {
  UncontrolledPopover,
  UncontrolledPopoverProps,
} from "components/Popover/UncontrolledPopover";
import { Separator } from "components/Separator/Separator";

import { MenuItem, MenuItemProps } from "./MenuItem";
import { separator } from "./MenuItemSeparator";
import styles from "./popoverMenu.module.css";

export type PopoverMenuProps = Omit<UncontrolledPopoverProps, "content"> & {
  items: (MenuItemProps | typeof separator)[];
  onClosed?: () => void;
  footer?: JSX.Element | null;
};

export const PopoverMenu = ({
  items,
  footer,
  className,
  onClosed,
  ...props
}: PopoverMenuProps) => (
  <UncontrolledPopover
    className={classNames(styles.menu, className)}
    content={(close) => (
      <>
        {items.map((item, i) =>
          item === separator ? (
            <Separator key={i} />
          ) : (
            <MenuItem
              key={i}
              item={item}
              close={() => {
                close();
                onClosed?.();
              }}
            />
          ),
        )}
        {footer}
      </>
    )}
    {...props}
  />
);
