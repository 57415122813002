import { useState } from "react";

import { MaybePromise } from "types";

import { useIsMounted } from "./useIsMounted";

export const useWaitPromise = () => {
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();

  return [
    (maybePromise: MaybePromise) => {
      if (maybePromise instanceof Promise) {
        setLoading(true);
        maybePromise.finally(() => {
          if (isMounted.current) setLoading(false);
        });
      }
    },
    loading,
  ] as const;
};
