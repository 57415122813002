import { HTMLProps } from "react";
import classNames from "classnames";

export const Background = ({
  className,
  ...rest
}: HTMLProps<HTMLDivElement>) => (
  <div
    // bg-light-blue is just placeholder while the image is loading,
    // which happen a lot in dev because the image is not cached
    className={classNames("bg-light-blue", className)}
    {...rest}
  />
);
