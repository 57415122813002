import { cloneDeep, get, set } from "lodash";

import { Exact } from "./Exact";

/**
 * Remove the wrapper for all updates inputs (UpdateForXInput) i.e { value: X }
 * { title: UpdateForStringInput } => { title?: Maybe<string> }
 */
type StrictObject = Record<string, unknown>;
export type VariablesWithoutUpdateInputs<Variables> = {
  [Key in keyof Variables]: NonNullable<Variables[Key]> extends StrictObject
    ? NonNullable<Variables[Key]> extends Exact<
        { value?: any },
        NonNullable<Variables[Key]>
      >
      ? NonNullable<Variables[Key]>["value"] | undefined
      : null extends Variables[Key]
      ?
          | VariablesWithoutUpdateInputs<NonNullable<Variables[Key]>>
          | undefined
          | null
      : VariablesWithoutUpdateInputs<NonNullable<Variables[Key]>>
    : NonNullable<Variables[Key]> extends (infer Element)[]
    ? Element extends StrictObject
      ? null extends Variables[Key]
        ? VariablesWithoutUpdateInputs<Element>[] | undefined | null
        : VariablesWithoutUpdateInputs<Element>[]
      : Variables[Key]
    : Variables[Key];
} & object;

// Convert unwrap update inputs to real API format for all paths that have been injected by the codegen
// { title: Maybe<string> } -> { title: { value: Maybe<string> } }
export const wrapUpdateInputs = <Variables>(
  v: VariablesWithoutUpdateInputs<Variables> | undefined,
  updateInputsPaths: string[],
) => {
  if (!v) return undefined;
  const wrappedInputs = cloneDeep(v);

  const addWrapperIfDefined = (path: string) => {
    const value = get(v, path, undefined);
    if (value !== undefined) set(wrappedInputs, path, { value });
  };

  const handleArrayPath = (path: string) => {
    const arrayIndex = path.indexOf("[]");
    if (arrayIndex === -1) {
      addWrapperIfDefined(path);
    } else {
      const arrayPath = path.slice(0, arrayIndex);
      const array = get(v, arrayPath, undefined);
      if (!array) return;
      for (let i = 0; i < array.length; i++) {
        handleArrayPath(`${arrayPath}[${i}]${path.slice(arrayIndex + 2)}`);
      }
    }
  };

  for (const updateInputsPath of updateInputsPaths) {
    handleArrayPath(updateInputsPath);
  }
  return wrappedInputs as Variables;
};
