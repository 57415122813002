import gql from "graphql-tag";

import { FormInput } from "components/Form/Input/FormInput";
import { FormModal } from "components/Modal/FormModal";
import {
  CreateWebhook,
  GetWebhooks,
  UpdateWebhook,
  WebhookFragment,
} from "generated/organizationuser";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  # schema = ORGANIZATION_USER
  mutation CreateWebhook($input: CreateWebhookInput!) {
    createWebhook(input: $input) {
      webhook {
        ...Webhook
      }
    }
  }

  mutation UpdateWebhook($uuid: UUID!, $input: UpdateWebhookInput!) {
    updateWebhook(uuid: $uuid, input: $input) {
      webhook {
        ...Webhook
      }
    }
  }
`;

type FormValues = {
  url: string;
};

export const WebhookComposer = ({
  webhook,
  onClose,
}: {
  webhook?: WebhookFragment;
  onClose: () => void;
}) => {
  const t = useTranslation();
  const [createWebhook] = useMutation(CreateWebhook, {
    onSuccess: (output, client) => {
      client.update({
        query: GetWebhooks,
        write: (data) => {
          data.unshift(output.webhook);
        },
      });
      notifier.success(
        t("developers.webhooks.webhook_composer.webhook_created"),
      );
      onClose();
    },
  });
  const [updateWebhook] = useMutation(UpdateWebhook, {
    onSuccess: () => {
      notifier.success(
        t("developers.webhooks.webhook_composer.webhook_updated"),
      );
      onClose();
    },
  });

  return (
    <FormModal<FormValues>
      title={
        webhook
          ? t("developers.webhooks.webhook_composer.edit_the_webhook")
          : t("developers.webhooks.webhook_composer.add_a_new_webhook")
      }
      submitLabel={
        webhook
          ? t("developers.webhooks.webhook_composer.save")
          : t("developers.webhooks.webhook_composer.create")
      }
      onHide={onClose}
      className="flex-col w-full"
      initialValues={{
        url: webhook?.url ?? "",
      }}
      onSubmit={({ url }) =>
        webhook
          ? updateWebhook({ uuid: webhook.uuid, input: { url } })
          : createWebhook({ input: { url } })
      }
    >
      <FormInput
        name="url"
        label={t("developers.webhooks.webhook_composer.url")}
        placeholder="https://example.com/webhook"
        wrapperClassName="flex-fill"
      />
    </FormModal>
  );
};
