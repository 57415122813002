// @ts-ignore
import gql from "graphql-tag";

// @ts-ignore
import { Maybe, Fragment, Query, Mutation } from "base-types";

export type EmptyObject = "EMPTY" | "FUTURE_VALUE";
export type EmptyObjectKnownValue = "EMPTY";
export const EmptyObjectKnownValues: EmptyObjectKnownValue[] = [ "EMPTY" ];



export const possibleTypes: { [key in keyof PossibleTypes]: PossibleTypes[key][] } = {
  "LoginResponse": [
    "LoginResponseSuccess",
    "LoginResponseMfaRequired"
  ],
  "MfaMethod": [
    "TotpMfaMethod",
    "SmsMfaMethod"
  ]
};
export type PossibleTypes = {
  LoginResponse: ("LoginResponseSuccess" | "LoginResponseMfaRequired")
  MfaMethod: ("TotpMfaMethod" | "SmsMfaMethod")
};

export type LoginWithPasswordData = { __typename: "LoginWithPasswordOutput"; loginResponse: ({ __typename: "LoginResponseSuccess"; jwtTokens: { __typename: "JWTTokens"; accessToken: string; refreshToken: string } } | { __typename: "LoginResponseMfaRequired"; mfaState: { __typename: "SetupMfaState"; setupMethods: Array<({ __typename: "TotpMfaMethod"; isSetup: boolean } | { __typename: "SmsMfaMethod"; isSetup: boolean; phone: Maybe<string>; mfaBySmsAntiAbuseToken: string } | { __typename: "FutureValue" })> } } | { __typename: "FutureValue" }) }
export type LoginWithPasswordVariables = { email: EmailAddress; password: string; mfaCode?: Maybe<string> }
export const LoginWithPassword: Mutation<LoginWithPasswordData, LoginWithPasswordVariables, "UNAUTHENTICATED_COPILOT_API_DEVELOPER"> = {
  schemaType: "UNAUTHENTICATED_COPILOT_API_DEVELOPER",
  document: gql`
mutation LoginWithPassword($email: EmailAddress!, $password: String!, $mfaCode: String) {
  loginWithPassword(email: $email, password: $password, mfaCode: $mfaCode) {
    loginResponse {
      ... on LoginResponseSuccess {
        jwtTokens {
          accessToken
          refreshToken
        }
      }
      ... on LoginResponseMfaRequired {
        mfaState {
          setupMethods {
            ... on TotpMfaMethod {
              isSetup
            }
            ... on SmsMfaMethod {
              isSetup
              phone
              mfaBySmsAntiAbuseToken
            }
          }
        }
      }
    }
  }
}

`,
  endpointName: "loginWithPassword",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type LogoutData = { __typename: "LogoutOutput"; _: Maybe<EmptyObject> }
export type LogoutVariables = { refreshJwtToken: string }
export const Logout: Mutation<LogoutData, LogoutVariables, "UNAUTHENTICATED_COPILOT_API_DEVELOPER"> = {
  schemaType: "UNAUTHENTICATED_COPILOT_API_DEVELOPER",
  document: gql`
mutation Logout($refreshJwtToken: String!) {
  logout(refreshJwtToken: $refreshJwtToken) {
    _
  }
}

`,
  endpointName: "logout",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type RefreshData = { __typename: "RefreshOutput"; jwtTokens: { __typename: "JWTTokens"; accessToken: string; refreshToken: string } }
export type RefreshVariables = { refreshJwtToken: string }
export const Refresh: Mutation<RefreshData, RefreshVariables, "UNAUTHENTICATED_COPILOT_API_DEVELOPER"> = {
  schemaType: "UNAUTHENTICATED_COPILOT_API_DEVELOPER",
  document: gql`
mutation Refresh($refreshJwtToken: String!) {
  refresh(refreshJwtToken: $refreshJwtToken) {
    jwtTokens {
      accessToken
      refreshToken
    }
  }
}

`,
  endpointName: "refresh",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}