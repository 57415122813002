import { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";

import { Icon, IconProps } from "./Icon";
import styles from "./clickableIcon.module.css";

type IconDisableType = {
  if: boolean;
  tooltip: string;
  position?: "top" | "bottom" | "left" | "right";
};
export type ClickableIconProps = Omit<IconProps, "onClick" | "to"> &
  (
    | {
        onClick?: ButtonHTMLAttributes<HTMLButtonElement>["onClick"];
        onMouseDown?: ButtonHTMLAttributes<HTMLButtonElement>["onMouseDown"];
        type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
        to?: undefined;
      }
    | {
        onClick?: AnchorHTMLAttributes<HTMLAnchorElement>["onClick"];
        onMouseDown?: AnchorHTMLAttributes<HTMLAnchorElement>["onMouseDown"];
        type?: undefined;
        to: string;
      }
  ) & {
    iconClassName?: string;
    disabled?: boolean | IconDisableType;
  };

export const ClickableIcon = ({
  onClick,
  onMouseDown,
  type = "button",
  to,
  disabled,
  className,
  iconClassName,
  style,
  tabIndex,
  ...rest
}: ClickableIconProps) =>
  !disabled || typeof disabled === "boolean" || !disabled.if ? (
    to === undefined ? (
      <button
        type={type}
        onClick={onClick}
        onMouseDown={onMouseDown}
        className={classNames(styles.button, className)}
        style={style}
        disabled={disabled === true}
        tabIndex={tabIndex}
      >
        <Icon className={iconClassName} {...rest} />
      </button>
    ) : (
      <Link
        to={to}
        onClick={onClick}
        onMouseDown={onMouseDown}
        className={classNames(styles.button, className)}
        style={style}
        tabIndex={tabIndex}
      >
        <Icon className={iconClassName} {...rest} />
      </Link>
    )
  ) : (
    <TooltipWrapper
      className={classNames(styles.button, className)}
      label={disabled.tooltip}
      position={disabled.position}
    >
      <Icon className={classNames("opacity-70", iconClassName)} {...rest} />
    </TooltipWrapper>
  );
