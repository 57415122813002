import classNames from "classnames";

import { Wrapper } from "components/Wrapper/Wrapper";

import styles from "./spinner.module.css";

export const Spinner = ({
  className,
  inline,
  small,
  medium,
  large,
  fill,
}: {
  className?: string;
  inline?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  fill?: boolean;
}) => (
  <Wrapper
    wrapper={(children) =>
      fill ? (
        <div className="h-full w-full flex-center">{children}</div>
      ) : (
        children
      )
    }
  >
    <span
      role="status"
      className={classNames(styles.spinner, className, {
        [styles.center]: !inline,
        [styles.small]: small,
        [styles.medium]: medium,
        [styles.large]: large || (!small && !medium),
      })}
    />
  </Wrapper>
);
