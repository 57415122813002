import { useState } from "react";
import classNames from "classnames";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { useMediaQuery } from "hooks/useMediaQuery";
import { IconName } from "icon-library";

import { BottomActionSheet } from "./BottomActionSheet";
import { separator } from "./MenuItemSeparator";
import { PopoverMenu, PopoverMenuProps } from "./PopoverMenu";

export type MoreMenuProps = Omit<PopoverMenuProps, "children"> & {
  popoverClassname?: string;
  bottomActionSheetOnMobile?: boolean;
  icon?: IconName;
  iconSize?: number;
  disabled?: boolean;
};

export const MoreMenu = ({
  items,
  position = "bottom-right",
  icon = "more",
  iconSize,
  className,
  popoverClassname,
  bottomActionSheetOnMobile,
  disabled,
  ...popoverMenuProps
}: MoreMenuProps) => {
  const useBottomActionSheet =
    // It seems that hover is disabled when puppeteer runs on CI
    useMediaQuery("(any-hover: none)") &&
    !window.E2E &&
    bottomActionSheetOnMobile;
  const [actionSheetOpened, setActionSheetOpened] = useState(false);

  if (items.isEmpty() || items.none((i) => i === separator || !i.hidden)) {
    return null;
  }

  return (
    <>
      <PopoverMenu
        items={items}
        position={position}
        className={popoverClassname}
        {...popoverMenuProps}
      >
        {({ opened, setTarget }) => (
          <ClickableIcon
            name={icon}
            size={iconSize}
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (useBottomActionSheet) {
                setActionSheetOpened(true);
              } else {
                setTarget(e);
              }
            }}
            // To compensate invisible group-hover:visible
            style={{ visibility: opened ? "visible" : undefined }}
            className={classNames(className, { "text-primary": opened })}
          />
        )}
      </PopoverMenu>
      {useBottomActionSheet && actionSheetOpened && (
        <BottomActionSheet
          items={items}
          close={() => setActionSheetOpened(false)}
        />
      )}
    </>
  );
};
