import { useCallback } from "react";
import { Operation } from "@apollo/client";

import { GqlSchemaAuthenticationKind, GRAPHQL_ENDPOINTS } from "api/endpoints";
import {
  AUTHENTICATION_KIND_IS_AUTHENTICATED,
  RequestContext,
  UnauthenticatedAuthenticationKind,
} from "api/types";
import { useGetAuthenticatedRequestContextOrThrow } from "api/utils";
import { SchemaType } from "base-types";

// Since it would be cumbersome to have to pass a `RequestContext` explicitly
// on every call to `useQuery` or `useMutation`, we derive a `RequestContext`
// automatically for queries and mutations to authenticated schemas (where we
// can do so unambiguously).
export type RequestContextOptions<Schema extends SchemaType> =
  GqlSchemaAuthenticationKind<Schema> extends UnauthenticatedAuthenticationKind
    ? { requestContext: RequestContext<GqlSchemaAuthenticationKind<Schema>> }
    : { requestContext?: undefined };

// Returns a helper which builds a `RequestContext` for GraphQL operations.
// - By deriving it from the `AuthContext` for authenticated schemas.
// - By retrieving it from the Apollo context for unauthenticated schemas.
//
// Re-renders on changes to the `AuthContext`.
export const useGetRequestContextOrThrow = <T extends SchemaType>(
  schemaType: T,
): ((
  operation: Operation,
) => RequestContext<GqlSchemaAuthenticationKind<T>>) => {
  const authenticationKind: GqlSchemaAuthenticationKind<T> =
    GRAPHQL_ENDPOINTS[schemaType].authenticationKind;
  const getAuthenticatedRequestContextOrThrow =
    useGetAuthenticatedRequestContextOrThrow(authenticationKind);

  return useCallback(
    (operation: Operation) =>
      isAuthenticatedSchema(schemaType)
        ? getAuthenticatedRequestContextOrThrow()
        : (operation.getContext() as RequestContextOptions<T>).requestContext!,
    [getAuthenticatedRequestContextOrThrow, schemaType],
  );
};

const isAuthenticatedSchema = (schemaType: SchemaType) =>
  AUTHENTICATION_KIND_IS_AUTHENTICATED[
    GRAPHQL_ENDPOINTS[schemaType].authenticationKind
  ];
