import { ReactElement, ReactNode } from "react";
import gql from "graphql-tag";

import { IdentityType } from "auth/AuthContext";
import { Query } from "components/Query/Query";
import {
  CurrentCopilotApiDeveloper,
  CurrentCopilotApiDeveloperData,
  SupportedLocale,
} from "generated/copilot-api-developer";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useSetLocaleAndTimezone } from "hooks/useSetLocaleAndTimezone";

import { UserContext } from "./UserContext";

gql`
  # schema = COPILOT_API_DEVELOPER
  query CurrentCopilotApiDeveloper {
    me {
      developer {
        ...CopilotApiDeveloperSummary
        permissions

        subOrganization {
          uuid
          displayName
          timezone

          organization {
            uuid
            stringId
            displayName
          }
        }
      }
    }
  }

  fragment CopilotApiDeveloperSummary on CopilotApiDeveloper {
    uuid
    email
    locale
    timezone
  }
`;

// TODO(@liautaud): This should not live this high in the component tree, but
//  the `AutomaticIdentitySwitcher` needs to live relatively high and needs to
//  access the organization and sub-organization of the current identity.
export const UserProvider = ({
  type,
  children,
}: {
  type: IdentityType | undefined;
  children: ReactElement;
}) => {
  switch (type) {
    case undefined:
      return children;
    case "COPILOT_API_DEVELOPER":
      return (
        // @graphql-schema-usage-ignore: Copilot API users always have access to COPILOT_API_DEVELOPER.
        <Query query={CurrentCopilotApiDeveloper}>
          {({ developer }) => (
            <CopilotApiDeveloperProvider
              developer={developer}
              children={children}
            />
          )}
        </Query>
      );
  }
};

const CopilotApiDeveloperProvider = ({
  developer,
  children,
}: {
  developer: CurrentCopilotApiDeveloperData["developer"];
  children: ReactNode;
}) => {
  useApplyPreferences(developer.locale, developer.timezone);

  return (
    <UserContext.Provider
      value={{
        type: "COPILOT_API_DEVELOPER",
        user: developer,
        uuid: developer.uuid,
        email: developer.email,
        isDesktop: useIsDesktop(),
        hasPermission: (permission) =>
          developer.permissions.some((p) => p === permission),
        hasAccessToOrganizationUserApi: true,
        timezone: developer.timezone,
        subOrganizationTimezone: developer.subOrganization.timezone,
        organizationStringId: developer.subOrganization.organization.stringId,
        subOrganizationUuid: developer.subOrganization.uuid,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useApplyPreferences = (locale: SupportedLocale, timezone: string) => {
  useSetLocaleAndTimezone(locale, timezone);
};
