import { useEffect, useRef } from "react";

export const useSyncRef = <T>(value: T) => {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref;
};

export const useSyncRefNoDelay = <T>(value: T) => {
  const ref = useRef<T>(value);
  ref.current = value;
  return ref;
};
