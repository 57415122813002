export const isKnownValue = <T extends readonly string[]>(
  value: string | null | undefined,
  knownValues: T,
): value is T[number] => (value ? knownValues.includes(value) : false);

export const getKnownValue = <T extends readonly string[]>(
  value: string | null | undefined,
  knownValues: T,
) => (isKnownValue(value, knownValues) ? value : null);

export const getKnownValueFilter =
  <T extends readonly string[]>(knownValues: T) =>
  (value: string) =>
    getKnownValue(value, knownValues);

export const mapEnumValue = <E extends string, T>(
  value: "default" extends E ? never : E | "FUTURE_VALUE",
  labels: { [key in E | "default"]: T },
): T => {
  if (Object.keys(labels).includes(value)) {
    // @ts-ignore
    return labels[value];
  }
  return labels.default;
};

export const mapEnumValueOrThrow = <E extends string, T>(
  value: E | "FUTURE_VALUE",
  labels: { [key in E]: T },
): T => {
  if (Object.keys(labels).includes(value)) {
    // @ts-ignore
    return labels[value];
  }
  throw new Error(`Unexpected value ${value}`);
};
