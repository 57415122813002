import { useEffect } from "react";

import { SupportedLocale } from "generated/account";
import { getLanguageFromLocale, setLanguage } from "utils/intl";
import { setTimezone } from "utils/timezone";

import { useRerender } from "./useRerender";

export const useSetLocaleAndTimezone = (
  locale: SupportedLocale,
  timezone: TimeZone,
) => {
  const rerender = useRerender();
  useEffect(() => {
    setLanguage(getLanguageFromLocale(locale));
    rerender();
  }, [rerender, locale]);

  useEffect(() => {
    setTimezone(timezone);
  }, [timezone]);
};
