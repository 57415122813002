import classNames from "classnames";

import { useField } from "hooks/useField";

import { useFormExtraContext } from "../Form/FormExtraContext";
import { LabelWrapper, NamedLabelWrapperProps } from "../Label/LabelWrapper";
import { TextArea, TextAreaProps } from "./TextArea";

export const FormTextArea = ({
  wrapperClassName,
  label,
  hint,

  name,
  className,
  disabled,
  onBlur,
  onChange,
  required,
  ...props
}: Omit<TextAreaProps & NamedLabelWrapperProps, "value" | "error">) => {
  const [
    { onBlur: formikOnBlur, onChange: formikOnChange, ...otherFieldProps },
    { error },
  ] = useField({ name, disabled });
  const isRequired = useFormExtraContext().requiredKeys.includes(name);

  return (
    <LabelWrapper
      wrapperClassName={wrapperClassName}
      label={required && label && !label.endsWith("*") ? `${label}*` : label}
      error={error}
      hint={hint}
      name={name}
    >
      <TextArea
        onBlur={(e) => {
          onBlur?.(e);
          formikOnBlur(e);
        }}
        onChange={(e) => {
          onChange?.(e);
          formikOnChange(e);
        }}
        className={classNames(className, { "border-danger": error })}
        required={required ?? isRequired}
        {...otherFieldProps}
        {...props}
      />
    </LabelWrapper>
  );
};
