import { useState } from "react";

import {
  AccountPasswordLoginForm,
  AccountPasswordLoginFormState,
} from "auth/components/AccountPasswordLoginForm";
import { QuickLogin } from "auth/components/QuickLogin";
import { EnvironmentBanner } from "components/EnvironmentBanner/EnvironmentBanner";
import { PublicScreenWrapper } from "components/PublicScreenWrapper/PublicScreenWrapper";
import { useTranslation } from "i18n";

export const Login = ({
  showPasswordLogin = true,
}: {
  showPasswordLogin?: boolean;
}) => {
  const t = useTranslation();
  const [passwordLoginFormState, setPasswordLoginFormState] =
    useState<AccountPasswordLoginFormState>({ step: "CREDENTIALS_FORM" });

  // Hide the rest of the layout if the password login form is past the initial step.
  if (showPasswordLogin && passwordLoginFormState.step !== "CREDENTIALS_FORM") {
    return (
      <PublicScreenWrapper>
        <EnvironmentBanner />

        <div className="flex-col w-full max-w-400">
          <AccountPasswordLoginForm
            state={passwordLoginFormState}
            onStateChange={setPasswordLoginFormState}
          />
        </div>
      </PublicScreenWrapper>
    );
  }

  return (
    <PublicScreenWrapper>
      <EnvironmentBanner />

      <div className="flex-col w-full max-w-400">
        <h1 className="title mb-40">{t("login.title")}</h1>

        <AccountPasswordLoginForm
          state={passwordLoginFormState}
          onStateChange={setPasswordLoginFormState}
        />

        {process.env.VITE_NABLA_USER && (
          <>
            <p className="my-22 text-center">{t("login.or")}</p>
            <QuickLogin />
          </>
        )}
      </div>
    </PublicScreenWrapper>
  );
};
