import { CSSProperties, ReactNode, useRef, useState } from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";

import { useOn } from "hooks/useOn";

import { Tooltip } from "./Tooltip";
import styles from "./tooltip.module.css";

export type TooltipWrapperProps = {
  position?: "top" | "bottom" | "left" | "right";
  label: string | JSX.Element;
  show?: boolean;
  className?: string;
  tooltipClassName?: string;
  tooltipStyle?: CSSProperties;
  children?: ReactNode;

  style?: CSSProperties;
};

export const TooltipWrapper = ({
  position = "bottom",
  label,
  show = true,
  className,
  tooltipClassName,
  tooltipStyle,
  children,
  style,
}: TooltipWrapperProps) => {
  // We can't simply put the tooltip next to the children in the DOM, because
  // the tooltip might be cut by parents with overflow: hidden. Instead, we
  // wrap the children in a "hit-box" placeholder which we use to compute the
  // absolute coordinates of the hover hit-box, and then we render the tooltip
  // with these coordinates in a React portal.
  const hitboxRef = useRef<HTMLDivElement | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<AbsolutePosition>();

  const hideTooltip = () => setTooltipPosition(undefined);
  const showTooltip = () => {
    if (!hitboxRef.current) return;
    setTooltipPosition(getAbsolutePosition(hitboxRef.current));
  };

  useOn("scroll", hideTooltip, true);

  return (
    <>
      {show &&
        tooltipPosition &&
        ReactDOM.createPortal(
          <div
            className={classNames(
              "flex-center absolute z-tooltip pointer-events-none",
              className,
            )}
            style={tooltipPosition}
          >
            <Tooltip
              className={tooltipClassName}
              style={tooltipStyle}
              position={position}
              label={label}
            />
          </div>,
          document.getElementById("tooltip-root")!,
        )}

      <div
        ref={hitboxRef}
        className={classNames(styles.tooltipWrapper, className)}
        style={style}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
      >
        {children}
      </div>
    </>
  );
};

type AbsolutePosition = {
  top: number;
  left: number;
  width: number;
  height: number;
};

const getAbsolutePosition = (element: Element): AbsolutePosition => {
  const { top, left, width, height } = element.getBoundingClientRect();
  return {
    top: top + window.scrollY,
    left: left + window.scrollX,
    width,
    height,
  };
};
