import { useState } from "react";
import gql from "graphql-tag";
import { useSearchParams } from "react-router-dom";

import { Button } from "components/Button/Button";
import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormInput } from "components/Form/Input/FormInput";
import { PublicScreenWrapper } from "components/PublicScreenWrapper/PublicScreenWrapper";
import { StartAccountPasswordReset } from "generated/unauth-account";
import { useMutation } from "graphql-client/useMutation";
import { useGoBack } from "hooks";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { routes } from "routes";

gql`
  # schema = UNAUTHENTICATED_ACCOUNT
  mutation StartAccountPasswordReset($email: String!) {
    startUserInitiatedAccountPasswordReset(email: $email) {
      _
    }
  }
`;

export const ForgotPassword = () => {
  const t = useTranslation();
  const [searchParams] = useSearchParams();
  const isDesktop = useIsDesktop();
  const goBack = useGoBack(false);

  const [showConfirmationScreen, setShowConfirmationScreen] = useState(false);
  const [startAccountPasswordReset] = useMutation(StartAccountPasswordReset);

  return (
    <PublicScreenWrapper>
      {showConfirmationScreen ? (
        <div className="flex-col w-full max-w-400">
          <h1 className="title mb-14">
            {t("forgot_password.confirmation.title")}
          </h1>
          <div className="text-16 text-center mb-40 leading-normal">
            {t("forgot_password.confirmation.subtitle")}
          </div>

          <Button
            large
            secondary
            to={routes.LOGIN}
            label={t("forgot_password.back_to_login")}
          />
        </div>
      ) : (
        <Form<{ email: string }>
          className="flex-col w-full max-w-400"
          initialValues={{ email: searchParams.get("email") ?? "" }}
          validationSchema={{ email: "requiredEmail" }}
          onSubmit={async ({ email }) => {
            const data = await startAccountPasswordReset(
              { email },
              { requestContext: { regionByAccountEmail: email } },
            );
            if (data) setShowConfirmationScreen(true);
          }}
        >
          <h1 className="title mb-16">{t("forgot_password.title")}</h1>
          <div className="text-16 text-center mb-40 leading-normal">
            {t("forgot_password.subtitle")}
          </div>

          <FormInput
            autoFocus={isDesktop}
            name="email"
            type="email"
            label={t("forgot_password.forgot_password.email_address")}
            placeholder={t("forgot_password.email_placeholder")}
          />

          <Submit
            className="mt-24"
            large
            label={t("forgot_password.forgot_password.reinitialise_password")}
          />

          <button
            className="mt-18 text-primary text-center hover:opacity-70"
            onClick={() => goBack(routes.LOGIN)}
          >
            {t("forgot_password.back_to_login")}
          </button>
        </Form>
      )}
    </PublicScreenWrapper>
  );
};
