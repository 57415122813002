import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormPasswordInputWithValidation } from "components/Form/Input/FormPasswordInputWithValidation";
import { FormPasswordInput } from "components/Form/Input/PasswordInput";
import { useTranslation } from "i18n";

type FormValues = {
  password: string;
  passwordConfirmation: string;
};

export const ChoosePasswordForm = ({
  initialPassword,
  onSubmit,
}: {
  initialPassword?: string;
  onSubmit: (password: string) => Promise<void>;
}) => {
  const t = useTranslation();

  return (
    <Form<FormValues>
      initialValues={{
        password: initialPassword ?? "",
        passwordConfirmation: initialPassword ?? "",
      }}
      className="flex-col"
      validationSchema={{
        password: "required",
        passwordConfirmation: "required",
      }}
      validate={({ password, passwordConfirmation }) => {
        if (password !== passwordConfirmation) {
          return {
            passwordConfirmation: t("choose_credentials.password.mismatched"),
          };
        }
      }}
      onSubmit={({ password }) => onSubmit(password)}
    >
      <FormPasswordInputWithValidation
        name="password"
        label={t("choose_credentials.password.label")}
        placeholder={t("choose_credentials.password.characters_minimum")}
        autoComplete="new-password"
      />

      <FormPasswordInput
        name="passwordConfirmation"
        label={t("choose_credentials.password.confirmation_label")}
        autoComplete="new-password"
        placeholder="Confirmation..."
        wrapperClassName="mt-24"
      />

      <Submit
        large
        label={t("choose_credentials.submit_label")}
        className="mt-28"
      />
    </Form>
  );
};
