import { useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { useAuth } from "auth/AuthContext";
import { EnvironmentBanner } from "components/EnvironmentBanner/EnvironmentBanner";
import { PublicScreenWrapper } from "components/PublicScreenWrapper/PublicScreenWrapper";
import { Spinner } from "components/Spinner/Spinner";

// The browser will be redirected to this page after a successful Google login
// flow or Google authorization flow.
export const AfterGoogleLogin = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { completeGoogleLoginOrAuthorization } = useAuth();
  const startedRunning = useRef(false);

  const code = searchParams.get("code");
  const state = searchParams.get("state");

  useEffect(() => {
    // Execute exactly once, even in development with React strict mode.
    if (startedRunning.current) return;
    startedRunning.current = true;

    // Errors are already notified by completeAccountLoginWithGoogle.
    completeGoogleLoginOrAuthorization({ code, state }).then(
      ({ redirectPath }) => {
        navigate(redirectPath ?? "/");
      },
    );
  }, [completeGoogleLoginOrAuthorization, code, state, navigate]);

  return (
    <PublicScreenWrapper>
      <EnvironmentBanner />
      <Spinner />
    </PublicScreenWrapper>
  );
};
