import { useEffect, useState } from "react";

import { useRerender } from "./useRerender";

const mediaQueries = new Map<string, MediaQueryList>();

export const useMediaQuery = (query: string) => {
  const rerender = useRerender();
  const [mediaQuery] = useState(() => {
    const existingMediaQuery = mediaQueries.get(query);
    if (existingMediaQuery) return existingMediaQuery;
    const newMediaQuery = window.matchMedia(query);
    mediaQueries.set(query, newMediaQuery);
    return newMediaQuery;
  });

  useEffect(() => {
    mediaQuery.addEventListener("change", rerender);
    return () => {
      mediaQuery.removeEventListener("change", rerender);
    };
  }, [mediaQuery, rerender]);

  return mediaQuery.matches;
};

export const useIsDesktop = () => useMediaQuery("(min-width: 1024px)");
export const useIsPWA = () => useMediaQuery("(display-mode: standalone)");
