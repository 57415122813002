import { useEffect } from "react";
import { To } from "history";
import { useNavigate } from "react-router";

export const Redirect = <State extends object | null = null>({
  to,
  state,
}: {
  to: To;
  state?: State;
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to, { state, replace: true });

    // we pass an empty array of dependencies to ensure that
    // we only run the effect once after initial render
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};
