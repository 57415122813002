import { useEffect } from "react";
import * as Sentry from "@sentry/browser";

import { useUser } from "contexts/User/UserContext";
import versions from "versions.json";

export const useConfigureSentryScope = () => {
  const { uuid, email } = useUser();

  useEffect(() => {
    Sentry.configureScope((scope) => {
      scope.setUser({ id: uuid ?? undefined, email: email ?? undefined });
    });
    Sentry.setTag("version", versions.code_version.toString());
  }, [uuid, email]);
};
