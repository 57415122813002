/* eslint-disable */
/*
  This is file is not linted and should be used only for small functions with a
  specific task found over the internet and which rely on low level JS operators.
*/

// https://stackoverflow.com/a/2117523/1934675
// This function is useful to create temporary unique values.
// It is not as random as other sources of UUIDs, and is not meant to be persisted to a database
export const ephemeralUuidV4 = (): string =>
  // @ts-ignore
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );

export const stringHash = (seed: string) => {
  let hash = 0;
  if (seed.length === 0) return hash;
  for (let i = 0; i < seed.length; i++) {
    const chr = seed.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
