import { z } from "zod";

import { notifier } from "utils/notifier";
import { ephemeralUuidV4 } from "utils/stackoverflow";

// Data needed to create an organization and its initial identity.
const organizationCreationPayloadSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("COPILOT_API_ORGANIZATION"),
    organization: z.object({
      displayName: z.string(),
      locale: z.literal("ENGLISH"), // always english
      timezone: z.string(),
      attributionData: z.string().nullable(),
    }),
    initialCopilotApiIdentity: z.object({}),
  }),
]);

export type OrganizationCreationPayload = z.infer<
  typeof organizationCreationPayloadSchema
>;

// ----- Scheduled organization creation.
//
// The mutation to create a new organization is on the `account` schema, but
// we sometimes want to create organizations in contexts where the user is
// not yet logged in as an account but is about to (for instance, right after
// picking new account credentials following a signup).
//
// To simplify this process, we offer a way to schedule an organization creation
// mutation to be executed later when the user is logged into the right account.

const SCHEDULED_ORGANIZATION_CREATION_PAYLOAD_KEY =
  "scheduled-organization-creation-payload-v2";

const scheduledOrganizationCreationPayloadKeyForEmail = (
  accountEmail: string,
) => `${SCHEDULED_ORGANIZATION_CREATION_PAYLOAD_KEY}:${accountEmail}`;

export const scheduleOrganizationCreation = (
  accountEmail: string,
  payload: OrganizationCreationPayload,
) => {
  const key = scheduledOrganizationCreationPayloadKeyForEmail(accountEmail);
  return navigator.locks.request(key, () => {
    storage.setItem(key, JSON.stringify(payload));
  });
};

// Pops the latest stored payload for the given email from storage, and executes
// `callback` with it. We use a lock to avoid races between tabs. Specifically,
// only one tab can create an organization at a given time–other tabs trying to
// call this function or `scheduleOrganizationCreation` while the first tab is
// creating an organization will wait until the first tab completes.
export const executeScheduledOrganizationCreation = (
  accountEmail: string,
  callback: (payload: OrganizationCreationPayload) => Promise<void>,
) => {
  const key = scheduledOrganizationCreationPayloadKeyForEmail(accountEmail);
  return navigator.locks.request(key, async () => {
    const rawPayload = storage.getItem(key);
    storage.removeItem(key);

    if (!rawPayload) return;
    const parsedPayload = organizationCreationPayloadSchema.safeParse(
      JSON.parse(rawPayload),
    );
    if (!parsedPayload.success) return;

    await callback(parsedPayload.data);
  });
};

// ----- Front-end data.
//
// The signup form contains enough information to both:
// 1. create the user's Nabla account if needed;
// 2. create the organization and its initial identity.
//
// However, we only want to create the organization and its initial identity
// *after* the user has picked credentials for their account by clicking on the
// link in the "New account" email and filling up the "choose credentials" form,
// so we need a way to forward the organization creation information from the
// signup form to the "choose credentials" form.
//
// This is done via a JSON-serialized payload called `frontendData`, which is
// sent to the backend when creating the account and which we can retrieve in
// the "choose credentials" form.

const frontendDataSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("ORGANIZATION_CREATION"),
    data: organizationCreationPayloadSchema,
  }),
]);
type FrontendData = z.infer<typeof frontendDataSchema>;

export const buildOrganizationCreationFrontendData = (
  data: OrganizationCreationPayload,
): string => {
  const frontendData: FrontendData = { type: "ORGANIZATION_CREATION", data };
  return JSON.stringify(frontendDataSchema.parse(frontendData));
};

export const parseOrganizationCreationFrontendData = (
  data: string,
): OrganizationCreationPayload | undefined => {
  const parsedData = frontendDataSchema.safeParse(JSON.parse(data));
  if (!parsedData.success) {
    notifier.error({
      sentry: {
        message: `Invalid organization creation frontend data: ${data}.`,
      },
    });
    return;
  }
  return parsedData.data.data;
};

const ORGANIZATION_STRING_ID_MAX_LENGTH = 50;

export const deriveProbablyUniqueOrganizationStringId = (
  organizationName: string,
) =>
  `${removeAllButAlphanumeric(organizationName).slice(
    0,
    ORGANIZATION_STRING_ID_MAX_LENGTH - 8,
  )}-${ephemeralUuidV4().slice(-7)}`;

const removeAllButAlphanumeric = (str: string) =>
  str
    .toLowerCase()
    .replaceAll(/[^a-z0-9]/gu, "")
    .trim();
