import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { DEBUG_MODE_KEY } from "consts";
import { Serializable } from "types";

import { useOn } from "./useOn";

const eventType = (key: string) => `storage-${key}`;

export const useStorageState = <T extends Serializable>(
  key: string,
  initialValue: T | (() => T),
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setLocalState] = useState<T>(() => {
    const current = storage.getItem(key);
    return current
      ? JSON.parse(current)
      : typeof initialValue === "function"
      ? initialValue()
      : initialValue;
  });

  // Listen to state changes from other pages (via LocalStorage)
  useOn("storage", (ev) => {
    if (key !== ev.key) return;
    setLocalState(ev.newValue ? JSON.parse(ev.newValue) : initialValue);
  });

  // Listen to state changes from other components on the same page (via window events)
  useEffect(() => {
    const listener = (ev: any) => setLocalState(ev.detail);
    window.addEventListener(eventType(key), listener);
    return () => window.removeEventListener(eventType(key), listener);
  }, [key]);

  return [
    state,
    useCallback(
      (valueOrCallback) => {
        setLocalState((currentValue) => {
          const newValue =
            typeof valueOrCallback === "function"
              ? valueOrCallback(currentValue)
              : valueOrCallback;

          // Set the local value and broadcast changes to other components.
          const newValueSerialized = JSON.stringify(newValue);
          if (JSON.stringify(currentValue) !== newValueSerialized) {
            storage.setItem(key, newValueSerialized);
            window.dispatchEvent(
              new CustomEvent(eventType(key), { detail: newValue }),
            );
          }

          return newValue;
        });
      },
      [key],
    ),
  ];
};

export const useDebugMode = () =>
  useStorageState<boolean>(DEBUG_MODE_KEY, false);

export const useIsOnboardingModalVisible = () =>
  useStorageState<boolean>("onboarding-modal-visible", true);
