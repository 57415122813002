import { FieldValidator } from "formik/dist/types";
import { isValidPhoneNumber } from "react-phone-number-input";

import { useField } from "hooks/useField";
import { useTranslation } from "i18n";

import { useFormExtraContext } from "../Form/FormExtraContext";
import { PhoneInput, PhoneInputProps } from "./PhoneInput";

export type FormPhoneInputProps = Omit<PhoneInputProps, "value" | "onChange"> &
  Partial<Pick<PhoneInputProps, "onChange">>;

export const FormPhoneInput = ({
  name,
  disabled,
  onBlur,
  onChange,
  ...props
}: FormPhoneInputProps) => {
  const t = useTranslation();

  const validate: FieldValidator = (value) => {
    if (value && !isValidPhoneNumber(value)) return t("phone_input.invalid");
    return undefined;
  };
  const [
    { onBlur: formikOnBlur, onChange: formikOnChange, ...otherFieldProps },
    { error },
  ] = useField({ name, disabled, validate });
  const isRequired = useFormExtraContext().requiredKeys.includes(name);

  return (
    <PhoneInput
      error={error}
      onBlur={(e) => {
        onBlur?.(e);
        formikOnBlur(e);
      }}
      onChange={(e) => {
        onChange?.(e);
        formikOnChange({ target: { name, value: e } });
      }}
      required={props.required ?? isRequired}
      {...otherFieldProps}
      {...props}
    />
  );
};
