import { getSafeArea } from "utils/environment";

export const BottomSafeArea = () => {
  const height = getSafeArea("bottom");
  if (!height) return null;
  return <div className="w-full" style={{ height }} />;
};

export const TopSafeArea = () => {
  const height = getSafeArea("top");
  if (!height) return null;
  return <div className="w-full" style={{ height }} />;
};
