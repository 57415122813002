import { Icon, IconProps } from "components/Icon/Icon";
import { IconName } from "icon-library";
import { Exclusive } from "types";

type OrganizationLogoProps = Exclusive<
  { fixedTitle: string } | { fixedIcon: IconName | IconProps }
>;

export const OrganizationLogo = ({
  fixedTitle,
  fixedIcon,
}: OrganizationLogoProps) => (
  <div className="bg-jagged-ice text-white text-primary-dark flex-center text-18 h-44 w-44 rounded">
    <div className="overflow-hidden">
      {fixedIcon ? (
        typeof fixedIcon === "string" ? (
          <Icon size={26} name={fixedIcon} />
        ) : (
          <Icon size={26} {...fixedIcon} />
        )
      ) : (
        fixedTitle
      )}
    </div>
  </div>
);
