import { useState } from "react";
import gql from "graphql-tag";

import { Select } from "components/Form/Select/Select";
import { ControlledConfirmationModalProps } from "components/Modal/ControlledConfirmationModal";
import { ControlledDeletionConfirmationModal } from "components/Modal/DeletionConfirmationModal";
import {
  GetAPIVersions,
  GetOrganizationAPIVersion,
  UpdateOrganizationAPIVersion,
} from "generated/organizationuser";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

import { AppFlavor } from "../../platform";

gql`
  # schema = ORGANIZATION_USER

  query GetAPIVersions {
    apiVersions {
      versions
    }
  }

  query GetOrganizationAPIVersion {
    myOrganization {
      uuid
      pinnedVersion
    }
  }

  mutation UpdateOrganizationAPIVersion($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        uuid
        pinnedVersion
      }
    }
  }
`;

type ConfirmationModalState =
  | Omit<ControlledConfirmationModalProps, "onHide" | "cta" | "children">
  | undefined;

export const APIVersionSelector = ({ appFlavor }: { appFlavor: AppFlavor }) => {
  const t = useTranslation();
  const { data: apiVersions } = useQuery(GetAPIVersions);
  const { data: organization } = useQuery(GetOrganizationAPIVersion);
  const [updateOrganizationSettings] = useMutation(
    UpdateOrganizationAPIVersion,
  );
  const pinnedVersion = organization?.pinnedVersion;
  // TODO(@achraf) Move this product-bases filtering to be server-side
  const firstCopilotApiVersion = "2023-06-16";
  const filteredApiVersion = apiVersions
    ? appFlavor === "CARE_PLATFORM"
      ? apiVersions.versions
      : apiVersions.versions.filter((v) => v >= firstCopilotApiVersion)
    : [];

  const [versionConfirmationModalState, setVersionConfirmationModalState] =
    useState<ConfirmationModalState>();

  return (
    <>
      {versionConfirmationModalState && (
        <ControlledDeletionConfirmationModal
          title={t("server_keys.update_api_version")}
          suffix={t("server_keys.to_update_the_api_version")}
          {...versionConfirmationModalState}
          onHide={() => setVersionConfirmationModalState(undefined)}
          ctaLabel={t("server_keys.cta.update")}
        />
      )}
      {pinnedVersion && (
        <>
          <div>
            {t("developers.server_keys.server_keys.api_version")}{" "}
            <a
              href={
                appFlavor === "CARE_PLATFORM"
                  ? "https://cp-docs.nabla.com/reference/versioning#default-api-version"
                  : "https://docs.nabla.com/guides/api-versioning/upgrades"
              }
              target="_blank"
              className="text-primary"
              rel="noreferrer"
            >
              ({t("developers.server_keys.server_keys.doc_link")})
            </a>
            {t("developers.server_keys.server_keys.colon")}
            <Select
              name="apiVersion"
              options={filteredApiVersion}
              wrapperClassName="w-[130px] inline-block"
              value={pinnedVersion}
              onChange={(label) => {
                setVersionConfirmationModalState({
                  onConfirm: async (close: () => void) => {
                    await updateOrganizationSettings(
                      { input: { apiVersion: label } },
                      {
                        onSuccess: () => {
                          setVersionConfirmationModalState(undefined);
                          notifier.success(
                            t("server_keys.organization_api_version_updated"),
                          );
                        },
                      },
                    );
                    close();
                  },
                });
              }}
            />
          </div>
          <div className="mb-16 mt-8">
            {t("developers.server_keys.server_keys.custom_api_version")}{" "}
            <a
              href={
                appFlavor === "CARE_PLATFORM"
                  ? "https://docs.nabla.com/reference/versioning#manually-set-the-api-version"
                  : "https://docs.nabla.com/guides/api-versioning/usage#manually-set-the-api-version"
              }
              target="_blank"
              className="text-primary"
              rel="noreferrer"
            >
              {t("developers.server_keys.server_keys.header_link")}
            </a>
          </div>
        </>
      )}
    </>
  );
};
