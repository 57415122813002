declare global {
  interface Array<T> {
    /**
     * Relative indexing method with nullable type
     */
    at(this: T[], index: number): T | undefined;

    /**
     * Immutable version of {@link Array.sort}
     */
    immutableSort(this: T[], compareFn?: (a: T, b: T) => number): T[];

    /**
     * Shortcut for {@link Array.immutableSort}
     * - sortAsc((fn) => number) === immutableSort((a, b) => fn(a) - fn(b))
     * - sortAsc((fn) => string) === immutableSort((a, b) => fn(a).localeCompare(fn(b)))
     */
    sortAsc(
      this: T[],
      getValue: ((el: T) => number) | ((el: T) => string),
    ): T[];

    /**
     * Shortcut for {@link Array.immutableSort}
     * - sortDesc((fn) => number) === immutableSort((a, b) => fn(b) - fn(a))
     * - sortDesc((fn) => string) === immutableSort((a, b) => fn(b).localeCompare(fn(a)))
     */
    sortDesc(
      this: T[],
      getValue: ((el: T) => number) | ((el: T) => string),
    ): T[];

    /**
     * Shortcut for {@link Array.filter}.length
     */
    count(
      this: T[],
      predicate: (value: T, index: number, array: T[]) => unknown,
    ): number;

    /**
     * Typed shortcut of {@link Array.filter} for removing undefined and null
     * filterNotNull() === filter((el) => el !== undefined && el !== null)
     */
    filterNotNull(this: T[]): NonNullable<T>[];

    /**
     * Typed shortcut of {@link Array.map} and {@link Array.filterNotNull}
     * mapNotNull(predicate) === map(predicate).filterNotNull()
     */
    mapNotNull<U>(
      this: T[],
      callbackFn: (value: T, index: number, array: T[]) => U | null | undefined,
    ): U[];

    /**
     * Typed shortcut of {@link Array.flatMap} and {@link Array.filterNotNull}
     * flatMapNotNull(predicate) === flatMap(predicate).filterNotNull()
     */
    flatMapNotNull<U>(
      this: T[],
      callbackFn: (
        value: T,
        index: number,
        array: T[],
      ) => U | null | undefined | (U | null | undefined)[],
    ): U[];

    /**
     * Shortcut for comparing {@link Array.length} to 0
     */
    isEmpty(this: T[]): boolean;

    /**
     * Shortcut for comparing {@link Array.length} to 0
     */
    isNotEmpty(this: T[]): boolean;

    /**
     * Enforces that [this] is not empty.
     */
    asNotEmpty(this: T[]): [T, ...T[]];

    /**
     * Returns a list containing only distinct (by reference) elements
     * Keeps the first in the array among duplicates
     */
    distinct(this: T[]): T[];

    /**
     * Returns a list containing only elements having distinct (by reference)
     * results for the selector function
     * Keeps the first in the array among duplicates
     */
    distinctBy(this: T[], selector: (value: T) => unknown): T[];

    /**
     * Splits the original array into a pair of arrays, where the first array
     * contains elements for which `predicate` returned true, while the second
     * array contains elements for which `predicate` returned false.
     */
    partition(this: T[], predicate: (value: T) => boolean): [T[], T[]];

    /**
     * Splits the original array into a pair of arrays, where the first array
     * contains the n first elements and the second contains the rest.
     */
    split(this: T[], n: number): [T[], T[]];

    /**
     * GroupBy, the good stuff.
     */
    groupBy(this: T[], selector: (value: T) => string): Record<string, T[]>;

    /**
     * GroupBy, the good stuff, even gooder.
     */
    groupByToEntries<Comparable>(
      this: T[],
      selector: (value: T) => Comparable,
    ): [Comparable, T[]][];

    /**
     * Same as {@link Array.findIndex}, but returns undefined instead of -1
     * when there is no match
     */
    findIndexOrUndefined(
      this: T[],
      predicate: (value: T, index: number, array: readonly T[]) => unknown,
    ): number | undefined;

    /**
     * Same as {@link Array.findIndexOrUndefined}, but starting from the end
     */
    findLastIndex(
      this: T[],
      predicate: (value: T, index: number, array: readonly T[]) => unknown,
    ): number | undefined;

    /**
     * Same as {@link Array.find}, but starting from the end
     */
    findLast(
      this: T[],
      predicate: (value: T, index: number, array: readonly T[]) => unknown,
    ): T | undefined;

    /**
     * Removes the element is present, otherwise adds it
     */
    toggle(this: T[], element: T, selector?: (value: T) => unknown): T[];

    /**
     * If first argument is true, uses {@link Array.concat} with the following arguments
     */
    concatIf(
      this: T[],
      condition: boolean | undefined | null,
      ...items: (T | ConcatArray<T>)[]
    ): T[];

    /**
     * Returns whether the elements are two arrays are equal.
     */
    equals(this: T[], other: T[]): boolean;

    /**
     * Returns true if no element matches the predicate.
     */
    none(
      this: T[],
      predicate: (value: T, index: number, array: T[]) => unknown,
    ): boolean;

    /**
     * An immutable version of {@link Array.splice}(index, 0, ...items)
     * Out of bound index fallback to 0 or this.length
     */
    insert(this: T[], index: number, ...items: T[]): T[];

    /**
     * Finds the index at which to insert an element into an already sorted array in O(log n).
     */
    insertionIndex(
      this: T[],
      element: T,
      compareFn: (a: T, b: T) => number,
    ): number;

    /**
     * Returns a copy of the array without the given items.
     */
    without(this: T[], ...items: T[]): T[];

    /**
     * Returns a copy of the array where elements passing `predicate` are bumped to the top.
     * If several elements pass the predicate, they will be bumped in order of appearance.
     */
    bumped(this: T[], predicate: (value: T) => boolean): T[];
  }
}

const defineArrayProperty = <K extends keyof Array<unknown>>(
  key: K,
  value: Array<unknown>[K],
) => {
  Object.defineProperty(Array.prototype, key, { value });
};

defineArrayProperty("at", function (index) {
  return this[index < 0 ? index + this.length : index];
});

defineArrayProperty("immutableSort", function (compareFn) {
  // eslint-disable-next-line nabla/no-array-sort
  return this.slice().sort(compareFn);
});

defineArrayProperty("isEmpty", function () {
  // eslint-disable-next-line nabla/use-is-empty
  return this.length === 0;
});

defineArrayProperty("isNotEmpty", function () {
  return this.length > 0;
});

defineArrayProperty("asNotEmpty", function () {
  const [first, ...rest] = this;
  return [first, ...rest];
});

const isStringSort = <T>(
  getValue: (value: T) => string | number | Date,
  value: T,
): getValue is (value: T) => string => typeof getValue(value) === "string";

defineArrayProperty("sortAsc", function (getValue) {
  if (this.isEmpty()) return this;
  if (isStringSort(getValue, this[0])) {
    return this.immutableSort((a, b) => getValue(a).localeCompare(getValue(b)));
  }
  return this.immutableSort((a, b) => getValue(a) - getValue(b));
});

defineArrayProperty("sortDesc", function (getValue) {
  if (this.isEmpty()) return this;
  if (isStringSort(getValue, this[0])) {
    return this.immutableSort((a, b) => getValue(b).localeCompare(getValue(a)));
  }
  return this.immutableSort((a, b) => getValue(b) - getValue(a));
});

defineArrayProperty("count", function (predicate) {
  // eslint-disable-next-line nabla/use-array-count
  return this.filter(predicate).length;
});

defineArrayProperty("filterNotNull", function () {
  return this.filter((el) => el !== undefined && el !== null) as {}[];
});

defineArrayProperty("mapNotNull", function (predicate) {
  // eslint-disable-next-line nabla/use-map-not-null
  return this.map(predicate).filterNotNull();
});

defineArrayProperty("flatMapNotNull", function (predicate) {
  return this.flatMap(predicate).filterNotNull();
});

defineArrayProperty("distinctBy", function (selector) {
  const set = new Set();
  return this.filter((e) => {
    if (set.has(selector(e))) return false;
    set.add(selector(e));
    return true;
  });
});

defineArrayProperty("distinct", function () {
  return this.distinctBy((e) => e);
});

const partition = <T>(
  array: T[],
  predicate: (value: T) => boolean,
): [T[], T[]] => {
  const trueElements: T[] = [];
  const falseElements: T[] = [];
  array.forEach((it) => {
    if (predicate(it)) {
      trueElements.push(it);
    } else {
      falseElements.push(it);
    }
  });
  return [trueElements, falseElements];
};

defineArrayProperty("partition", function (predicate) {
  return partition(this, predicate);
});

defineArrayProperty("split", function (n) {
  return [this.slice(0, n), this.slice(n, this.length)];
});

defineArrayProperty("groupBy", function (selector) {
  return this.reduce<Record<string, Array<unknown>>>((acc, it) => {
    if (selector(it) in acc) {
      acc[selector(it)].push(it);
    } else {
      acc[selector(it)] = [it];
    }
    return acc;
  }, {});
});

const groupByToEntries = <T, Comparable>(
  array: T[],
  selector: (value: T) => Comparable,
) => {
  const map = new Map<Comparable, unknown[]>();
  array.forEach((item) => {
    const key = selector(item);
    const value = map.get(key);
    if (value) value.push(item);
    else map.set(key, [item]);
  });
  return Array.from(map.entries());
};
defineArrayProperty("groupByToEntries", function (selector) {
  return groupByToEntries(this, selector);
});

defineArrayProperty("findIndexOrUndefined", function (predicate) {
  const index = this.findIndex(predicate);
  return index === -1 ? undefined : index;
});

defineArrayProperty("findLastIndex", function (predicate) {
  for (let i = this.length - 1; i >= 0; i--) {
    if (predicate(this[i], i, this)) return i;
  }
  return undefined;
});

defineArrayProperty("findLast", function (predicate) {
  const index = this.findLastIndex(predicate);
  return index ? this[index] : undefined;
});

defineArrayProperty("toggle", function (element, selector = (it) => it) {
  const newArray: unknown[] = [];
  let found = false;
  for (const el of this) {
    if (selector(el) === selector(element)) {
      found = true;
    } else {
      newArray.push(el);
    }
  }
  if (!found) newArray.push(element);
  return newArray;
});

defineArrayProperty("concatIf", function (condition, ...items) {
  return condition ? this.concat(...items) : this;
});

defineArrayProperty("equals", function (other) {
  return this.length === other.length && this.every((v, i) => v === other[i]);
});

defineArrayProperty("none", function (predicate) {
  return !this.some(predicate);
});

defineArrayProperty("insert", function (start, ...items) {
  return [...this.slice(0, start), ...items, ...this.slice(start)];
});

defineArrayProperty("insertionIndex", function (element, compareFn) {
  let min = 0;
  let max = this.length;
  let index = Math.floor((min + max) / 2);
  while (max > min) {
    if (compareFn(element, this[index]) < 0) {
      max = index;
    } else {
      min = index + 1;
    }
    index = Math.floor((min + max) / 2);
  }
  return index;
});

defineArrayProperty("without", function (...items) {
  return this.filter((it) => !items.includes(it));
});

defineArrayProperty("bumped", function (predicate) {
  return [
    ...this.filter((it) => predicate(it)),
    ...this.filter((it) => !predicate(it)),
  ];
});

export {};
