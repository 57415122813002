import { Button } from "components/Button/Button";
import { useLogout } from "contexts/User/useLogout";
import { useTranslation } from "i18n";

import notFoundSrc from "./notFound.png";

export const MfaRequired = () => {
  const t = useTranslation();
  const [logout, loggingOut] = useLogout();

  return (
    <div className="flex-col items-center text-center">
      <img src={notFoundSrc} style={{ height: 184 }} alt="Illustration" />
      <h1 className="mt-24 text-24 text-primary-dark font-medium">
        {t("error_page.error_page.mfa_required_title")}
      </h1>
      <span className="mt-12">
        {t("error_page.error_page.mfa_required_description")}
      </span>
      <Button
        className="mt-32"
        label={t("error_page.error_page.logout")}
        loading={loggingOut}
        onClick={() => logout()}
      />
    </div>
  );
};
