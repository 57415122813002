import { useField } from "hooks/useField";

import { useFormExtraContext } from "../Form/FormExtraContext";
import { Select, SelectProps } from "./Select";

export type FormSelectProps<Option, IsClearable extends boolean = false> = Omit<
  SelectProps<Option, IsClearable>,
  "value" | "error" | "onChange"
> &
  Partial<Pick<SelectProps<Option, IsClearable>, "onChange">>;

export const FormSelect = <Option, IsClearable extends boolean = false>({
  name,
  disabled,
  onChange,
  required,
  ...props
}: FormSelectProps<Option, IsClearable>) => {
  const [{ value, disabled: fieldDisabled }, { error }, { setValue }] =
    useField({ name, disabled });
  const isRequired = useFormExtraContext().requiredKeys.includes(name);

  return (
    <Select
      name={name}
      value={value}
      error={error}
      disabled={fieldDisabled}
      onChange={(v) => {
        setValue(v);
        onChange?.(v);
      }}
      required={required ?? isRequired}
      {...props}
    />
  );
};
