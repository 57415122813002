import {
  CSSProperties,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";

import { Icon } from "components/Icon/Icon";
import { TopSafeArea } from "components/Mobile/SafeArea";
import { on } from "utils";

import styles from "./modal.module.css";

export type ModalProps = {
  title?: string;
  show?: boolean;
  xl?: boolean;
  noPadding?: boolean;
  alignTopOnMobile?: boolean;
  onHide: () => void;
  className?: string;
  backdropClassName?: string;
  style?: CSSProperties;
  children?: ReactNode;
  closeButton?: ((close: () => void) => JSX.Element) | null;
};

// start: !show && !visible: not in the dom
// opened: show && !visible: in the dom: transparent, translateY 100vh
// effect: show && visible: in the dom, transition to bg-overlay, translate-none
// onClose: show && !visible: in the dom, transition to transparent, translateY 100vh
// onTransitionEnd: call onHide, back goTo 1
export const Modal = ({
  title,
  show = true,
  xl,
  noPadding,
  alignTopOnMobile,
  onHide,
  className,
  backdropClassName,
  style,
  children,
  closeButton,
}: ModalProps) => {
  const [visible, setVisible] = useState(false);
  const close = useCallback(() => setVisible(false), []);

  useEffect(() => {
    setVisible(show);
    if (!show) return;
    // TODO: enable focus trap and put listener on the modal
    return on("keydown", (e) => e.key === "Escape" && close());
  }, [show, close]);

  if (!visible && !show) return null;

  return ReactDOM.createPortal(
    <div
      className={classNames(styles.backdrop, backdropClassName, {
        "bg-overlay": visible,
      })}
      onTransitionEnd={() => !visible && onHide()}
      onClick={close}
      role="dialog"
      tabIndex={-1}
    >
      <TopSafeArea />
      <div
        className={classNames(
          styles.modal,
          { "transform-none": visible, [styles.xl]: xl },
          className,
          { "py-56 px-20 lg:py-24 lg:px-44": !noPadding },
          alignTopOnMobile ? "lg:my-auto" : "my-auto",
        )}
        style={style}
        role="document"
        // Don't trigger click outside events
        onClick={(e) => e.stopPropagation()}
      >
        {closeButton !== null && (
          <div className="absolute top-10 right-20 lg:right-10">
            {closeButton?.(close) ?? (
              <button
                className="flex-center h-[34px] w-[34px] rounded-full border"
                onClick={close}
              >
                <Icon name="close" />
              </button>
            )}
          </div>
        )}
        {title && (
          <h2 className="mb-6 text-20 text-center text-primary-dark font-semibold">
            {title}
          </h2>
        )}
        {children}
      </div>
    </div>,
    document.getElementById("modal-root")!,
  );
};
