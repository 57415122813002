import { StartAccountLoginWithGooglePayload, useAuth } from "auth/AuthContext";
import { Button } from "components/Button/Button";
import { useTranslation } from "i18n";

export const AccountGoogleLoginButton = ({
  payload = {},
}: {
  payload?: StartAccountLoginWithGooglePayload;
}) => {
  const t = useTranslation();
  const { startAccountLoginWithGoogle } = useAuth();

  return (
    <Button
      large
      secondary
      leftIcon="google"
      label={t("login.google_login_button.login_with_google")}
      onClick={async () => {
        const result = await startAccountLoginWithGoogle(payload);
        if (result.kind === "FAILURE") return; // Already notified by startAccountLoginWithGoogle.
        window.location.href = result.authenticationUrl;
      }}
    />
  );
};
