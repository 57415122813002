import { HTMLAttributes, useCallback, useEffect, useState } from "react";
import classNames from "classnames";
import ReactDOM from "react-dom";

import { useClickOutside } from "hooks";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useOn } from "hooks/useOn";
import { FakeClientRect } from "types";

import styles from "./popover.module.css";

type ArrowPosition = "top" | "bottom" | "right" | "left";
export type PopoverPosition =
  | ArrowPosition
  | "top-right"
  | "top-left"
  | "bottom-right"
  | "bottom-left";

type FakeElement = { getBoundingClientRect(): FakeClientRect }; // Allow RichMentionEditor to use coords in state
export type PopoverProps = {
  target: FakeElement;
  position?:
    | PopoverPosition
    | PopoverPosition[]
    | ((rect: FakeClientRect) => PopoverPosition[]);
  noArrow?: boolean;
  onClose: (() => void) | undefined;
  allowScrolling?: boolean;
  fullWidthOnMobile?: boolean;
} & Omit<HTMLAttributes<HTMLDivElement>, "ref">;

export const Popover = ({
  target,
  position = "bottom",
  noArrow,
  onClose,
  allowScrolling,
  fullWidthOnMobile,
  className,
  style,
  ...rest
}: PopoverProps) => {
  const ref = useClickOutside(() => onClose?.());
  const isDesktop = useIsDesktop();
  const fullWidth = !isDesktop && fullWidthOnMobile;

  const getPosition = useCallback(
    () =>
      computePosition({
        target,
        positions: typeof position === "string" ? [position] : position,
        noArrow,
        fullWidth,
      }),
    [noArrow, position, target, fullWidth],
  );

  const [{ arrowDirection, translate, ...insets }, setPosition] =
    useState(getPosition);
  useEffect(() => {
    setPosition(getPosition());
  }, [getPosition]);

  // Make the popover disappear in case of scroll outside the popover
  // if we don't 'allowScrolling'.
  // This logic is ignored when the scroll event is inside
  // the Popover div (or one of their direct descendants,
  // we don't recursively check on descendants, because
  // we haven't needed it so far)
  useOn(
    "scroll",
    (e) => {
      const children = ref.current?.children.let((it) => Array.from(it)) ?? [];
      if (
        e.target &&
        (e.target === ref.current || children.includes(e.target as Element))
      ) {
        return;
      }
      allowScrolling ? setPosition(getPosition()) : onClose?.();
    },
    true,
  );

  return ReactDOM.createPortal(
    <div
      ref={ref}
      style={{
        ...insets,
        ...(fullWidth ? { left: 6, right: 6 } : {}),
        ...style,
      }}
      role="dialog"
      // Don't trigger click outside events
      onClick={(e) => e.stopPropagation()}
      className={classNames(className, translate, styles.arrowBox, "", {
        [`${styles.common} ${styles.bottom}`]: arrowDirection === "bottom",
        [`${styles.common} ${styles.left}`]: arrowDirection === "left",
        [`${styles.common} ${styles.right}`]: arrowDirection === "right",
        [`${styles.common} ${styles.top}`]: arrowDirection === "top",
      })}
      {...rest}
    />,
    document.getElementById("popover-root")!,
  );
};

const computePosition = ({
  target,
  positions,
  noArrow,
  fullWidth,
}: {
  target: FakeElement;
  positions: PopoverPosition[] | ((rect: FakeClientRect) => PopoverPosition[]);
  noArrow?: boolean;
  fullWidth?: boolean;
}) => {
  const rect = target.getBoundingClientRect();
  const positionsArray = Array.isArray(positions) ? positions : positions(rect);
  const { top, bottom, left, right, height, width } = rect;

  // Computing the position that optimize the available space in two directions
  // Dump x + y for now
  const spaces = {
    top,
    left,
    right: window.innerWidth - right,
    bottom: window.innerHeight - bottom,
  };
  const position = positionsArray.sortDesc((p) => {
    const [axisA, axisB] = p.split("-") as [
      ArrowPosition,
      "right" | "left" | undefined,
    ];
    return (
      spaces[axisA] +
      (axisB === "right"
        ? spaces.left
        : axisB === "left"
        ? spaces.right
        : // Popover is centered. Adding 1/3 of the width of the viewport to the minimum
        // favorise centered position if the target is in the second third of the screen
        axisA === "top" || axisA === "bottom"
        ? Math.min(spaces.left, spaces.right) + window.innerWidth / 3
        : Math.min(spaces.top, spaces.bottom) + window.innerHeight / 3)
    );
  })[0];

  const arrowDirectionMap: Partial<{
    [key in PopoverPosition]: ArrowPosition;
  }> = { top: "bottom", bottom: "top", left: "right", right: "left" };
  const arrowDirection =
    noArrow || fullWidth ? undefined : arrowDirectionMap[position];
  const arrowMargin = arrowDirection ? 11 : 0; // Should match $popover-arrow-size

  const shouldCenterVertically = position === "left" || position === "right";
  const shouldCenterHorizontally = position === "bottom" || position === "top";

  return {
    arrowDirection,
    translate: shouldCenterVertically
      ? "-translate-y-1/2"
      : shouldCenterHorizontally && !fullWidth
      ? "-translate-x-1/2"
      : undefined,
    top: position.includes("bottom")
      ? bottom + arrowMargin
      : shouldCenterVertically
      ? top + height / 2
      : undefined,
    bottom: position.includes("top")
      ? window.innerHeight - top + arrowMargin
      : undefined,
    left:
      position === "right"
        ? right + arrowMargin
        : position === "bottom-left" || position === "top-left"
        ? left
        : shouldCenterHorizontally
        ? left + width / 2
        : undefined,
    right:
      position === "left"
        ? window.innerWidth - left + arrowMargin
        : position === "bottom-right" || position === "top-right"
        ? window.innerWidth - right
        : undefined,
  };
};
