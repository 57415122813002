import { useState } from "react";
import classNames from "classnames";
import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { NextPageButton } from "components/Button/NextPageButton";
import { DatePicker } from "components/Form/DatePicker/DatePicker";
import { Select } from "components/Form/Select/Select";
import { PaginatedQuery } from "components/Query/PaginatedQuery";
import { Table } from "components/Table/Table";
import {
  ApiMethod,
  ApiMethodKnownValues,
  GetApiLogCodes,
  GetApiLogEndpoints,
  GetApiLogs,
} from "generated/organizationuser";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";

gql`
  # schema = ORGANIZATION_USER
  query GetApiLogs($filters: ApiLogsFilter, $cursor: String) {
    apiLogs(page: { numberOfItems: 20, cursor: $cursor }, filters: $filters) {
      nextCursor
      hasMore
      data {
        ...ApiLog
      }
    }
  }
  query GetApiLogEndpoints {
    apiLogEndpoints {
      paths
    }
  }
  query GetApiLogCodes {
    apiLogCodes {
      codes
    }
  }

  fragment ApiLog on ApiLog {
    uuid
    createdAt
    ipAddress
    path
    responseCode
    errorCode
    errorMessage
    apiKey
    method
  }
`;

export const ApiLogs = () => {
  const t = useTranslation();
  const [from, setFrom] = useState<ISOString>();
  const [to, setTo] = useState<ISOString>();
  const [path, setPath] = useState<string>();
  const [responseCode, setResponseCode] = useState<number>();
  const [method, setMethod] = useState<ApiMethod>();

  const endpointsData = useQuery(GetApiLogEndpoints).data;
  const endpoints = endpointsData ? endpointsData.paths : [];
  const codesData = useQuery(GetApiLogCodes).data;
  const codes = codesData ? codesData.codes : [];

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <div className="flex-col">
        <h1 className="text-primary-dark text-24 font-bold">
          {t("developers.api_logs.api_logs.api_logs")}
        </h1>
        <div>
          {t(
            "developers.below_are_api_calls_registered_for_your_organization_we_register_the_logs_up_to_30_days",
          )}
        </div>
      </div>
      <div className="flex space-x-16 rounded shadow-sm-outlined border bg-white p-16">
        <DatePicker<true>
          wrapperClassName="flex-1"
          label={t("developers.api_logs.api_logs.from")}
          value={from}
          onChange={(v) => {
            setFrom(v);
          }}
        />
        <DatePicker<true>
          wrapperClassName="flex-1"
          label={t("developers.api_logs.api_logs.to")}
          value={to}
          onChange={(v) => {
            setTo(v);
          }}
        />
        <div className="flex-1">
          <Select
            value={path}
            name="path"
            label={t("developers.api_logs.api_logs.endpoint")}
            options={endpoints}
            onChange={(v) => setPath(v)}
          />
        </div>
        <div className="flex-1">
          <Select
            value={responseCode}
            name="responseCode"
            label={t("developers.api_logs.api_logs.code")}
            options={codes}
            onChange={(v) => setResponseCode(v)}
          />
        </div>
        <div className="flex-1">
          <Select
            value={method}
            name="method"
            label={t("developers.api_logs.api_logs.method")}
            options={ApiMethodKnownValues}
            onChange={(v) => setMethod(v)}
          />
        </div>
      </div>
      <div className="flex items-center" />
      <PaginatedQuery
        query={GetApiLogs}
        variables={{
          filters: {
            createdAtAfter: from,
            createdAtBefore: to,
            path,
            responseCode,
            method,
          },
        }}
        pollInterval={60_000}
      >
        {({ data }, utils) => (
          <>
            <Table
              elements={data}
              fieldHeaders={[
                t("developers.api_logs.api_logs.endpoint"),
                t("developers.api_logs.api_logs.code"),
                t("developers.api_logs.api_logs.date"),
                t("developers.api_logs.api_logs.method"),
                t("developers.api_logs.api_logs.server_key"),
                t("developers.api_logs.api_logs.ip_address"),
              ]}
              fields={(log) => [
                <div className="max-w-330" key={`path_${log.uuid}`}>
                  <div>{log.path}</div>
                  <div className="text-body text-12">{log.errorMessage}</div>
                </div>,
                <div className="flex items-center" key={`code_${log.uuid}`}>
                  <div
                    key={`code_${log.uuid}`}
                    className={classNames(
                      "rounded px-4",
                      log.responseCode === 200
                        ? "bg-success"
                        : "bg-danger-100 border border-danger",
                    )}
                  >
                    {log.errorCode
                      ? `${log.responseCode} (${log.errorCode})`
                      : log.responseCode}
                  </div>
                </div>,
                `${log.createdAt.format("date")} ${log.createdAt.format(
                  "time",
                )}`,
                <div className="flex items-center" key={`method_${log.uuid}`}>
                  <div className="bg-light-blue rounded px-4">{log.method}</div>
                </div>,
                <div className="max-w-330" key={`key_${log.uuid}`}>
                  {log.apiKey}
                </div>,
                log.ipAddress,
              ]}
            />
            <NextPageButton {...utils} />
          </>
        )}
      </PaginatedQuery>
    </Background>
  );
};
