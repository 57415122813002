import { Component, ReactNode } from "react";
import * as Sentry from "@sentry/browser";

import { Button } from "components/Button/Button";
import { staticT } from "i18n";

export class ErrorBoundary extends Component<{ children: ReactNode }> {
  state: { error?: Error } = {};

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error) {
    Sentry.captureException(error);
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;
    if (error) {
      return (
        <div className="m-auto p-16 flex-center flex-col text-center">
          <span className="font-semibold">
            {staticT("error_boundary.an_error_has_occurred")}
          </span>
          {error.message.includes(
            "Failed to execute 'insertBefore' on 'Node'",
          ) && (
            <span>{staticT("error_boundary.never_translate_this_site")}</span>
          )}

          {process.env.NODE_ENV === "development" && (
            <div className="text-danger" style={{ whiteSpace: "pre-wrap" }}>
              {error.toString()}
            </div>
          )}
          <Button
            className="mt-16"
            onClick={() => window.location.reload()}
            label={staticT("error_boundary.reload")}
          />
        </div>
      );
    }

    return children;
  }
}
