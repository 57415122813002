import gql from "graphql-tag";

import { Background } from "components/Background/Backgound";
import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormState } from "components/Form/Form/FormState";
import { FormTextArea } from "components/Form/TextArea/FormTextArea";
import { SendCopilotApiUserHelpMessage } from "generated/copilot-api-developer";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

gql`
  # schema = COPILOT_API_DEVELOPER
  mutation SendCopilotApiUserHelpMessage($input: SendHelpMessageInput!) {
    sendHelpMessage(input: $input) {
      status
    }
  }
`;

type HelpFormValues = {
  text: string;
};

export const Help = () => {
  const t = useTranslation();
  const [sendHelpMessage, loading] = useMutation(SendCopilotApiUserHelpMessage);

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44">
      <div className="flex-col">
        <h1 className="text-primary-dark text-24 font-bold">
          {t("help.help.help")}
        </h1>
        <div className="text-body text-14 mb-32">
          {t(
            "help.a_bug_a_question_a_suggestion_well_get_back_to_you_promptly",
          )}
        </div>
        <div className="text-primary-dark font-medium text-14">
          {t("help.help.message")}
        </div>
        <Form<HelpFormValues>
          initialValues={{ text: "" }}
          onSubmit={(values) =>
            sendHelpMessage(
              { input: { message: values.text } },
              {
                onSuccess: () => {
                  values.text = "";
                  notifier.success(t("help.help.message_sent"));
                },
              },
            )
          }
        >
          <FormState<HelpFormValues>>
            {({ values }) => (
              <>
                <FormTextArea
                  minRows={5}
                  maxRows={10}
                  name="text"
                  className="mb-8"
                  style={{ maxWidth: "600px" }}
                  placeholder={t("help.help.your_message")}
                />
                <Submit
                  label={t("help.help.send")}
                  disabled={values.text.isBlank() || loading}
                />
              </>
            )}
          </FormState>
        </Form>
      </div>
    </Background>
  );
};
