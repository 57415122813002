import { createContext, useContext } from "react";
import { disableFragmentWarnings } from "graphql-tag";

import { SchemaType } from "base-types";
import { GraphQLClient } from "types";

import { PartialResultError } from "./errors";

// Works really bad with fast refresh + ensured by codegen
disableFragmentWarnings();

export const GraphQLClientContext = createContext<{
  graphQLClients: { [key in SchemaType]: GraphQLClient };
  isSocketReady: boolean;
  partialResultErrors: PartialResultError[];
  clearPartialResultErrors: () => void;
} | null>(null);
GraphQLClientContext.displayName = "GraphQLClientContext";

export const useGraphQLClient = () => {
  const context = useContext(GraphQLClientContext);
  if (!context) throw new Error("GraphQLClientProvider not available");
  return context;
};
