import { ReactNode, useMemo } from "react";
import {
  Form as FormikForm,
  Formik,
  FormikConfig,
  FormikHelpers,
} from "formik";
import * as Yup from "yup";

import { useIsMounted } from "hooks/useIsMounted";
import { useTranslation } from "i18n";

import { FormExtraContext } from "./FormExtraContext";

type ValidationShortcut =
  | "required"
  | "requiredEmail"
  | "requiredGranularDateTime"
  | "notEmpty"
  | "requiredCheckbox"
  | "requiredValue";

export type FormProps<
  Values extends Record<string, unknown>,
  SubmitContext extends Record<string, unknown> = { genericRequired: true },
> = Omit<
  FormikConfig<Values>,
  "render" | "onSubmit" | "validationSchema" | "children"
> & {
  onSubmit?: (
    values: Values,
    actions: FormikHelpers<Values>,
    context: SubmitContext | undefined,
  ) => void | Promise<any>;
  onSubmitEnd?: () => void;
  validationSchema?: {
    [key in keyof Partial<Values>]: ValidationShortcut | Yup.Schema<any>;
  };
  children?: ReactNode;
  disabled?: boolean;
  className?: string;
};

export const Form = <
  Values extends Record<string, unknown>,
  SubmitContext extends Record<string, unknown> = { genericRequired: true },
>({
  onSubmit,
  children,
  validationSchema,
  disabled = false,
  onSubmitEnd,
  className,
  initialValues,
  ...rest
}: FormProps<Values, SubmitContext>) => {
  const isMounted = useIsMounted();
  const t = useTranslation();

  const validationShortcuts = useMemo((): Record<
    ValidationShortcut,
    Yup.Schema<any>
  > => {
    // This map is memo because Yup is faking slow: https://github.com/nabla/health/pull/6073
    // But not outside of components like in the PR to sync with language
    const requiredLabel = t("form.form.form.this_field_is_required");
    return {
      required: Yup.string().trim().required(requiredLabel),
      requiredEmail: Yup.string()
        .trim()
        .email(t("form.form.form.incorrect_format"))
        .required(requiredLabel),
      requiredGranularDateTime: Yup.object().nullable().required(requiredLabel),
      notEmpty: Yup.array().min(1, requiredLabel),
      requiredCheckbox: Yup.boolean().oneOf(
        [true],
        t("form.form.form.must_be_checked"),
      ),
      requiredValue: Yup.string().trim().nullable().required(requiredLabel),
      // If you add a non-required shortcut here, you need to update the logic for requiredKeys
    };
  }, [t]);

  const requiredKeys: string[] = [];
  const yupShape: undefined | Record<string, Yup.Schema<any>> = {};
  if (validationSchema) {
    for (const [key, value] of Object.entries(validationSchema)) {
      if (typeof value === "string") {
        requiredKeys.push(key);
        yupShape[key] = validationShortcuts[value];
      } else {
        yupShape[key] = value;
      }
    }
  }

  return (
    <Formik
      onSubmit={(values, helpers) =>
        consumeSubmitContext<Values, SubmitContext>(helpers)
          .then((submitContext) => onSubmit?.(values, helpers, submitContext))
          .finally(() => {
            if (isMounted.current) helpers.setSubmitting(false);
            onSubmitEnd?.();
          })
      }
      validationSchema={
        // This is still probably too fucking slow when using multiple custom Yup validator.
        // Memo is not possible because validationSchema is always inline in the render
        // Solution 1: Aggressively memo the whole schema, but could lead to difficult bug to track if the schema is dynamic.
        // This is currently not done on ~40 of the codebase.
        // Solution 2: Keep as is, memo few places that have multiple custom schema and add more shortcuts
        // Solution 3: Ditch Formik and Yup and ask me (Arnaud) for my current implementation of forms
        // based on raw React context, Immer & Zod. It will be a big refactor, but the gain in validation syntax,
        // typing, performance and api (See the weird useSyncRef in FormDropzone) is worth it.
        // To get a sense of the main difference between Yup and Zod: https://lexi-lambda.github.io/blog/2019/11/05/parse-don-t-validate/
        validationSchema ? Yup.object().shape(yupShape) : undefined
      }
      initialValues={initialValues}
      enableReinitialize
      {...rest}
    >
      <FormExtraContext.Provider value={{ disabled, requiredKeys }}>
        <FormikForm noValidate className={className} children={children} />
      </FormExtraContext.Provider>
    </Formik>
  );
};

// There is currently no direct way to access the current status of a Formik
// form inside the `onSubmit` handler, so we use the `setFormikState` helper
// to get access to the status and update it at the same time.
const consumeSubmitContext = <
  Values extends Record<string, unknown>,
  SubmitContext extends Record<string, unknown> = { genericRequired: true },
>({
  setFormikState,
}: FormikHelpers<Values>): Promise<SubmitContext | undefined> =>
  new Promise((resolve) => {
    setFormikState(({ status, ...prevState }) => {
      const { submitContext, ...remainingStatus } = status ?? {};
      resolve(submitContext);
      return { remainingStatus, ...prevState };
    });
  });
