import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import gql from "graphql-tag";
import { useSearchParams } from "react-router-dom";

import { useAuth, VerifyMfaByTotpOrPhonePayload } from "auth/AuthContext";
import { ExpiredLinkButton } from "auth/components/ExpiredLinkButton";
import { InvalidLinkError } from "auth/components/InvalidLinkError";
import { VerifyMfaForm } from "auth/components/VerifyMfaForm";
import {
  TARGET_FRONTEND_SEARCH_PARAM,
  useNavigatePreservingTargetParams,
} from "auth/utils/copilot";
import { buildVerifyMfaPayload } from "auth/utils/mfa";
import {
  PublicScreenImage,
  PublicScreenWrapperWithEnvironmentBanner,
} from "components/PublicScreenWrapper/PublicScreenWrapper";
import { Spinner } from "components/Spinner/Spinner";
import { AppErrorCode } from "errors/generated";
import {
  RedeemLoginCode,
  RequestLoginCode,
  TargetFrontendKnownValues,
} from "generated/unauth-account";
import { ParsedGraphQLError } from "graphql-client/errors";
import { useMutation } from "graphql-client/useMutation";
import { getKnownValue } from "utils/enum";

import { routes } from "../../routes";

gql`
  # schema = UNAUTHENTICATED_ACCOUNT
  fragment LoginResponse on LoginResponse {
    ... on LoginResponseSuccess {
      jwtTokens {
        accessToken
        refreshToken
      }
    }
    ... on LoginResponseMfaRequired {
      mfaState {
        ... on SetupMfaState {
          supportedMethods {
            ... on TotpMfaMethod {
              isSetup
            }
            ... on SmsMfaMethod {
              isSetup
              phone
              mfaBySmsAntiAbuseToken
            }
          }
        }
      }
    }
  }

  mutation RequestLoginCode(
    $email: String!
    $targetFrontend: TargetFrontend!
    $includeMagicLink: Boolean
  ) {
    requestLoginCode(
      email: $email
      targetFrontend: $targetFrontend
      includeMagicLink: $includeMagicLink
    ) {
      _
    }
  }

  mutation RedeemLoginCode($email: String!, $code: String!, $mfaCode: String) {
    redeemLoginCode(email: $email, code: $code, mfaCode: $mfaCode) {
      loginResponse {
        ...LoginResponse
      }
    }
  }
`;

type LoginLinkState =
  | { type: "REDEEMING" }
  | { type: "REDEEMED" }
  | { type: "NEEDS_MFA"; payload: VerifyMfaByTotpOrPhonePayload }
  | { type: "EXPIRED" }
  | { type: "ALREADY_USED" }
  | { type: "FAILED" };

type RedeemValueType = { jwt: string } | { code: string };

export type CodeInputMode = "MANUAL" | "LINK";

export const AccountLoginLink = () => {
  const { accountLoginWithTokens } = useAuth();
  const navigatePreservingTargetParams = useNavigatePreservingTargetParams();
  const [searchParams] = useSearchParams();
  const jwt = searchParams.get("oneTimeLoginJwt");
  const code = searchParams.get("accountLoginMagicLinkCode");
  const inputMode = searchParams.get("inputMode");
  const redeemParam: RedeemValueType | undefined = useMemo(
    () => (jwt ? { jwt } : code ? { code } : undefined),
    [code, jwt],
  );
  const email = searchParams.get("email");
  const targetFrontend = getKnownValue(
    searchParams.get(TARGET_FRONTEND_SEARCH_PARAM),
    TargetFrontendKnownValues,
  );
  const publicScreenImage: PublicScreenImage =
    targetFrontend === "COPILOT_WEB_APP" ||
    targetFrontend === "COPILOT_CHROME_EXTENSION"
      ? "COPILOT"
      : "CARE_PLATFORM";

  const shouldRedeemLink = !!email && !!targetFrontend && !!redeemParam;
  const startedRedeemingLinkRef = useRef(false);
  const [redeemLoginCode] = useMutation(RedeemLoginCode, {
    throwOnError: true,
  });
  const [requestLoginCode] = useMutation(RequestLoginCode);
  const [loginLinkState, setLoginLinkState] = useState<LoginLinkState>({
    type: "REDEEMING",
  });

  const redeemAndParse = useCallback(
    async (
      currentEmail: string,
      redeemValue: RedeemValueType,
      mfaCode?: string,
    ): Promise<LoginLinkState> => {
      try {
        let response;
        if ("code" in redeemValue) {
          response = await redeemLoginCode(
            { email: currentEmail, code: redeemValue.code, mfaCode },
            { requestContext: { regionByAccountEmail: currentEmail } },
          );
        } else {
          throw new Error("Invalid redeem value");
        }
        const { loginResponse } = response;
        if (loginResponse.__typename === "LoginResponseSuccess") {
          accountLoginWithTokens({
            accountAccessToken: loginResponse.jwtTokens.accessToken,
            accountRefreshToken: loginResponse.jwtTokens.refreshToken,
          });
          navigatePreservingTargetParams(routes.PICK_IDENTITY, {
            replace: true,
            state: { shouldAutomaticallyPickSingleIdentity: true },
          });
          return { type: "REDEEMED" };
        }

        if (loginResponse.__typename === "LoginResponseMfaRequired") {
          const payload = buildVerifyMfaPayload(
            currentEmail,
            loginResponse.mfaState,
          );
          return payload ? { type: "NEEDS_MFA", payload } : { type: "FAILED" };
        }

        return { type: "FAILED" };
      } catch (e) {
        if (e instanceof ParsedGraphQLError) {
          switch (e.code) {
            case AppErrorCode.EXPIRED_VERIFICATION_CODE:
              return { type: "EXPIRED" };
            case AppErrorCode.ALREADY_USED_VERIFICATION_CODE:
              return { type: "ALREADY_USED" };
          }
        }
        return { type: "FAILED" };
      }
    },
    [accountLoginWithTokens, navigatePreservingTargetParams, redeemLoginCode],
  );

  useEffect(() => {
    if (!shouldRedeemLink) return;
    if (startedRedeemingLinkRef.current) return;
    startedRedeemingLinkRef.current = true;
    redeemAndParse(email, redeemParam).then(setLoginLinkState);
  }, [code, email, jwt, redeemAndParse, redeemParam, shouldRedeemLink]);

  return !shouldRedeemLink ? (
    <PublicScreenWrapperWithEnvironmentBanner image={publicScreenImage}>
      <InvalidLinkError />
    </PublicScreenWrapperWithEnvironmentBanner>
  ) : loginLinkState.type === "REDEEMING" ? (
    <Spinner />
  ) : loginLinkState.type === "EXPIRED" ? (
    <PublicScreenWrapperWithEnvironmentBanner image={publicScreenImage}>
      <ExpiredLinkButton
        email={email}
        onClick={async () => {
          const didRequest = await requestLoginCode(
            { email, targetFrontend, includeMagicLink: true },
            { requestContext: { regionByAccountEmail: email } },
          );
          return didRequest ? "SUCCESS" : "ERROR";
        }}
      />
    </PublicScreenWrapperWithEnvironmentBanner>
  ) : loginLinkState.type === "NEEDS_MFA" ? (
    <PublicScreenWrapperWithEnvironmentBanner image={publicScreenImage}>
      <VerifyMfaForm
        {...loginLinkState.payload}
        onSubmit={async (mfaCode) => {
          setLoginLinkState({ type: "REDEEMING" });
          const state = await redeemAndParse(email, redeemParam, mfaCode);
          setLoginLinkState(state);
          return state.type === "REDEEMED" ? "SUCCESS" : "ERROR";
        }}
      />
    </PublicScreenWrapperWithEnvironmentBanner>
  ) : (
    <PublicScreenWrapperWithEnvironmentBanner image={publicScreenImage}>
      <InvalidLinkError
        codeInputMode={inputMode === "MANUAL" ? "MANUAL" : "LINK"}
        reason={
          loginLinkState.type === "ALREADY_USED" ? "ALREADY_USED" : "INVALID"
        }
        targetFrontend={targetFrontend}
      />
    </PublicScreenWrapperWithEnvironmentBanner>
  );
};
