import { ApolloClient } from "@apollo/client";

import { Fragment, Query, SchemaType } from "base-types";
import {
  EntityFieldMap as EntityFieldMap_account,
  EntityName as EntityName_account,
  possibleTypes as possibleTypes_account,
  QueryName as QueryName_account,
  QueryVariablesMap as QueryVariablesMap_account,
} from "generated/account";
import {
  EntityFieldMap as EntityFieldMap_copilotuser,
  EntityName as EntityName_copilotuser,
  possibleTypes as possibleTypes_copilotuser,
  QueryName as QueryName_copilotuser,
  QueryVariablesMap as QueryVariablesMap_copilotuser,
} from "generated/copilot-api-developer";
import {
  EntityFieldMap as EntityFieldMap_organizationuser,
  EntityName as EntityName_organizationuser,
  possibleTypes as possibleTypes_organizationuser,
  QueryName as QueryName_organizationuser,
  QueryVariablesMap as QueryVariablesMap_organizationuser,
} from "generated/organizationuser";
import { possibleTypes as possibleTypes_unauth_account } from "generated/unauth-account";

export type QueryName =
  | QueryName_copilotuser
  | QueryName_organizationuser
  | QueryName_account;

export type EntityName =
  | EntityName_copilotuser
  | EntityName_organizationuser
  | EntityName_account;

export type EntityFieldMap = EntityFieldMap_copilotuser &
  EntityFieldMap_organizationuser &
  EntityFieldMap_account;

export type QueryVariablesMap = QueryVariablesMap_copilotuser &
  QueryVariablesMap_organizationuser &
  QueryVariablesMap_account;

export const possibleTypes = {
  ...possibleTypes_copilotuser,
  ...possibleTypes_organizationuser,
  ...possibleTypes_account,
  ...possibleTypes_unauth_account,
};

export type PossibleTypes = {
  [key in keyof typeof possibleTypes]: typeof possibleTypes[key][number];
};

export type KnownUnionValue<T> = T extends { __typename: "FutureValue" } | null
  ? never
  : T;

export type ExtractUnionType<Value, Typename> = Extract<
  KnownUnionValue<Value>,
  { __typename: Typename }
>;

export type BaseCacheUpdateOptions<Data> = {
  skip?: (current: Data) => boolean;
  write: (current: Data) => void | Data;
};
export type QueryUpdateOptions<Data, Variables, VariablesRequired> =
  BaseCacheUpdateOptions<Data> & {
    query: Query<Data, Variables, VariablesRequired, SchemaType>;
  } & (VariablesRequired extends true
      ? { variables: Variables }
      : { variables?: Variables });
export type FragmentUpdateOptions<Data> = BaseCacheUpdateOptions<Data> & {
  uuid: UUID;
  fragment: Fragment<Data>;
};
export type GraphQLClient = ApolloClient<object> & {
  read<Data>(fragment: Fragment<Data>, uuid: UUID): Data | undefined;
  read<Data, Variables, VariablesRequired>(
    query: Query<Data, Variables, VariablesRequired, SchemaType>,
    variables: Variables,
  ): Data | undefined;
  update<Data, Variables, VariablesRequired>(
    options: QueryUpdateOptions<Data, Variables, VariablesRequired>,
  ): void;
  update<Data>(options: FragmentUpdateOptions<Data>): void;
  updateAll<Data>(options: {
    queryName: keyof QueryVariablesMap;
    query: Query<Data, unknown, unknown, SchemaType>;
    write: (current: Data) => void | Data;
  }): void;
  remove(entityName: EntityName, uuid: UUID): void;
  evict<Entity extends EntityName>(props: {
    entityName: Entity;
    uuid: UUID;
    field: EntityFieldMap[Entity];
  }): void;
  evictQuery<Query extends keyof QueryVariablesMap>(
    queryName: Query,
    variable: QueryVariablesMap[Query] | "ALL",
  ): void;
};
