import { createContext, useContext } from "react";

export type AppVersionState =
  | "NONE"
  | "UPDATE_AVAILABLE"
  | "UNKNOWN_ENUM_USED"
  | "OUTDATED"
  | "RELEASING";

export const AppVersionContext = createContext<{
  state: AppVersionState;
  minimumCodeVersion: number;
  updateMinimumCodeVersionIfNeeded: (version: number) => void;
  downloadingUpdate: boolean;
  availableCodeVersion: number | undefined;
  reload: () => void;
  downloadUpdateIfOutdated: () => void;
} | null>(null);
AppVersionContext.displayName = "AppVersionContext";

export const useAppVersion = () => {
  const context = useContext(AppVersionContext);
  if (!context) throw new Error("AppVersionProvider not available");
  return context;
};
