declare global {
  interface Number {
    /*
    Modulo operator that returns positive results
     */
    modulo(this: number, divisor: number): number;
  }
}

const defineNumberProperty = <K extends keyof number>(
  key: K,
  value: number[K],
) => {
  Object.defineProperty(Number.prototype, key, { value });
};

defineNumberProperty("modulo", function (divisor) {
  return ((this % divisor) + divisor) % divisor;
});

export {};
