// @ts-ignore
import gql from "graphql-tag";

// @ts-ignore
import { Maybe, Fragment, Query, Mutation } from "base-types";

export type MutationStatus = "SUCCESS" | "FUTURE_VALUE";
export type MutationStatusKnownValue = "SUCCESS";
export const MutationStatusKnownValues: MutationStatusKnownValue[] = [ "SUCCESS" ];
export type Permission = "USE_WEB_APP" | "VIEW_COPILOT_USERS" | "VIEW_API_KEYS" | "SEND_HELP_MESSAGES" | "FUTURE_VALUE";
export type PermissionKnownValue = "USE_WEB_APP" | "VIEW_COPILOT_USERS" | "VIEW_API_KEYS" | "SEND_HELP_MESSAGES";
export const PermissionKnownValues: PermissionKnownValue[] = [ "USE_WEB_APP","VIEW_COPILOT_USERS","VIEW_API_KEYS","SEND_HELP_MESSAGES" ];
export type SupportedLocale = "FRENCH" | "ENGLISH" | "PORTUGUESE" | "FUTURE_VALUE";
export type SupportedLocaleKnownValue = "FRENCH" | "ENGLISH" | "PORTUGUESE";
export const SupportedLocaleKnownValues: SupportedLocaleKnownValue[] = [ "FRENCH","ENGLISH","PORTUGUESE" ];
export type NablaProduct = "ASSISTANT" | "CORE_API" | "FUTURE_VALUE";
export type NablaProductKnownValue = "ASSISTANT" | "CORE_API";
export const NablaProductKnownValues: NablaProductKnownValue[] = [ "ASSISTANT","CORE_API" ];
export type JwkAlgorithmType = "RSA256" | "FUTURE_VALUE";
export type JwkAlgorithmTypeKnownValue = "RSA256";
export const JwkAlgorithmTypeKnownValues: JwkAlgorithmTypeKnownValue[] = [ "RSA256" ];



export type SendHelpMessageInput = { message: string }
export type UpdateCopilotApiDeveloperSettingsInput = { dummy?: Maybe<string> }
export type JwkSourceJwksUrlInput = { url: UrlAddress }
export type JwkSourceJwkPublicKeyInput = { key: RSAPublicKey; algorithmType: JwkAlgorithmType }
export type JwkSourceInput = { jwksUrl?: Maybe<JwkSourceJwksUrlInput>; jwkPublicKey?: Maybe<JwkSourceJwkPublicKeyInput> }
export type CreateOAuthClientInput = { displayName: string; externalJwkSource: JwkSourceInput }
export type UpdateOAuthClientInput = { displayName?: Maybe<string>; externalJwkSource?: Maybe<JwkSourceInput> }

export const possibleTypes: { [key in keyof PossibleTypes]: PossibleTypes[key][] } = {
  "JwkSource": [
    "JwkSourceJwksUrl",
    "JwkSourceJwkPublicKey"
  ]
};
export type PossibleTypes = {
  JwkSource: ("JwkSourceJwksUrl" | "JwkSourceJwkPublicKey")
};

export type EntityName = 
  | "CopilotApiDeveloper"
  | "Organization"
  | "SubOrganization"
  | "CopilotApiUsersWithActivity"
  | "OAuthClient"
export type EntityFieldMap = { CopilotApiDeveloper: "uuid" | "organization" | "subOrganization" | "email" | "permissions" | "locale" | "timezone"; Organization: "uuid" | "stringId" | "displayName" | "product" | "oauthClients"; SubOrganization: "uuid" | "organization" | "displayName" | "timezone"; CopilotApiUsersWithActivity: "uuid" | "externalId" | "activationDaysCount"; OAuthClient: "uuid" | "displayName" | "createdAt" | "lastUsedAt" | "externalJwkSource" }
export type QueryVariablesMap = { copilotApiUsersActivityStats: "start" | "end" }

export type QueryName = 
  | "CurrentCopilotApiDeveloper"
  | "CopilotApiUsersActivityStats"
  | "Me"

const CopilotApiDeveloperSummaryFragmentDocument = gql`
fragment CopilotApiDeveloperSummary on CopilotApiDeveloper {
  uuid
  email
  locale
  timezone
}

`
export const CopilotApiDeveloperSummaryFragmentProps: Fragment<CopilotApiDeveloperSummaryFragment> = {
  document: CopilotApiDeveloperSummaryFragmentDocument,
  fragmentName: "CopilotApiDeveloperSummary",
  entityName: "CopilotApiDeveloper",
  __do_not_use_Data: null
}
export type CopilotApiDeveloperSummaryFragment = { __typename: "CopilotApiDeveloper"; uuid: UUID; email: Maybe<EmailAddress>; locale: SupportedLocale; timezone: TimeZone }

export type CurrentCopilotApiDeveloperData = { __typename: "MeOutput"; developer: CopilotApiDeveloperSummaryFragment&{ permissions: Array<Permission>; subOrganization: { __typename: "SubOrganization"; uuid: UUID; displayName: Maybe<string>; timezone: TimeZone; organization: { __typename: "Organization"; uuid: UUID; stringId: string; displayName: string } } } }
export type CurrentCopilotApiDeveloperVariables = { [key: string]: never }
export const CurrentCopilotApiDeveloper: Query<CurrentCopilotApiDeveloperData, CurrentCopilotApiDeveloperVariables, false, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
query CurrentCopilotApiDeveloper {
  me {
    developer {
      ...CopilotApiDeveloperSummary
      permissions
      subOrganization {
        uuid
        displayName
        timezone
        organization {
          uuid
          stringId
          displayName
        }
      }
    }
  }
}
${CopilotApiDeveloperSummaryFragmentDocument}
`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type SendCopilotApiUserHelpMessageData = { __typename: "SendHelpMessageOutput"; status: MutationStatus }
export type SendCopilotApiUserHelpMessageVariables = { input: SendHelpMessageInput }
export const SendCopilotApiUserHelpMessage: Mutation<SendCopilotApiUserHelpMessageData, SendCopilotApiUserHelpMessageVariables, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
mutation SendCopilotApiUserHelpMessage($input: SendHelpMessageInput!) {
  sendHelpMessage(input: $input) {
    status
  }
}

`,
  endpointName: "sendHelpMessage",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type UpdateSettingsData = { __typename: "UpdateSettingsCopilotApiOutput"; developer: CopilotApiDeveloperSummaryFragment }
export type UpdateSettingsVariables = { input: UpdateCopilotApiDeveloperSettingsInput }
export const UpdateSettings: Mutation<UpdateSettingsData, UpdateSettingsVariables, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
mutation UpdateSettings($input: UpdateCopilotApiDeveloperSettingsInput!) {
  updateSettings(input: $input) {
    developer {
      ...CopilotApiDeveloperSummary
    }
  }
}
${CopilotApiDeveloperSummaryFragmentDocument}
`,
  endpointName: "updateSettings",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type CopilotApiUsersActivityStatsData = { __typename: "CopilotApiUserActivityStatsOutput"; copilotApiUsersWithActivity: Array<{ __typename: "CopilotApiUsersWithActivity"; uuid: UUID; externalId: Maybe<string>; activationDaysCount: Int }> }
export type CopilotApiUsersActivityStatsVariables = { start: ISOString; end: ISOString }
export const CopilotApiUsersActivityStats: Query<CopilotApiUsersActivityStatsData, CopilotApiUsersActivityStatsVariables, true, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
query CopilotApiUsersActivityStats($start: DateTime!, $end: DateTime!) {
  copilotApiUsersActivityStats(start: $start, end: $end) {
    copilotApiUsersWithActivity {
      uuid
      externalId
      activationDaysCount
    }
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type MeData = { __typename: "MeOutput"; developer: { __typename: "CopilotApiDeveloper"; uuid: UUID; organization: { __typename: "Organization"; uuid: UUID; oauthClients: Array<{
  __typename: "OAuthClient";
  uuid: UUID;
  displayName: string;
  createdAt: ISOString;
  lastUsedAt: Maybe<ISOString>;
  externalJwkSource: ({ __typename: "JwkSourceJwksUrl"; url: UrlAddress } | { __typename: "JwkSourceJwkPublicKey"; key: RSAPublicKey; algorithmType: JwkAlgorithmType } | { __typename: "FutureValue" })
}> } } }
export type MeVariables = { [key: string]: never }
export const Me: Query<MeData, MeVariables, false, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
query Me {
  me {
    developer {
      uuid
      organization {
        uuid
        oauthClients {
          uuid
          displayName
          createdAt
          lastUsedAt
          externalJwkSource {
            ... on JwkSourceJwksUrl {
              url
            }
            ... on JwkSourceJwkPublicKey {
              key
              algorithmType
            }
          }
        }
      }
    }
  }
}

`,
  __do_not_use_Data: null,
  __do_not_use_Variables: null,
  __do_not_use_VariablesRequired: null
}

export type DeleteOAuthClientData = { __typename: "DeleteOAuthClientOutput"; organization: { __typename: "Organization"; uuid: UUID } }
export type DeleteOAuthClientVariables = { oauthClientUuid: UUID }
export const DeleteOAuthClient: Mutation<DeleteOAuthClientData, DeleteOAuthClientVariables, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
mutation DeleteOAuthClient($oauthClientUuid: UUID!) {
  deleteOAuthClient(oauthClientUuid: $oauthClientUuid) {
    organization {
      uuid
    }
  }
}

`,
  endpointName: "deleteOAuthClient",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type CreateOAuthClientData = { __typename: "CreateOAuthClientOutput"; organization: { __typename: "Organization"; uuid: UUID } }
export type CreateOAuthClientVariables = { input: CreateOAuthClientInput }
export const CreateOAuthClient: Mutation<CreateOAuthClientData, CreateOAuthClientVariables, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
mutation CreateOAuthClient($input: CreateOAuthClientInput!) {
  createOAuthClient(input: $input) {
    organization {
      uuid
    }
  }
}

`,
  endpointName: "createOAuthClient",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}

export type UpdateOAuthClientData = { __typename: "UpdateOAuthClientOutput"; organization: { __typename: "Organization"; uuid: UUID } }
export type UpdateOAuthClientVariables = { oauthClientUuid: UUID; input: UpdateOAuthClientInput }
export const UpdateOAuthClient: Mutation<UpdateOAuthClientData, UpdateOAuthClientVariables, "COPILOT_API_DEVELOPER"> = {
  schemaType: "COPILOT_API_DEVELOPER",
  document: gql`
mutation UpdateOAuthClient($oauthClientUuid: UUID!, $input: UpdateOAuthClientInput!) {
  updateOAuthClient(oauthClientUuid: $oauthClientUuid, input: $input) {
    organization {
      uuid
    }
  }
}

`,
  endpointName: "updateOAuthClient",
  updateInputsPaths: [],
  __do_not_use_Data: null,
  __do_not_use_Variables: null
}