import classNames from "classnames";
import { To } from "history";
import { Link } from "react-router-dom";

import { Icon } from "components/Icon/Icon";
import { Spinner } from "components/Spinner/Spinner";
import { TooltipWrapper } from "components/Tooltip/TooltipWrapper";
import { useWaitPromise } from "hooks/useWaitPromise";
import { useTranslation } from "i18n";
import { IconName } from "icon-library";
import { MaybePromise } from "types";

import styles from "./menuItem.module.css";

export type BaseMenuItemProps = {
  icon?: IconName;
  rotate?: number;
  text: string;
  className?: string;
  bottomSeparator?: boolean;
  topSeparator?: boolean;
  hidden?: boolean;
  disable?: { if: boolean; tooltip?: string };
};

export type LinkMenuItemProps = BaseMenuItemProps & { to: To };

export type ActionMenuItemProps = BaseMenuItemProps & {
  onClick: (close: () => void) => MaybePromise;
};

export type MenuItemProps = LinkMenuItemProps | ActionMenuItemProps;

export type SubMenuMenuItemProps = BaseMenuItemProps & {
  subItems: MenuItemProps[];
  subItemsPosition?: "top" | "bottom";
};

export type MultiLevelMenuItemProps = MenuItemProps | SubMenuMenuItemProps;

export const MenuItem = ({
  item,
  close,
  className,
}: {
  item: MultiLevelMenuItemProps;
  close: () => void;
  className?: string;
}) => {
  const [waitPromise, loading] = useWaitPromise();
  const t = useTranslation();

  if (item.hidden) return null;

  const content = (
    <>
      {item.icon ? (
        loading ? (
          <Spinner inline small className="mr-8" />
        ) : (
          <Icon className="-ml-2 mr-8" name={item.icon} rotate={item.rotate} />
        )
      ) : null}
      <span className="flex-fill">{item.text}</span>
      {!item.icon && loading && <Spinner inline small className="ml-8" />}
      {"subItems" in item && <Icon className="ml-8 -mr-6" name="chevron" />}
    </>
  );

  const itemClassName = classNames(styles.base, styles.enable, item.className);

  return (
    <>
      {item.topSeparator && (
        <div className="bg-grey-200" style={{ height: 1 }} />
      )}
      {"to" in item ? (
        <Link
          to={item.to}
          className={classNames(itemClassName, className)}
          onClick={close}
        >
          {content}
        </Link>
      ) : item.disable?.if ? (
        <TooltipWrapper
          className={classNames(
            styles.base,
            styles.disabled,
            item.className,
            className,
          )}
          label={
            item.disable.tooltip ??
            t("menu_item.this_action_cannot_be_performed")
          }
          show={!!item.disable.tooltip}
        >
          {content}
        </TooltipWrapper>
      ) : "onClick" in item ? (
        <button
          className={classNames(itemClassName, className)}
          disabled={loading}
          onClick={() => waitPromise(item.onClick(close))}
        >
          {content}
        </button>
      ) : (
        <div className={classNames(itemClassName, className)}>{content}</div>
      )}
      {item.bottomSeparator && (
        <div className="bg-grey-200" style={{ height: 1 }} />
      )}
    </>
  );
};
