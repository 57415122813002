declare global {
  interface String {
    isEmpty(this: string): boolean;
    isNotEmpty(this: string): boolean;
    isBlank(this: string): boolean;
    isNotBlank(this: string): boolean;
    trimOrNull(this: string): string | null;
    indexOfOrUndefined(this: string, searchString: string): number | undefined;
    lastIndexOfOrUndefined(
      this: string,
      searchString: string,
    ): number | undefined;
    addNonBrakingSpaces(this: string): string;
    lowerFirst(this: string): string;
    upperFirst(this: string): string;
    truncate(this: string, maxLength: number): string;
    removeAccents(this: string): string;
    toSearchValue(this: string): string;
    fuzzyMatch(this: string, search: string): boolean;
  }
}

const defineStringProperty = <K extends keyof string>(
  key: K,
  value: string[K],
) => {
  Object.defineProperty(String.prototype, key, { value });
};

defineStringProperty("isEmpty", function () {
  // eslint-disable-next-line nabla/use-is-empty
  return this.length === 0;
});
defineStringProperty("isNotEmpty", function () {
  return !this.isEmpty();
});

defineStringProperty("isBlank", function () {
  return this.trim().isEmpty();
});
defineStringProperty("isNotBlank", function () {
  return !this.isBlank();
});

defineStringProperty("trimOrNull", function () {
  return this.isBlank() ? null : this.trim();
});

defineStringProperty("indexOfOrUndefined", function (searchString) {
  const index = this.indexOf(searchString);
  return index === -1 ? undefined : index;
});

defineStringProperty("lastIndexOfOrUndefined", function (searchString) {
  const index = this.lastIndexOf(searchString);
  return index === -1 ? undefined : index;
});

defineStringProperty("addNonBrakingSpaces", function () {
  return this.replaceAll(/ ([?!:])/gu, "\xa0$1");
});

defineStringProperty("lowerFirst", function () {
  return this.isEmpty() ? "" : this[0].toLowerCase() + this.slice(1);
});

defineStringProperty("upperFirst", function () {
  return this.isEmpty() ? "" : this[0].toUpperCase() + this.slice(1);
});

defineStringProperty("truncate", function (maxLength) {
  const singleLine = this.replaceAll(/\s+/gu, " ");
  return singleLine.length < maxLength
    ? singleLine
    : `${singleLine.slice(0, maxLength - 2)}...`;
});

// https://stackoverflow.com/a/37511463
defineStringProperty("removeAccents", function () {
  return this.normalize("NFD").replaceAll(/[\u0300-\u036f]/gu, "");
});

defineStringProperty("toSearchValue", function () {
  return this.toLowerCase().removeAccents().replaceAll("­", "");
});

const breakingPointsRegex = /(\s|')/u;
defineStringProperty("fuzzyMatch", function (search) {
  if (!search) return true;

  if (search.match(breakingPointsRegex)) {
    return this.toSearchValue().includes(search.toSearchValue());
  }

  return this.toSearchValue()
    .split(breakingPointsRegex)
    .some((word) => word.startsWith(search.toSearchValue()));
});

export {};
