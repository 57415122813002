import { ThinSideBar } from "components/ThinSideBar/ThinSideBar";
import { useUser } from "contexts/User/UserContext";
import { AccountMenuItem, MainSidebarItem } from "types";

export const MainSidebar = ({
  mainSidebarItems,
  accountMenuItems,
}: {
  mainSidebarItems: MainSidebarItem[];
  accountMenuItems: AccountMenuItem[];
}) => {
  const { ...accessProps } = useUser();
  const selectedItems = mainSidebarItems
    .filter((item) => item.hasAccess(accessProps))
    .map((item) => ({
      ...item,
      useCount: () => item.useCount?.(accessProps),
    }));

  return (
    <ThinSideBar items={selectedItems} accountMenuItems={accountMenuItems} />
  );
};
