import { useState } from "react";

import { Background } from "components/Background/Backgound";
import { Button } from "components/Button/Button";
import { Icon } from "components/Icon/Icon";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";
import { Translation } from "i18n/Translation";

import { ServerKeys } from "../../../shared/Developers/ServerKeys/ServerKeys";

export const ApiKeys = () => {
  const t = useTranslation();
  const [addingApiKey, setAddingApiKey] = useState(false);
  const isDesktop = useIsDesktop();

  return (
    <Background className="flex-col flex-fill overflow-auto p-16 lg:p-44 space-y-24">
      <div className="flex-col">
        <div className="flex items-center">
          <h1 className="text-primary-dark text-24 font-bold">
            {t("developers.server_keys.api_keys.api_keys")}
          </h1>

          <Button
            label={
              isDesktop
                ? t("developers.server_keys.server_keys.add_server_api_key")
                : t("developers.server_keys.api_keys.add_short")
            }
            onClick={() => setAddingApiKey(true)}
            className="ml-auto"
          />
        </div>
      </div>
      <div className="flex">
        <Icon name="warning" className="text-danger-300 mr-10" />
        <div className="text-danger-300">
          <Translation
            k="api_keys.warning_deprecated"
            components={{ b: ({ children }) => <b children={children} /> }}
          />
        </div>
      </div>
      <ServerKeys
        appFlavor="COPILOT_API"
        isAddingKey={addingApiKey}
        onKeyComposerClosed={() => setAddingApiKey(false)}
      />
    </Background>
  );
};
