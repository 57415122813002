const attributionKeys = [
  "gclid",
  "gbraid",
  "wbraid",
  "campaignid",
  "adgroupid",
  "creative",
  "qrcode",
];

const adAccountId = "AW-10834736277";
const conversionEventId: Record<ConversionEvent, string> = {
  SignupConfirmed: "JQTICOGq2eADEJXps64o",
  SignupFormSubmitted: "TSgUCI6ypuADEJXps64o",
};

const searchParams = new URLSearchParams(window.location.search);
// Only enable attribution scripts if the URL contains attribution parameters
const enabled = attributionKeys.some((x) => searchParams.get(x));

export const attributionData = enabled
  ? Object.fromEntries<string>(
      attributionKeys.mapNotNull((x) => {
        const value = searchParams.get(x);
        return value ? [x, value] : null;
      }),
    )
  : null;

if (enabled) {
  const addScriptTag = (props: {
    src?: string;
    async?: boolean;
    defer?: boolean;
    content?: string;
  }) => {
    const script = document.createElement("script");

    if (props.src) script.src = props.src;
    if (props.async) script.async = true;
    if (props.defer) script.defer = true;
    if (props.content) script.append(props.content);

    document.body.appendChild(script);
  };

  // The consent settings below prevent Google from using cookies to track
  // user sessions, which makes us GDPR compliant without needing to show
  // a consent banner. However, this also means that Google can only use
  // URL parameters like `gclid` for attribution, so we must persist them
  // across page changes to avoid losing conversions.
  //
  // See https://developers.google.com/tag-platform/devguides/consent

  addScriptTag({
    src: `https://www.googletagmanager.com/gtag/js?id=${adAccountId}`,
    async: true,
  });
  addScriptTag({
    content: `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('consent', 'default', {
      'ad_storage': 'denied',
      'analytics_storage': 'denied',
    });
    gtag('config', '${adAccountId}');
  `,
  });
}

export type ConversionEvent = "SignupConfirmed" | "SignupFormSubmitted";

export const trackConversion = (event: ConversionEvent) => {
  if (
    !window.gtag ||
    !window.dataLayer ||
    // Ensures the Tag Manager script hasn't been blocked by an ad blocker.
    !window.dataLayer.some((e) => e.event === "gtm.dom")
  ) {
    return;
  }

  window.gtag("event", "conversion", {
    send_to: `${adAccountId}/${conversionEventId[event]}`,
  });
};
