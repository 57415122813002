import { cssTransition, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Icon } from "components/Icon/Icon";

import styles from "./notificationsContainer.module.css";

const NoTransition = cssTransition({
  enter: "none",
  exit: "none",
  collapse: false,
  duration: 0,
});

export const NotificationsContainer = () => (
  <ToastContainer
    className={styles.container}
    bodyClassName={styles.toastBody}
    position="top-center"
    draggable={false}
    closeOnClick={false}
    hideProgressBar
    transition={window.E2E ? NoTransition : undefined}
    closeButton={({ closeToast }) => (
      <button className={styles.close} onClick={() => closeToast()}>
        <Icon name="close" />
      </button>
    )}
  />
);
