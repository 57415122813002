import { useEffect, useState } from "react";
import gql from "graphql-tag";

import {
  VerifyMfaByPhonePayload,
  VerifyMfaByTotpOrPhonePayload,
} from "auth/AuthContext";
import { Button } from "components/Button/Button";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import { SendMfaCodeBySms } from "generated/unauth-account";
import { useMutation } from "graphql-client/useMutation";
import { useTranslation } from "i18n";

import { MfaCodeForm } from "./MfaCodeForm";

export type VerifyMfaFormProps = {
  onGoBack?: () => void;
  onSubmit: (code: string) => Promise<"SUCCESS" | "ERROR">;
} & VerifyMfaByTotpOrPhonePayload;

type Step = "PICKER" | "TOTP" | "PHONE";

export const VerifyMfaForm = ({
  mfaByTotp,
  mfaByPhone,
  onGoBack,
  onSubmit,
}: VerifyMfaFormProps) => {
  const t = useTranslation();

  // Don't show the MFA method picker if there is only one method available.
  const fixedStep =
    mfaByTotp && mfaByPhone ? null : mfaByTotp ? "TOTP" : "PHONE";

  const initialStep = fixedStep ?? "PICKER";
  const [step, setStep] = useState<Step>(initialStep);

  return (
    <div className="flex-col">
      {(step !== initialStep || onGoBack) && (
        <ClickableIcon
          name="chevron"
          rotate={180}
          className="mb-24 border rounded-full h-44 w-44"
          onClick={step !== initialStep ? () => setStep(initialStep) : onGoBack}
        />
      )}

      {step === "PICKER" ? (
        <>
          <h2 className="text-black font-medium mb-4">
            {t("verify_mfa_form.authentication_method")}
          </h2>
          <p className="leading-normal">
            {t("verify_mfa_form.picker_subtitle")}
          </p>

          <div className="flex gap-12 mt-28">
            <Button
              secondary
              className="p-12 flex-1"
              label={t("verify_mfa_form.totp_code")}
              topIcon={{ name: "qrcode", size: 30 }}
              onClick={() => setStep("TOTP")}
            />
            <Button
              secondary
              className="p-12 flex-1"
              label={t("verify_mfa_form.sms_code")}
              topIcon={{ name: "mobile", size: 30 }}
              onClick={() => setStep("PHONE")}
            />
          </div>
        </>
      ) : step === "TOTP" ? (
        <VerifyMfaByTotpForm onSubmit={onSubmit} />
      ) : (
        <VerifyMfaByPhoneForm onSubmit={onSubmit} {...mfaByPhone!} />
      )}
    </div>
  );
};

const VerifyMfaByTotpForm = ({
  onSubmit,
}: {
  onSubmit: (code: string) => Promise<"SUCCESS" | "ERROR">;
}) => {
  const t = useTranslation();

  return (
    <MfaCodeForm
      subtitle={t("verify_mfa_form.totp_subtitle")}
      onSubmit={onSubmit}
    />
  );
};

gql`
  # schema = UNAUTHENTICATED_ACCOUNT
  mutation SendMfaCodeBySms(
    $accountEmail: String!
    $phoneNumber: String!
    $antiAbuseToken: String!
  ) {
    sendMfaCodeBySms(
      accountEmail: $accountEmail
      phone: $phoneNumber
      mfaBySmsAntiAbuseToken: $antiAbuseToken
    ) {
      _
    }
  }
`;

const VerifyMfaByPhoneForm = ({
  accountEmail,
  phoneNumber,
  antiAbuseToken,
  onSubmit,
}: VerifyMfaByPhonePayload & {
  onSubmit: (code: string) => Promise<"SUCCESS" | "ERROR">;
}) => {
  const t = useTranslation();
  const [sendMfaCodeBySms] = useMutation(SendMfaCodeBySms);

  // Automatically send the first code on component mount.
  useEffect(() => {
    if (alreadyConsumedAntiAbuseTokens.has(antiAbuseToken)) return;
    alreadyConsumedAntiAbuseTokens.add(antiAbuseToken);

    void sendMfaCodeBySms(
      { accountEmail, phoneNumber, antiAbuseToken },
      { requestContext: { regionByAccountEmail: accountEmail } },
    );
  }, [sendMfaCodeBySms, accountEmail, phoneNumber, antiAbuseToken]);

  return (
    <MfaCodeForm
      subtitle={t("verify_mfa_form.phone_subtitle", { phoneNumber })}
      onSubmit={onSubmit}
    />
  );
};

// Keep a cache of consumed tokens to avoid re-sending them to the server by
// mistake. This is especially relevant in development with React strict mode.
const alreadyConsumedAntiAbuseTokens = new Set<string>();
