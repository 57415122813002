import { useCallback, useEffect, useRef, useState } from "react";
import gql from "graphql-tag";

import {
  SetupMfaByPhonePayload,
  SetupMfaByTotpOrPhonePayload,
  SetupMfaByTotpPayload,
} from "auth/AuthContext";
import { Button } from "components/Button/Button";
import { Submit } from "components/Button/Submit";
import { Form } from "components/Form/Form/Form";
import { FormPhoneInput } from "components/Form/Input/FormPhoneInput";
import { ClickableIcon } from "components/Icon/ClickableIcon";
import {
  GetFreshMfaBySmsAntiAbuseToken,
  MfaMethodKindKnownValue,
} from "generated/account";
import { SendMfaCodeBySms } from "generated/unauth-account";
import { useMutation } from "graphql-client/useMutation";
import { useQuery } from "graphql-client/useQuery";
import { useTranslation } from "i18n";
import { run } from "utils";

import { MfaCodeForm } from "./MfaCodeForm";

export type SetupMfaFormProps = {
  organizationName: string;
  onGoBack?: () => void;
  onSubmit: (
    method: MfaMethodKindKnownValue,
    code: string,
  ) => Promise<"SUCCESS" | "ERROR">;
} & SetupMfaByTotpOrPhonePayload;

type Step =
  | "PICKER"
  | "TOTP_QR_CODE"
  | "TOTP_CONFIRMATION"
  | "PHONE_NUMBER"
  | "PHONE_CONFIRMATION";

export const SetupMfaForm = ({
  organizationName,
  mfaByTotp,
  mfaByPhone,
  onGoBack,
  onSubmit,
}: SetupMfaFormProps) => {
  const t = useTranslation();

  // Don't show the MFA method picker if there is only one method available.
  const fixedStep: Step | null =
    mfaByTotp && mfaByPhone
      ? null
      : mfaByTotp
      ? "TOTP_QR_CODE"
      : "PHONE_NUMBER";

  const initialStep = fixedStep ?? "PICKER";
  const [step, setStep] = useState<Step>(initialStep);
  const [phoneNumber, setPhoneNumber] = useState(
    mfaByPhone?.tentativePhoneNumber ?? "",
  );

  return (
    <div className="flex-col">
      {(step !== initialStep || onGoBack) && (
        <ClickableIcon
          name="chevron"
          rotate={180}
          className="mb-24 border rounded-full h-44 w-44"
          onClick={() => {
            switch (step) {
              case initialStep:
              case "PICKER":
                onGoBack?.();
                break;
              case "TOTP_QR_CODE":
              case "PHONE_NUMBER":
                setStep("PICKER");
                break;
              case "TOTP_CONFIRMATION":
                setStep("TOTP_QR_CODE");
                break;
              case "PHONE_CONFIRMATION":
                setStep("PHONE_NUMBER");
                break;
            }
          }}
        />
      )}

      {run(() => {
        switch (step) {
          case "PICKER":
            return (
              <>
                <h2 className="text-black font-medium mb-4">
                  {t("setup_mfa_form.title")}
                </h2>
                <p className="leading-normal mb-28">
                  {t("setup_mfa_form.picker_subtitle", { organizationName })}
                </p>

                <div className="flex gap-12">
                  <Button
                    secondary
                    className="p-12 flex-1"
                    label={t("verify_mfa_form.totp_code")}
                    topIcon={{ name: "qrcode", size: 30 }}
                    onClick={() => setStep("TOTP_QR_CODE")}
                  />
                  <Button
                    secondary
                    className="p-12 flex-1"
                    label={t("verify_mfa_form.sms_code")}
                    topIcon={{ name: "mobile", size: 30 }}
                    onClick={() => setStep("PHONE_NUMBER")}
                  />
                </div>
              </>
            );
          case "TOTP_QR_CODE":
            return (
              <SetupMfaByTotpQrCode
                organizationName={organizationName}
                onContinue={() => setStep("TOTP_CONFIRMATION")}
                {...mfaByTotp!}
              />
            );
          case "TOTP_CONFIRMATION":
            return (
              <SetupMfaByTotpConfirmationForm
                onSubmit={(code) => onSubmit("TOTP", code)}
              />
            );
          case "PHONE_NUMBER":
            return (
              <SetupMfaByPhoneNumberForm
                organizationName={organizationName}
                initialPhoneNumber={phoneNumber}
                onContinue={(number) => {
                  setPhoneNumber(number);
                  setStep("PHONE_CONFIRMATION");
                }}
              />
            );
          case "PHONE_CONFIRMATION":
            return (
              <SetupMfaByPhoneConfirmationForm
                phoneNumber={phoneNumber}
                onSubmit={(code) => onSubmit("SMS", code)}
                {...mfaByPhone!}
              />
            );
        }
      })}
    </div>
  );
};

const SetupMfaByTotpQrCode = ({
  organizationName,
  tentativeQrCode,
  onContinue,
}: SetupMfaByTotpPayload & {
  organizationName: string;
  onContinue: () => void;
}) => {
  const t = useTranslation();

  return (
    <>
      <h2 className="text-black font-medium mb-4">
        {t("setup_mfa_form.title")}
      </h2>
      <p className="leading-normal mb-18">
        {t("setup_mfa_form.totp_qr_code_subtitle", { organizationName })}
      </p>

      <img
        className="w-[300px] h-[300px] self-center"
        style={{ imageRendering: "pixelated" }}
        src={`data:image/jpeg;base64,${tentativeQrCode}`}
        alt="QR code"
      />

      <Button
        large
        label={t("setup_mfa_form.continue")}
        onClick={onContinue}
        className="mt-28"
      />
    </>
  );
};

const SetupMfaByTotpConfirmationForm = ({
  onSubmit,
}: {
  onSubmit: (code: string) => Promise<"SUCCESS" | "ERROR">;
}) => {
  const t = useTranslation();
  return (
    <MfaCodeForm
      subtitle={t("setup_mfa_form.totp_confirm_subtitle")}
      onSubmit={onSubmit}
    />
  );
};

const SetupMfaByPhoneNumberForm = ({
  organizationName,
  initialPhoneNumber,
  onContinue,
}: {
  organizationName: string;
  initialPhoneNumber: string;
  onContinue: (phoneNumber: string) => void;
}) => {
  const t = useTranslation();
  return (
    <Form<{ phoneNumber: string }>
      className="flex-col"
      initialValues={{ phoneNumber: initialPhoneNumber }}
      onSubmit={({ phoneNumber }) => onContinue(phoneNumber)}
    >
      <h2 className="text-black font-medium mb-4">
        {t("setup_mfa_form.title")}
      </h2>
      <p className="leading-normal mb-28">
        {t("setup_mfa_form.phone_number_subtitle", { organizationName })}
      </p>
      <FormPhoneInput
        name="phoneNumber"
        label={t("patients.patient_composer.phone")}
        placeholder={t("patients.patient_composer.phone")}
        wrapperClassName="flex-fill"
        autoFocus
      />
      <Submit
        large
        label={t("setup_mfa_form.continue")}
        requiresValid
        className="mt-28"
      />
    </Form>
  );
};

const SetupMfaByPhoneConfirmationForm = ({
  phoneNumber,
  accountEmail,
  onSubmit,
}: SetupMfaByPhonePayload & {
  phoneNumber: string;
  onSubmit: (code: string) => Promise<"SUCCESS" | "ERROR">;
}) => {
  const t = useTranslation();
  const [sendMfaCodeBySms] = useMutation(SendMfaCodeBySms);
  const getFreshAntiAbuseToken = useGetFreshAntiAbuseToken();
  const sentCodeRef = useRef(false);

  // Automatically send the first code on component mount.
  useEffect(() => {
    if (sentCodeRef.current) return;
    sentCodeRef.current = true;

    void run(async () => {
      const antiAbuseToken = await getFreshAntiAbuseToken();
      if (!antiAbuseToken) return;
      void sendMfaCodeBySms(
        { accountEmail, phoneNumber, antiAbuseToken },
        { requestContext: { regionByAccountEmail: accountEmail } },
      );
    });
  }, [sendMfaCodeBySms, getFreshAntiAbuseToken, accountEmail, phoneNumber]);

  return (
    <MfaCodeForm
      subtitle={t("setup_mfa_form.phone_confirm_subtitle", {
        phoneNumber,
      })}
      onSubmit={onSubmit}
    />
  );
};

gql`
  # schema = ACCOUNT
  query GetFreshMfaBySmsAntiAbuseToken {
    me {
      mfaState {
        ... on NotSetupMfaState {
          supportedMethods {
            ... on SmsMfaMethod {
              mfaBySmsAntiAbuseToken
            }
          }
        }
      }
    }
  }
`;

// Returns a fresh anti-abuse token. This is done to avoid issues where the
// subsequent calls to `sendMfaCodeBySms` would fail because the anti-abuse
// token returned in the beginning of the flow has already been used.
//
// Note: only works when the user is currently logged in to an account.
const useGetFreshAntiAbuseToken = () => {
  const { refetch } = useQuery(GetFreshMfaBySmsAntiAbuseToken, { skip: true });
  return useCallback(async () => {
    const freshAntiAbuseTokenData = await refetch();
    return freshAntiAbuseTokenData?.mfaState.__typename === "NotSetupMfaState"
      ? freshAntiAbuseTokenData.mfaState.supportedMethods
          .filter(
            (it): it is Extract<typeof it, { __typename: "SmsMfaMethod" }> =>
              it.__typename === "SmsMfaMethod",
          )
          .at(0)?.mfaBySmsAntiAbuseToken
      : null;
  }, [refetch]);
};
