import { Query, SchemaType } from "base-types";
import { ErrorPage } from "components/ErrorPage/ErrorPage";
import { Spinner } from "components/Spinner/Spinner";
import {
  PaginatedQueryOptions,
  PaginatedQueryUtils,
  usePaginatedQuery,
} from "graphql-client/usePaginatedQuery";

export const PaginatedQuery = <
  Data,
  Cursor,
  Variables extends { cursor?: Cursor },
  VariablesRequired,
  Schema extends SchemaType,
  PageElement,
>({
  query,
  children,
  ...options
}: PaginatedQueryOptions<
  Data,
  Cursor,
  Variables,
  VariablesRequired,
  Schema,
  PageElement
> & {
  query: Query<Data, Variables, VariablesRequired, Schema>;
  children: (
    output: Data,
    utils: PaginatedQueryUtils<Data, Variables>,
  ) => JSX.Element | null;
}) => {
  const { data, error, loading, ...utils } = usePaginatedQuery(
    query,
    options as unknown as PaginatedQueryOptions<
      Data,
      Cursor,
      Variables,
      VariablesRequired,
      Schema,
      PageElement
    >,
  );

  if (loading) return <Spinner fill />;
  if (data) return children(data, utils);
  if (error) return <ErrorPage error={error} />;
  return null;
};
