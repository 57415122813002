type CustomStorage = {
  getItem(key: string): string | null;
  setItem(key: string, value: string): void;
  removeItem(key: string): void;
};

declare global {
  interface Window {
    E2E: true | undefined;
    storage: CustomStorage;
  }

  const storage: CustomStorage;
}

/* eslint-disable nabla/no-local-storage */
window.storage = window.E2E
  ? sessionStorage
  : {
      getItem: (key) => {
        try {
          return localStorage.getItem(key);
        } catch {
          // Safari iOS can throw for "Security" reason when calling this on init
          // To avoid crashing the page when trying to know if the user is
          // already authenticated, we catch the error and return null
          return null;
        }
      },
      setItem: (key, value) => localStorage.setItem(key, value),
      removeItem: (key) => localStorage.removeItem(key),
    };

export {};
