import { useCallback, useEffect, useMemo, useState } from "react";
import { ZxcvbnResult } from "@zxcvbn-ts/core";

import { language } from "utils/intl";

import { useDebounce } from "./useDebounce";

export const usePasswordEstimator = () => {
  const [password, setPassword] = useState<string | undefined>(undefined);
  const [result, setResult] = useState<ZxcvbnResult | undefined>(undefined);
  const passwordStrength = password ? result?.score ?? 0 : 0;

  // Lazy load the module until needed, because it's heavy
  // We also memoized the zxcvbn function because the init takes ~25% of a zxcvbn call
  const memoizedZxcvbn = useMemo(async () => {
    const { dictionary: commonDictionnary, adjacencyGraphs } = (
      await import("@zxcvbn-ts/language-common")
    ).default;
    const { dictionary, translations } = (
      await (language === "fr"
        ? import("@zxcvbn-ts/language-fr")
        : language === "pt"
        ? import("@zxcvbn-ts/language-pt-br")
        : import("@zxcvbn-ts/language-en"))
    ).default;
    const { zxcvbnOptions, zxcvbn } = await import("@zxcvbn-ts/core");

    translations.suggestions.anotherWord = getAnotherWordText(language);

    const options = {
      dictionary: {
        ...commonDictionnary,
        ...dictionary,
      },
      graphs: adjacencyGraphs,
      translations,
      useLevenshteinDistance: false,
    };
    zxcvbnOptions.setOptions(options);
    return zxcvbn;
  }, []);

  const computeStrength = useCallback(
    async (p: string) => {
      const zxcvbnResult = (await memoizedZxcvbn)(p);
      setResult(zxcvbnResult);
      return zxcvbnResult.score > 2;
    },
    [memoizedZxcvbn],
  );
  const debouncedPassword = useDebounce(password, 100);

  useEffect(() => {
    debouncedPassword && computeStrength(debouncedPassword);
  }, [debouncedPassword, computeStrength]);

  return {
    estimate: (p: string) => setPassword(p),
    passwordStrength,
    isStrongEnough: passwordStrength > 2,
    warning: result?.feedback.warning,
    suggestions: result?.feedback.suggestions ?? [],
  };
};

const getAnotherWordText = (value: string) => {
  switch (value) {
    case "fr":
      return "Ajoutez plus de mots.";
    case "pt":
      return "Adicione mais palavras.";
    case "en":
    default:
      return "Add more words.";
  }
};
