import { ReactNode, useState } from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { ClickableIcon } from "components/Icon/ClickableIcon";
import { Icon } from "components/Icon/Icon";
import { MenuItemProps } from "components/Menu/MenuItem";
import { MoreMenu } from "components/Menu/MoreMenu";
import { useIsDesktop } from "hooks/useMediaQuery";
import { useTranslation } from "i18n";

export type FieldHeader<K> = {
  id: K;
  name: string;
  sortable: boolean;
  noWrap?: boolean;
};

export type SortStatus<K> = {
  id: K;
  isAscending: boolean;
};

export type RowActionMode<T extends { uuid: UUID }> =
  | { mode: "expand"; expand: (e: T) => ReactNode }
  | { mode: "navigate"; to: string }
  | { mode: "none" };

export const Table = <K, T extends { uuid: UUID }>({
  elements,
  menuItems,
  fieldHeaders,
  fields,
  cellClassName,
  onShowDetails,
  sortStatus,
  onClickSortableHeader,
  emptyPlaceholder,
  doRowAction,
}: {
  elements: T[];
  fieldHeaders: (FieldHeader<K> | string | null)[];
  fields: (user: T) => ReactNode[];
  menuItems?: (user: T) => MenuItemProps[];
  cellClassName?: (user: T) => string | undefined;
  onShowDetails?: (show: boolean) => void;
  sortStatus?: SortStatus<K>;
  onClickSortableHeader?: (id: K) => void;
  emptyPlaceholder?: ReactNode;
  doRowAction?: (element: T) => RowActionMode<T>;
}) => {
  const [showDetails, setShowDetails] = useState<UUID>();
  const [expandedRow, setExpandedRow] = useState<UUID>();
  const element = elements.find((e) => e.uuid === showDetails);

  const isDesktop = useIsDesktop();
  const previewFieldHeaders = isDesktop
    ? fieldHeaders
    : fieldHeaders.slice(0, 2);

  const commonHeaderFont = "uppercase text-12";
  return element ? (
    <Details
      element={element}
      fieldNames={fieldHeaders.map((fieldHeader) => getHeaderName(fieldHeader))}
      fields={fields}
      back={() => {
        onShowDetails?.(false);
        setShowDetails(undefined);
      }}
      menuItems={menuItems}
    />
  ) : (
    <div className="inline-block rounded shadow-sm-outlined border bg-white w-full overflow-hidden">
      <table className="w-full">
        <thead className="rounded-t">
          <tr className="bg-grey-100">
            {previewFieldHeaders.map((fieldHeader, i) => {
              if (!fieldHeader) return <th key={i} />;
              return (
                <th key={i} className="px-20 py-12 text-left truncate">
                  {typeof fieldHeader === "string" ? (
                    <span
                      className={classNames(commonHeaderFont, "font-semibold")}
                    >
                      {fieldHeader}
                    </span>
                  ) : (
                    <button
                      className={classNames("flex items-center", {
                        "whitespace-nowrap": fieldHeader.noWrap,
                      })}
                      disabled={!fieldHeader.sortable}
                      onClick={() => onClickSortableHeader?.(fieldHeader.id)}
                    >
                      <span
                        className={classNames(
                          fieldHeader.id === sortStatus?.id
                            ? "text-primary-dark font-bold"
                            : "font-semibold",
                          commonHeaderFont,
                        )}
                      >
                        {fieldHeader.name}
                      </span>
                      {fieldHeader.sortable && (
                        <Icon
                          name="chevron"
                          rotate={
                            fieldHeader.id === sortStatus?.id &&
                            sortStatus.isAscending
                              ? -90
                              : 90
                          }
                        />
                      )}
                    </button>
                  )}
                </th>
              );
            })}
            {menuItems && <th />}
          </tr>
        </thead>
        <tbody>
          {elements.map((e) => {
            const previewFields = isDesktop ? fields(e) : fields(e).slice(0, 2);
            const rowAction = doRowAction?.(e);
            const rowHasAction = rowAction && rowAction.mode !== "none";
            const potentialLink =
              rowAction?.mode === "navigate" ? rowAction.to : undefined;

            return (
              <ExpandableRow
                key={e.uuid}
                element={e}
                row={
                  <tr
                    key={e.uuid}
                    className={classNames({
                      "cursor-pointer hover:bg-grey-100": rowHasAction,
                    })}
                    onClick={() => {
                      if (rowAction && rowAction.mode === "expand") {
                        setExpandedRow(
                          expandedRow === e.uuid ? undefined : e.uuid,
                        );
                      }
                    }}
                  >
                    {previewFields.map((fieldValue, i) =>
                      fieldValue ? (
                        <td
                          key={i}
                          className={classNames(
                            "border-t text-primary-dark text-14",
                            cellClassName?.(e),
                          )}
                        >
                          <LinkOrDiv link={potentialLink} className="p-20">
                            {fieldValue}
                          </LinkOrDiv>
                        </td>
                      ) : (
                        <td key={i} className="border-t">
                          <LinkOrDiv link={potentialLink} className="p-20" />
                        </td>
                      ),
                    )}
                    {menuItems &&
                      (isDesktop ? (
                        <td className="p-20 border-t text-primary-dark text-14">
                          <MoreMenu
                            items={menuItems(e)}
                            position="left"
                            className={classNames(
                              "rounded p-8",
                              rowHasAction
                                ? "hover:bg-grey-200"
                                : "hover:bg-grey-100",
                            )}
                          />
                        </td>
                      ) : (
                        <td className="py-20 border-t text-primary-dark text-14">
                          <ClickableIcon
                            name="chevron"
                            className="hover:bg-grey-100 rounded p-8"
                            onClick={() => {
                              onShowDetails?.(true);
                              setShowDetails(e.uuid);
                            }}
                          />
                        </td>
                      ))}
                  </tr>
                }
                colSpan={previewFields.length}
                expandRow={
                  rowAction?.mode === "expand" ? rowAction.expand : undefined
                }
                expandedRow={expandedRow}
              />
            );
          })}
        </tbody>
      </table>
      {elements.isEmpty() && emptyPlaceholder}
    </div>
  );
};

const ExpandableRow = <T extends { uuid: UUID }>({
  element,
  row,
  colSpan,
  expandRow,
  expandedRow,
}: {
  element: T;
  row: ReactNode;
  colSpan: number;
  expandedRow?: UUID;
  expandRow?: (element: T) => ReactNode;
}) => (
  <>
    {row}
    {expandRow && expandedRow === element.uuid && (
      <tr key={`expanded-${element.uuid}`}>
        <td colSpan={colSpan} className="p-20 border-t">
          {expandRow(element)}
        </td>
      </tr>
    )}
  </>
);

const Details = <T extends { uuid: UUID }>({
  element,
  menuItems,
  fieldNames,
  fields,
  back,
}: {
  element: T;
  fieldNames: (string | null)[];
  fields: (element: T) => ReactNode[];
  menuItems?: (element: T) => MenuItemProps[];
  back: () => void;
}) => {
  const t = useTranslation();

  return (
    <div className="flex-col flex-fill space-y-16">
      <button
        className="flex items-center text-16 font-medium text-primary-dark"
        onClick={() => back()}
      >
        <Icon name="chevron" rotate={180} className="mr-10" size={24} />
        {t("table.table.back")}
      </button>
      <div className="grid grid-cols-2 gap-20 rounded shadow-sm-outlined border bg-white w-full p-20">
        {fields(element).map((field, i) =>
          fieldNames[i] && field ? (
            <div key={i} className="flex-col">
              <div className="flex items-center text-12 text-body">
                {fieldNames[i]}
              </div>
              <div className="text-primary-dark text-16 font-medium">
                {field ? field : t("table.table.unknown")}
              </div>
            </div>
          ) : null,
        )}
      </div>
      {menuItems?.(element)
        .filter((item) => !item.hidden)
        .map((item) => {
          const content = (
            <div
              className={classNames(
                "flex items-center text-16 font-medium py-20 px-24 bg-white shadow-sm-outlined rounded active:bg-grey-100 hover:bg-grey-100",
                item.className,
              )}
            >
              {item.icon && (
                <Icon name={item.icon} className="mr-10" size={24} />
              )}
              {item.text}
            </div>
          );

          return "onClick" in item ? (
            <button
              key={item.text}
              onClick={() => item.onClick(() => undefined)}
            >
              {content}
            </button>
          ) : "to" in item ? (
            <Link to={item.to}>{content}</Link>
          ) : null;
        })}
    </div>
  );
};

const LinkOrDiv = ({
  link,
  children,
  className,
}: {
  link: string | undefined;
  children?: ReactNode;
  className?: string;
}) =>
  link ? (
    <Link to={link} className={classNames(className, "block")}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );

const getHeaderName = <K extends unknown>(
  fieldHeader: FieldHeader<K> | string | null,
) => {
  if (fieldHeader === null) return null;
  if (typeof fieldHeader === "string") return fieldHeader;
  return fieldHeader.name;
};
