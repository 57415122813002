// Generated file, see README
import add from "./icons/add.svg";
import addPeople from "./icons/addPeople.svg";
import alcoholGlass from "./icons/alcoholGlass.svg";
import archive from "./icons/archive.svg";
import arrow from "./icons/arrow.svg";
import artery from "./icons/artery.svg";
import audioBars from "./icons/audioBars.svg";
import bill from "./icons/bill.svg";
import bloodSugar from "./icons/bloodSugar.svg";
import bookmarkLight from "./icons/bookmarkLight.svg";
import books from "./icons/books.svg";
import bug from "./icons/bug.svg";
import calendar from "./icons/calendar.svg";
import calendarCancel from "./icons/calendarCancel.svg";
import camera from "./icons/camera.svg";
import change from "./icons/change.svg";
import chart from "./icons/chart.svg";
import chat from "./icons/chat.svg";
import chatVertical from "./icons/chatVertical.svg";
import chatVerticalEmpty from "./icons/chatVerticalEmpty.svg";
import check from "./icons/check.svg";
import checkCircle from "./icons/checkCircle.svg";
import checklist from "./icons/checklist.svg";
import checkMark from "./icons/checkMark.svg";
import checkOff from "./icons/checkOff.svg";
import checkOn from "./icons/checkOn.svg";
import chevron from "./icons/chevron.svg";
import chevronDown from "./icons/chevronDown.svg";
import cigarette from "./icons/cigarette.svg";
import circle from "./icons/circle.svg";
import clip from "./icons/clip.svg";
import clipboard from "./icons/clipboard.svg";
import close from "./icons/close.svg";
import closeCircle from "./icons/closeCircle.svg";
import code from "./icons/code.svg";
import condom from "./icons/condom.svg";
import copy from "./icons/copy.svg";
import creditCard from "./icons/creditCard.svg";
import dimensions from "./icons/dimensions.svg";
import dining from "./icons/dining.svg";
import document from "./icons/document.svg";
import dollar from "./icons/dollar.svg";
import dot from "./icons/dot.svg";
import doubleChatBubble from "./icons/doubleChatBubble.svg";
import doubleChevron from "./icons/doubleChevron.svg";
import download from "./icons/download.svg";
import drawer from "./icons/drawer.svg";
import edit from "./icons/edit.svg";
import email from "./icons/email.svg";
import equal from "./icons/equal.svg";
import euroMoney from "./icons/euroMoney.svg";
import exit from "./icons/exit.svg";
import expand from "./icons/expand.svg";
import eye from "./icons/eye.svg";
import eyeSlash from "./icons/eyeSlash.svg";
import faceId from "./icons/faceId.svg";
import fax from "./icons/fax.svg";
import fever from "./icons/fever.svg";
import filePlus from "./icons/filePlus.svg";
import filePreview from "./icons/filePreview.svg";
import fileText from "./icons/fileText.svg";
import filter from "./icons/filter.svg";
import flagEn from "./icons/flagEn.svg";
import flash from "./icons/flash.svg";
import floppyDisk from "./icons/floppyDisk.svg";
import folder from "./icons/folder.svg";
import future from "./icons/future.svg";
import globe from "./icons/globe.svg";
import globeOff from "./icons/globeOff.svg";
import grid from "./icons/grid.svg";
import happy from "./icons/happy.svg";
import headphones from "./icons/headphones.svg";
import health from "./icons/health.svg";
import heart from "./icons/heart.svg";
import heartBeat from "./icons/heartBeat.svg";
import heartLight from "./icons/heartLight.svg";
import help from "./icons/help.svg";
import helpCircle from "./icons/helpCircle.svg";
import history from "./icons/history.svg";
import home from "./icons/home.svg";
import homeLight from "./icons/homeLight.svg";
import humanHeight from "./icons/humanHeight.svg";
import hyperlink from "./icons/hyperlink.svg";
import imagery from "./icons/imagery.svg";
import incognito from "./icons/incognito.svg";
import info from "./icons/info.svg";
import infoCircle from "./icons/infoCircle.svg";
import addCircle from "./icons/keep-colors/addCircle.svg";
import google from "./icons/keep-colors/google.svg";
import masterCard from "./icons/keep-colors/masterCard.svg";
import visa from "./icons/keep-colors/visa.svg";
import whatsApp from "./icons/keep-colors/whatsApp.svg";
import key from "./icons/key.svg";
import laboratory from "./icons/laboratory.svg";
import list from "./icons/list.svg";
import location from "./icons/location.svg";
import lock from "./icons/lock.svg";
import logo from "./icons/logo.svg";
import logout from "./icons/logout.svg";
import machineLearning from "./icons/machineLearning.svg";
import map from "./icons/map.svg";
import medicalOffice from "./icons/medicalOffice.svg";
import megaphone from "./icons/megaphone.svg";
import menstrualCup from "./icons/menstrualCup.svg";
import menu from "./icons/menu.svg";
import menuPlus from "./icons/menuPlus.svg";
import micOff from "./icons/micOff.svg";
import micOn from "./icons/micOn.svg";
import mine from "./icons/mine.svg";
import minus from "./icons/minus.svg";
import mobile from "./icons/mobile.svg";
import moon from "./icons/moon.svg";
import more from "./icons/more.svg";
import nabla from "./icons/nabla.svg";
import notification from "./icons/notification.svg";
import paint from "./icons/paint.svg";
import passport from "./icons/passport.svg";
import pause from "./icons/pause.svg";
import people from "./icons/people.svg";
import percentage from "./icons/percentage.svg";
import phone from "./icons/phone.svg";
import photo from "./icons/photo.svg";
import pill from "./icons/pill.svg";
import pillsStroke from "./icons/pillsStroke.svg";
import pin from "./icons/pin.svg";
import pinnedPin from "./icons/pinnedPin.svg";
import player from "./icons/player.svg";
import power from "./icons/power.svg";
import profile from "./icons/profile.svg";
import profileCircle from "./icons/profileCircle.svg";
import profileCross from "./icons/profileCross.svg";
import program from "./icons/program.svg";
import puzzle from "./icons/puzzle.svg";
import qrcode from "./icons/qrcode.svg";
import questionMark from "./icons/questionMark.svg";
import questionnaire from "./icons/questionnaire.svg";
import questions from "./icons/questions.svg";
import quote from "./icons/quote.svg";
import radioOff from "./icons/radioOff.svg";
import radioOn from "./icons/radioOn.svg";
import reduce from "./icons/reduce.svg";
import refresh from "./icons/refresh.svg";
import reminder from "./icons/reminder.svg";
import reply from "./icons/reply.svg";
import reporting from "./icons/reporting.svg";
import rightArrow from "./icons/rightArrow.svg";
import ruler from "./icons/ruler.svg";
import run from "./icons/run.svg";
import sanitaryPad from "./icons/sanitaryPad.svg";
import scissors from "./icons/scissors.svg";
import search from "./icons/search.svg";
import send from "./icons/send.svg";
import settings from "./icons/settings.svg";
import shareLight from "./icons/shareLight.svg";
import speaker from "./icons/speaker.svg";
import squares from "./icons/squares.svg";
import star from "./icons/star.svg";
import starShield from "./icons/starShield.svg";
import starStroke from "./icons/starStroke.svg";
import stethoscope from "./icons/stethoscope.svg";
import stop from "./icons/stop.svg";
import stopWatch from "./icons/stopWatch.svg";
import tag from "./icons/tag.svg";
import tampon from "./icons/tampon.svg";
import theirs from "./icons/theirs.svg";
import time from "./icons/time.svg";
import trash from "./icons/trash.svg";
import trend from "./icons/trend.svg";
import trophy from "./icons/trophy.svg";
import undo from "./icons/undo.svg";
import up from "./icons/up.svg";
import upload from "./icons/upload.svg";
import uploadCloud from "./icons/uploadCloud.svg";
import vaccine from "./icons/vaccine.svg";
import videoOff from "./icons/videoOff.svg";
import videoOn from "./icons/videoOn.svg";
import virus from "./icons/virus.svg";
import waistSize from "./icons/waistSize.svg";
import wand from "./icons/wand.svg";
import warning from "./icons/warning.svg";
import waterGlass from "./icons/waterGlass.svg";
import wavingHand from "./icons/wavingHand.svg";
import weight from "./icons/weight.svg";

export const iconLibrary = {
  add,
  addPeople,
  alcoholGlass,
  archive,
  arrow,
  artery,
  audioBars,
  bill,
  bloodSugar,
  bookmarkLight,
  books,
  bug,
  calendar,
  calendarCancel,
  camera,
  change,
  chart,
  chat,
  chatVertical,
  chatVerticalEmpty,
  check,
  checkCircle,
  checkMark,
  checkOff,
  checkOn,
  checklist,
  chevron,
  chevronDown,
  cigarette,
  circle,
  clip,
  clipboard,
  close,
  closeCircle,
  code,
  condom,
  copy,
  creditCard,
  dimensions,
  dining,
  document,
  dollar,
  dot,
  doubleChatBubble,
  doubleChevron,
  download,
  drawer,
  edit,
  email,
  equal,
  euroMoney,
  exit,
  expand,
  eye,
  eyeSlash,
  faceId,
  fax,
  fever,
  filePlus,
  filePreview,
  fileText,
  filter,
  flagEn,
  flash,
  floppyDisk,
  folder,
  future,
  globe,
  globeOff,
  grid,
  happy,
  headphones,
  health,
  heart,
  heartBeat,
  heartLight,
  help,
  helpCircle,
  history,
  home,
  homeLight,
  humanHeight,
  hyperlink,
  imagery,
  incognito,
  info,
  infoCircle,
  key,
  laboratory,
  list,
  location,
  lock,
  logo,
  logout,
  machineLearning,
  map,
  medicalOffice,
  megaphone,
  menstrualCup,
  menu,
  menuPlus,
  micOff,
  micOn,
  mine,
  minus,
  mobile,
  moon,
  more,
  nabla,
  notification,
  paint,
  passport,
  pause,
  people,
  percentage,
  phone,
  photo,
  pill,
  pillsStroke,
  pin,
  pinnedPin,
  player,
  power,
  profile,
  profileCircle,
  profileCross,
  program,
  puzzle,
  qrcode,
  questionMark,
  questionnaire,
  questions,
  quote,
  radioOff,
  radioOn,
  reduce,
  refresh,
  reminder,
  reply,
  reporting,
  rightArrow,
  ruler,
  run,
  sanitaryPad,
  scissors,
  search,
  send,
  settings,
  shareLight,
  speaker,
  squares,
  star,
  starShield,
  starStroke,
  stethoscope,
  stop,
  stopWatch,
  tag,
  tampon,
  theirs,
  time,
  trash,
  trend,
  trophy,
  undo,
  up,
  upload,
  uploadCloud,
  vaccine,
  videoOff,
  videoOn,
  virus,
  waistSize,
  wand,
  warning,
  waterGlass,
  wavingHand,
  weight,
  addCircle,
  google,
  masterCard,
  visa,
  whatsApp,
};

export type IconName = keyof typeof iconLibrary;
