import { useCallback } from "react";
import { ApolloError, useMutation as useApolloMutation } from "@apollo/client";

import { useMaybeAuth } from "auth/AuthContext";
import { Mutation, SchemaType } from "base-types";
import { useAppVersion } from "contexts/AppVersion/AppVersionContext";
import { useSyncRef } from "hooks/useSyncRef";
import { staticT as t } from "i18n";
import { QueryName } from "types";
import { notifier } from "utils/notifier";

import { notifyGraphQLError, parseApolloError } from "./errors";
import { Exact } from "./Exact";
import { useGraphQLClient } from "./GraphQLClientContext";
import { RequestContextOptions } from "./request-context-utils";
import { ErrorHandler, SuccessCallback, WithOperation } from "./types";
import { VariablesWithoutUpdateInputs, wrapUpdateInputs } from "./updateInputs";
import { getOutput } from "./utils";

export type MutationOptions<Data, ThrowOnError extends boolean> = {
  awaitRefetchQueries?: boolean;
  optimisticResponse?: Data;
  refetchQueries?: QueryName[];
  throwOnError?: ThrowOnError;
  onSuccess?: SuccessCallback<Data>;
  onError?: ErrorHandler;
};

export type MutationInstanciationOptions<
  Data,
  Variables,
  ThrowOnError extends boolean,
> = MutationOptions<Data, ThrowOnError> & {
  variables?: VariablesWithoutUpdateInputs<Variables>;
  skipIfImpersonation?: boolean;
};

// prettier-ignore
export type Executable<
  Data,
  Variables,
  Schema extends SchemaType,
  ContextOptionsRequired extends boolean,
  ThrowOnErrorInstanciation extends boolean,
> = <ActualVariables, ThrowOnError extends boolean>(
  variables?: Exact<Variables, ActualVariables>,
  ...options: ContextOptionsRequired extends false
    ? [options?: MutationOptions<Data, ThrowOnError> & Partial<RequestContextOptions<Schema>>]
    : Partial<RequestContextOptions<Schema>> extends RequestContextOptions<Schema>
    ? // No need to pass an options object if none of the options are required.
      [options?: MutationOptions<Data, ThrowOnError> & RequestContextOptions<Schema>]
    : [options: MutationOptions<Data, ThrowOnError> & RequestContextOptions<Schema>]
) => Promise<ThrowOnErrorInstanciation extends true ? Data : ThrowOnError extends true ? Data : Data | void>;

// prettier-ignore
export type UseMutationFn = {
  // If context options are passed at instanciation, they're optional at runtime.
  <Data, Variables, Schema extends SchemaType, ThrowOnErrorInstanciation extends boolean>(
    mutation: Mutation<Data, Variables, Schema>,
    options: MutationInstanciationOptions<Data, Variables, ThrowOnErrorInstanciation> &
      RequestContextOptions<Schema>,
  ): [
    Executable<Data, VariablesWithoutUpdateInputs<Variables>, Schema, false, ThrowOnErrorInstanciation>,
    boolean,
  ];

  // But if they aren't passed at instanciation, they're required at runtime.
  <Data, Variables, Schema extends SchemaType, ThrowOnErrorInstanciation extends boolean>(
    mutation: Mutation<Data, Variables, Schema>,
    options?: MutationInstanciationOptions<Data, Variables, ThrowOnErrorInstanciation>,
  ): [
    Executable<Data, VariablesWithoutUpdateInputs<Variables>, Schema, true, ThrowOnErrorInstanciation>,
    boolean,
  ];
};

// prettier-ignore
export const useMutation: UseMutationFn = <
  Data,
  Variables,
  Schema extends SchemaType,
  ThrowOnErrorInstanciation extends boolean,
>(
  mutation: Mutation<Data, Variables, Schema>,
  options?: MutationInstanciationOptions<Data, Variables, ThrowOnErrorInstanciation> &
    Partial<RequestContextOptions<Schema>>,
): [
  Executable<Data, VariablesWithoutUpdateInputs<Variables>, Schema, boolean, ThrowOnErrorInstanciation>,
  boolean,
] => {
  const {
    onSuccess,
    onError,
    variables: initialVariables,
    skipIfImpersonation,
    optimisticResponse,
    requestContext,
    throwOnError,
    ...unchangedOptions
  } = options ?? {};

  const client = useGraphQLClient().graphQLClients[mutation.schemaType];

  const wrapOptimisticResponse = (value: Data | undefined) =>
    value
      ? ({
          __typename: "Mutation",
          [mutation.endpointName]: value,
        } as WithOperation<Data>)
      : undefined;

  const instantiationOptionsRef = useSyncRef({
    client,
    onSuccess,
    onError,
    skipIfImpersonation,
    updateInputsPaths: mutation.updateInputsPaths,
    wrapOptimisticResponse,
    requestContext,
  });

  const [execute, { loading }] = useApolloMutation<
    WithOperation<Data>,
    Variables
  >(mutation.document, {
    client,
    ...unchangedOptions,
    optimisticResponse: wrapOptimisticResponse(optimisticResponse),
    variables: wrapUpdateInputs(initialVariables, mutation.updateInputsPaths),
  });
  const { state: appVersionState } = useAppVersion();
  const auth = useMaybeAuth();

  return [
    useCallback(
      (variables, ...runtimeOptionsArray) => {
        const {
          requestContext: runtimeRequestContext,
          optimisticResponse: runtimeOptimisticResponse,
          onSuccess: runtimeOnSuccess,
          onError: runtimeOnError,
          throwOnError: runtimeThrowOnError,
          refetchQueries: runtimeRefetchQueries,
          awaitRefetchQueries: runtimeAwaitRefetchQueries,
        } = runtimeOptionsArray[0] ?? {};

        if (
          auth?.state === "LOGGED_IN" &&
          auth.currentImpersonationUuid &&
          !instantiationOptionsRef.current.skipIfImpersonation
        ) {
          const message = t("use_mutation.forbidden_in_incognito_mode");
          return throwOnError || runtimeThrowOnError
            ? Promise.reject(new Error(message))
            : new Promise(() => {
                notifier.error({ user: message });
              });
        }

        const actualRequestContext =
          runtimeRequestContext ??
          instantiationOptionsRef.current.requestContext;

        // See `useQuery` for an in-depth explanation.
        const shouldBypassCache = actualRequestContext !== undefined;
        const apolloContext = {
          requestContext: actualRequestContext,
          queryDeduplication: !shouldBypassCache,
        };

        return execute({
          fetchPolicy: shouldBypassCache ? "no-cache" : "network-only",
          context: apolloContext,
          variables: wrapUpdateInputs(
            variables,
            instantiationOptionsRef.current.updateInputsPaths,
          ),
          optimisticResponse:
            instantiationOptionsRef.current.wrapOptimisticResponse(
              runtimeOptimisticResponse,
            ),
          update: (_, { data }) => {
            const output = getOutput(data);
            if (output) {
              const currentClient = instantiationOptionsRef.current.client;
              runtimeOnSuccess?.(output, currentClient);
              instantiationOptionsRef.current.onSuccess?.(
                output,
                currentClient,
              );
            }
          },
          refetchQueries: runtimeRefetchQueries,
          awaitRefetchQueries: runtimeAwaitRefetchQueries,
        })
          .then(({ data }) => getOutput(data))
          .catch((e: ApolloError) => {
            const parsedError = parseApolloError(e);
            if (throwOnError || runtimeThrowOnError) throw parsedError;
            const secondLevelHandler = () =>
              instantiationOptionsRef.current.onError
                ? instantiationOptionsRef.current.onError(parsedError, () =>
                    notifyGraphQLError(appVersionState, auth?.state, e),
                  )
                : notifyGraphQLError(appVersionState, auth?.state, e);
            runtimeOnError
              ? runtimeOnError(parsedError, secondLevelHandler)
              : secondLevelHandler();
          }) as Promise<Data>;
      },
      [auth, execute, instantiationOptionsRef, appVersionState, throwOnError],
    ),
    loading,
  ];
};
