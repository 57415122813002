import { ReactElement, useEffect, useState } from "react";
import { Route, RouteProps, useLocation, useNavigate } from "react-router";

import { IdentityType, useAuth } from "auth/AuthContext";
import { REDIRECT_TO_KEY } from "consts";
import { routes } from "routes";

// A variant of `Route` which is only rendered if the user is logged in.
// Otherwise, the user will be redirected to the login view.
export const AuthenticatedRoute = ({
  element,
  ...rest
}: Omit<RouteProps, "element"> & {
  element: (userType: IdentityType | undefined) => ReactElement;
}) => {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  // Only if *initially* logged out (opened from an external link).
  const [saveCurrentPathForRedirectAfterLogin] = useState(
    auth.state === "LOGGED_OUT",
  );

  const redirectToLogin = auth.state === "LOGGED_OUT";

  useEffect(() => {
    if (!redirectToLogin) return;
    if (saveCurrentPathForRedirectAfterLogin) {
      storage.setItem(REDIRECT_TO_KEY, location.pathname);
    } else {
      storage.removeItem(REDIRECT_TO_KEY);
    }

    navigate(routes.LOGIN);
  }, [
    navigate,
    redirectToLogin,
    saveCurrentPathForRedirectAfterLogin,
    location.pathname,
  ]);

  return redirectToLogin ? null : (
    <Route {...rest} element={element(auth.currentIdentity?.type)} />
  );
};
