import { useEffect } from "react";
import { Route, RouteProps, useNavigate } from "react-router";

import { useAuth } from "auth/AuthContext";
import { REDIRECT_TO_KEY } from "consts";

// A variant of `Route` which is only rendered if the user is logged out.
//
// Otherwise, the user will be redirected to the URL they were trying to access
// before being redirected to the login view when applicable; or to the index.
export const UnauthenticatedRoute = (props: RouteProps) => {
  const { state } = useAuth();
  const navigate = useNavigate();

  const shouldRedirect = state === "LOGGED_IN";

  useEffect(() => {
    if (!shouldRedirect) return;
    navigate(storage.getItem(REDIRECT_TO_KEY) ?? "/", { replace: true });
  }, [navigate, shouldRedirect]);

  return shouldRedirect ? null : <Route {...props} />;
};
