import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  MouseEventHandler,
  useState,
} from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";

import { Icon, IconProps } from "components/Icon/Icon";
import { Spinner } from "components/Spinner/Spinner";
import { useDelayedLoading } from "hooks/useDelayedLoading";
import { IconName } from "icon-library";
import { run } from "utils";

import styles from "./button.module.css";

type SharedButtonProps = {
  label?: string;
  leftIcon?: IconName | IconProps;
  rightIcon?: IconName | IconProps;
  topIcon?: IconName | IconProps;
  danger?: boolean;
  loading?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  primary?: boolean;
  secondary?: boolean;
  tertiary?: boolean;
};

type ButtonMouseEvent = Parameters<MouseEventHandler<HTMLButtonElement>>[0];

export type FormButtonProps = SharedButtonProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onClick"> & {
    to?: undefined;
    external?: undefined;
    onClick?:
      | ((e: ButtonMouseEvent) => void)
      | ((e: ButtonMouseEvent) => Promise<void>);
  };

export type AnchorButtonProps = SharedButtonProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "onClick"> &
  ({ to: string; external?: undefined } | { to?: undefined; external: string });

export type ButtonProps = FormButtonProps | AnchorButtonProps;

const ButtonIcon = ({ icon }: { icon: IconName | IconProps }): JSX.Element => {
  if (typeof icon === "string") return <Icon name={icon} />;
  return <Icon {...icon} />;
};

export const Button = ({
  label,
  leftIcon,
  rightIcon,
  topIcon,
  danger,
  className,
  small,
  medium,
  large,
  primary,
  secondary,
  tertiary,
  loading: loadingFromProps,
  ...rest
}: ButtonProps) => {
  const [loadingFromOnClick, setLoadingFromOnClick] = useState(false);
  const loading = !!loadingFromProps || loadingFromOnClick;
  const delayedLoading = useDelayedLoading(loading);
  const mergedClassName = classNames(styles.button, className, {
    [styles.default]: !danger,
    [styles.danger]: danger,
    [styles.small]: small,
    [styles.medium]: medium || (!small && !large),
    [styles.large]: large,
    [styles.primary]: primary || (!secondary && !tertiary),
    [styles.secondary]: secondary,
    [styles.tertiary]: tertiary,
    [styles.loading]: loading,
    [styles.delayedLoading]: delayedLoading,
  });

  const children = (
    <>
      {delayedLoading && ( // But only show the loading state a bit later.
        <Spinner medium className={styles.spinner} />
      )}
      {leftIcon && <ButtonIcon icon={leftIcon} />}
      {topIcon ? (
        <span className="flex-col gap-6 items-center">
          <ButtonIcon icon={topIcon} />
          <span className={styles.content}>{label}</span>
        </span>
      ) : (
        <span className={styles.content}>{label}</span>
      )}
      {rightIcon && <ButtonIcon icon={rightIcon} />}
    </>
  );

  return rest.to !== undefined ? (
    <Link className={mergedClassName} {...rest}>
      {children}
    </Link>
  ) : rest.external !== undefined ? (
    <a
      className={mergedClassName}
      target="_blank"
      rel="noreferrer"
      {...rest}
      href={rest.external}
    >
      {children}
    </a>
  ) : (
    run(() => {
      const { onClick, ...restWithoutOnClick } = rest;
      const onClickWithLoadingState = onClick
        ? (e: ButtonMouseEvent) => {
            setLoadingFromOnClick(true);
            Promise.resolve(onClick(e)).finally(() => {
              setLoadingFromOnClick(false);
            });
          }
        : undefined;

      return (
        <button
          className={mergedClassName}
          disabled={!!rest.disabled || loading} // Disable the button right away.
          {...restWithoutOnClick}
          onClick={onClickWithLoadingState}
          type={rest.type ?? "button"} // Otherwise it would default to `submit`.
        >
          {children}
        </button>
      );
    })
  );
};
