import classNames from "classnames";

import { BottomSafeArea } from "components/Mobile/SafeArea";
import { Modal } from "components/Modal/Modal";
import { Separator } from "components/Separator/Separator";

import { MenuItem, MenuItemProps } from "./MenuItem";
import { separator } from "./MenuItemSeparator";

export const BottomActionSheet = ({
  items,
  close,
}: {
  items: (MenuItemProps | typeof separator)[];
  close: () => void;
}) => (
  <Modal
    backdropClassName="p-0"
    className="mb-0 rounded-0 border-0 p-0 items-stretch"
    onHide={close}
    closeButton={null}
  >
    {items.map((item, index) =>
      item === separator ? (
        <Separator key={index} />
      ) : (
        <MenuItem
          key={index}
          item={{
            ...item,
            className: classNames(item.className, "rounded-0 px-16 py-20"),
          }}
          close={close}
        />
      ),
    )}
    <BottomSafeArea />
  </Modal>
);
