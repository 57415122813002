import { useEffect } from "react";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "i18n";
import { apiUrl, defaultOrigin } from "utils/environment";
import { notifier } from "utils/notifier";

export const AppListener = () => {
  const t = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      if (event.url.startsWith(defaultOrigin)) {
        navigate(event.url.slice(defaultOrigin.length));
      } else if (event.url.startsWith(apiUrl(undefined))) {
        window.location.href = event.url;
      } else {
        notifier.error({
          user: t("app_listener.unrecognized_url"),
          sentry: {
            message: `Unrecognized URL: ${event.url}. Expected URL to start with ${defaultOrigin}`,
            level: "error",
          },
        });
      }
    });
    return () => {
      App.removeAllListeners();
    };
  }, [navigate, t]);

  return null;
};
