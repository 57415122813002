import { useMemo } from "react";

import { CodeInputMode } from "auth/views/AccountLoginLink";
import { Button } from "components/Button/Button";
import { useTranslation } from "i18n";
import { notifier } from "utils/notifier";

export const ExpiredLinkButton = ({
  codeInputMode = "LINK",
  email,
  onClick,
}: {
  codeInputMode?: CodeInputMode;
  email: string;
  onClick: () => Promise<"SUCCESS" | "ERROR">;
}) => {
  const t = useTranslation();
  const message = useMemo(() => {
    switch (codeInputMode) {
      case "MANUAL":
        return t("sign_in.expired_code.title");
      case "LINK":
        return t("choose_credentials.expired_link.title");
    }
  }, [codeInputMode, t]);
  return (
    <div className="flex-col">
      <p className="text-16 mb-16 text-center">{message}</p>
      <Button
        label={t("choose_credentials.expired_link.submit")}
        onClick={async () => {
          const status = await onClick();
          status === "SUCCESS" &&
            notifier.success(
              t("choose_credentials.expired_link.success", { email }),
            );
        }}
      />
    </div>
  );
};
