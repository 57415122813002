import { useEffect, useState } from "react";
import { DateTime } from "luxon";

import { useField } from "hooks/useField";
import { useSyncRef } from "hooks/useSyncRef";

import { LabelWrapper } from "../Label/LabelWrapper";
import { DatePicker, DatePickerProps } from "./DatePicker";
import { TimePicker } from "./TimePicker";
import { Time } from "./utils";

export const FormDateAndHour = <IsClearable extends boolean>({
  wrapperClassName,
  label,
  hint,
  name,

  disabled,
  loading,
  possibilities,
  hoursLabel,
  ...props
}: Omit<
  DatePickerProps<IsClearable>,
  "value" | "onChange" | "error" | "noteComposer"
> & {
  name: string;
  hoursLabel?: string;
}) => {
  // Temp value before selecting hour
  const [baseDate, setBaseDate] = useState<ISOString>();
  const [baseTime, setBaseTime] = useState<Time>();

  const [{ disabled: disabledField }, { error }, { setValue }] = useField<
    ISOString | undefined
  >({ name, disabled });

  const setValueRef = useSyncRef(setValue);

  useEffect(() => {
    if (baseDate && baseTime) {
      const fullDate = DateTime.fromObject({
        year: baseDate.getDate().getFullYear(),
        month: baseDate.getDate().getMonth() + 1,
        day: baseDate.getDate().getDate(),
        hour: baseTime.hours,
        minute: baseTime.minutes,
      });

      setValueRef.current(fullDate.toJSDate().toISOString());
    }
  }, [baseDate, baseTime, setValueRef]);

  return (
    <LabelWrapper
      wrapperClassName={wrapperClassName}
      label={hoursLabel ? undefined : label}
      error={error}
      hint={hint}
      useDiv
    >
      <div className="flex items-end">
        <DatePicker
          wrapperClassName="flex-1"
          label={hoursLabel ? label : undefined}
          value={baseDate}
          loading={loading}
          possibilities={possibilities}
          disabled={disabledField}
          onChange={setBaseDate}
          {...props}
        />
        <TimePicker
          wrapperClassName="flex-1"
          name={`${name}-time`}
          disabled={disabledField}
          value={baseTime}
          onChange={setBaseTime}
        />
      </div>
    </LabelWrapper>
  );
};
