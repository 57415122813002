import classNames from "classnames";
import ReactSwitch, { ReactSwitchProps } from "react-switch";

import { Icon } from "components/Icon/Icon";
import { IconName } from "icon-library";
import { colors } from "styles";

import styles from "./switch.module.css";

export type ToggleProps = {
  label?: string;
  error?: string;
  labelClassName?: string;
  reverse?: boolean;
  checkIcon?: IconName;
  closeIcon?: IconName;
} & ReactSwitchProps;

export const Switch = ({
  label,
  labelClassName,
  reverse,
  checked,
  disabled,
  error,
  checkIcon,
  closeIcon,
  ...rest
}: ToggleProps) => (
  <label
    className={classNames(styles.label, labelClassName, {
      "flex-row-reverse": reverse,
      "cursor-pointer": !disabled,
    })}
  >
    <div
      className={classNames(styles.border, {
        "border-primary": checked,
        "border-opacity-50": disabled,
      })}
    >
      <ReactSwitch
        checked={checked}
        disabled={disabled}
        onColor={colors.primary}
        offColor={colors["grey-100"]}
        boxShadow="0 1px 2px 0 rgba(53, 49, 117, 0.2)"
        activeBoxShadow="0px 0px 2px 3px rgba(0, 82, 255, 0.7)"
        height={22}
        checkedIcon={false}
        uncheckedIcon={false}
        checkedHandleIcon={
          <div className="h-full w-full flex-center">
            <Icon
              name={checkIcon ?? "check"}
              className="text-primary"
              size={10}
            />
          </div>
        }
        uncheckedHandleIcon={
          <div className="h-full w-full flex-center">
            <Icon name={closeIcon ?? "close"} size={10} />
          </div>
        }
        width={40}
        {...rest}
      />
    </div>
    <div
      className={classNames("flex-fill flex-col", reverse ? "mr-12" : "ml-12")}
    >
      {label && <span>{label}</span>}
      {/* This flex-wrap is weird but I don't want to deal with this edge case now (somewhere in MyAppointmentFormats) */}
      {error && <div className="text-danger flex-wrap">{error}</div>}
    </div>
  </label>
);
