import classNames from "classnames";

import { useTranslation } from "i18n";

import styles from "./PasswordStrengthBar.module.css";

export const PasswordStrengthBar = ({ strength }: { strength: number }) => {
  const t = useTranslation();

  return (
    <div className="flex mt-8 items-center">
      <div>
        {t("password_strength_bar.password_strength_bar.password_strength")}
      </div>
      <meter
        className={classNames("flex-1 mx-8", styles.meter)}
        max="4"
        id="password-strength-meter"
        value={strength}
      />
    </div>
  );
};
